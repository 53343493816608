import React from 'react'
import { CapitalizationSummary } from '../../../../../../Models/DataModels/Common/ToolsModel'
import { AlertModalProps, AlertButtonType } from '../../../../../Common/Modals/AlertModal'
import AlertModal from '../../../../../Common/Modals/AlertModal'
import CapSummaryBarChart from './CapSummaryBarChart'
import CapSummaryPieChart from './CapSummaryPieChart'
import { NotOKResponseModel } from '../../../../../../Models/DataModels/Common/NotOKResponseModel'
import { getNextKellyColorSkipLight } from '../../../../../../Services/ColorService'
import { LabelValuePair } from '../../../../../../Models/DataModels/Common/LabelValuePairModel'
import moment from 'moment'

export enum CapSummaryChartTypes {
    Bar,
    Pie
}

export interface CapSummaryChartDisplayModalProps {
    showModal: boolean,
    setShow: any,
    capSummaryData: CapitalizationSummary[],
    chartType: CapSummaryChartTypes,
    setErrorResponse: (errorResponse: NotOKResponseModel | null) => void,
    summaryType: string
    selectedCurrency: LabelValuePair,
    selectedIndex: LabelValuePair
}

const CapSummaryChartDisplayModal = ({
    showModal,
    setShow,
    capSummaryData,
    chartType,
    setErrorResponse,
    summaryType,
    selectedCurrency,
    selectedIndex
}: CapSummaryChartDisplayModalProps) => {

    const chartTitle = (): string => {
        const summaryName: string = summaryType
        const tempDateString: string = capSummaryData?.length > 0 ? capSummaryData[0].date.toString() : ''
        const dateString: string = moment(tempDateString).isValid() ? moment(tempDateString).format('YYYY') : 'N/A'
        const currencyString: string = selectedCurrency.label
        return `${summaryName} - Date: ${dateString} - Currency: ${currencyString}`
    }

    const chartTitleWithIndexNamePrefix = (): string => {
        const prefixTitle: string = selectedIndex?.label && selectedIndex.label.length > 0 ? `${selectedIndex.label} - ` : ''
        return `${prefixTitle}${chartTitle()}`
    }

    const contents = (): JSX.Element => {
        const availableColorCodes: string[] = []

        capSummaryData.forEach(
            (entry: CapitalizationSummary, index: number) => {
                const color: string = getNextKellyColorSkipLight(index + 1) // skip black
                availableColorCodes.push(color)
            }
        )

        switch (chartType) {
            case CapSummaryChartTypes.Bar:
                return (
                    <CapSummaryBarChart chartTitle={chartTitleWithIndexNamePrefix()} headerName={summaryType} capSummaryData={capSummaryData} availableColorCodes={availableColorCodes} setErrorResponse={setErrorResponse} />
                )
            case CapSummaryChartTypes.Pie:
                return (
                    <CapSummaryPieChart chartTitle={chartTitleWithIndexNamePrefix()} headerName={summaryType} capSummaryData={capSummaryData} availableColorCodes={availableColorCodes} setErrorResponse={setErrorResponse} />
                )
            default:
                return (
                    <div><p><strong>Unsupported Chart Type</strong></p></div>
                )
        }
    }

    const capSummaryBarChartAlertProps: AlertModalProps = {
        showModal: showModal,
        setShowModal: setShow,
        AlertTitle: chartTitle(),
        AlertContent: contents(),
        AlertButtons: [
            {
                type: AlertButtonType.OK,
                display: 'Close',
                onClick: () => setShow(false),
                isPrimary: false,
            }
        ],
        Size: 'xl',
        fullScreen: false,
        additionalClassnames: 'wider'
    }

    return AlertModal(capSummaryBarChartAlertProps)
}

export default CapSummaryChartDisplayModal
