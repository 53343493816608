export const sessionStorageKeys = {
    selectedResultsForGraphData: 'selectedResultsForGraphData',
    selectedComparisonGraphData: 'selectedComparisonGraphData',
    selectedResultsForChartsData: 'selectedResultsForChartsData',
    graphCustomColors: 'graphCustomColors',
    customIPHeader: 'X-Custom-Ip'
}

export const getSessionStorageOrDefault = (key: any, defaultValue: any) => {
    const stored = sessionStorage.getItem(key)
    if (!stored) {
        return defaultValue
    }
    return JSON.parse(stored)
}

export const getSessionStorageValueOrDefault = (key: any, defaultValue: any) => {
    const stored = sessionStorage.getItem(key)
    if (!stored) {
        return defaultValue
    }
    return stored
}
