import { Autocomplete, CircularProgress, Grid, IconButton, InputAdornment, Paper, TextField, Tooltip } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { PopulateConstituentIndicesRequest, PopulateCountryRequest, PopulateIndustryRequest, PopulateSectorsRequest } from '../../../../../Models/DataModels/Requests/PopulationRequests'
import { CriteriaData, Factor, GetReportResultsByScreenName, LoadScreeningReportRequest, RankData, ScreenStatisticsRequest, SortData } from '../../../../../Models/DataModels/Requests/ScreeningRequests'
import { populateConstituentIndices, populateCountry, populateIndustry, populateSectors } from '../../../../../Services/FieldPopulationService'
import FamaFrenchResults from './FamaFrenchResults'
import FamaFrenchScreeningTable from './FamaFrenchScreeningTable'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { loadScreeningReport, getScreenStatistics } from '../../../../../Services/ScreeningService'
import { criteriaMapping } from '../../../../Common/Utility/CriteriaMapping'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import ResetAllConfirmation from '../../../../Common/Modals/ResetAllConfirmation'
import { getLocallyStoredIndustries } from '../../../../../Services/DropDownValuesService'
import { SearchDatabaseTypes, allEntry, blankEntry } from '../../../../../Models/DataModels/Common/FieldPopulationModel'
import { LabelValuePair } from '../../../../../Models/DataModels/Common/LabelValuePairModel'
import { AppConfigurations } from '../../../../../Models/DataModels/Common/AppConfigurationsModel'
import { CustomBtnBlue, BtnClearReset } from '../../../../Common/GlobalSettings/CustomStyles'
import { errorRedColor } from '../../../../Common/GlobalSettings/GlobalStyles'
import { UserInfo } from '../../../../../Models/DataModels/Common/UserInfoModel'
import { NotOKResponseModel } from '../../../../../Models/DataModels/Common/NotOKResponseModel'
import { LogoutReasonType } from '../../../../../Models/DataModels/Requests/AuthRequests'
import { GFDToastError } from '../../../../Common/Utility/GFDToastify'
import LoadingScreen from '../../../../Common/Modals/LoadingScreen'

export interface CancelModalProps {
  resetAll: () => void,
  showModal: boolean,
  setShowModal: (value: boolean) => void,
  isCancel: boolean,
  cancelScreening: () => void,
  setIsCancel: (value: boolean) => void
}

export interface FamaStateProps {
  setIsAddOrEditScreen: (value: boolean) => void,
  screeningName?: string | null,
  screeningID?: number | null,
  customIndexID?: number | null,
  isEdit: boolean,
  cancelModalProps: CancelModalProps,
  showResetModal: boolean,
  setShowResetModal: (value: boolean) => void,
  isCancel: boolean,
  setIsCancel: (value: boolean) => void,
}

export interface FamaFrenchStartScreenProps
{
  userInfo: UserInfo | null,
  famaStateProps: FamaStateProps,
  setErrorResponse: (error: NotOKResponseModel | null) => void,
  signOut: (logoutReason: LogoutReasonType) => void,
  checkIsTrial: () => boolean
}

const defaultRank = {
  type: "Top",
  rankBy: "Value",
  rankValue: 300,
  usePctChg: false,
  pctChgStart: null,
  pctChgEnd: null
}

const FamaFrenchStartScreen = ({ userInfo, famaStateProps, signOut, setErrorResponse, checkIsTrial }: FamaFrenchStartScreenProps) => {
  const noInputArrows = [
    {
      '& input[type=number]': {
        MozAppearance: 'textfield'
      },
      '& input[type=number]::-webkit-outer-spin-button': {
        WebkitAppearance: 'none',
        margin: 0
      },
      '& input[type=number]::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: 0
      }
    }]

  //Screen Values
  const [showFamaInitial, setFamaInital] = useState<boolean>(true)
  const [showFamaScreening, setFamaScreening] = useState<boolean>(false)
  const [showFamaResults, setFamaResults] = useState<boolean>(false)
  const [screeningInProgress, setScreeningInProgress] = useState<boolean>(false)

  //Form Values
  const [screeningID, setScreeningId] = useState<number>(0)
  const [screeningName, setScreeningName] = useState<any>('')
  const [description, setDescription] = useState<any>('')
  const [sector, setSector] = useState<LabelValuePair>(blankEntry)
  const [sectorOptions, setSectorOptions] = useState<LabelValuePair[]>([])
  const [industry, setIndustry] = useState<LabelValuePair>(blankEntry)
  const [industryOptions, setIndustryOptions] = useState<LabelValuePair[]>([])
  const [index, setIndex] = useState<LabelValuePair>(blankEntry)
  const [indicesOptions, setIndicesOptions] = useState<any>([])
  const [countryOfOperation, setCountryOfOperation] = useState<any>(blankEntry)
  const [countryOptions, setCountryOptions] = useState<any>([blankEntry])
  const [countryOfListing, setCountryOfListing] = useState<any>(blankEntry)
  const countryListingOptions = [
    { label: 'Europe', value: 'EUR' },
    { label: 'United States', value: 'USA' },
    { label: 'United Kingdom', value: 'GBR' }
  ]
  const [tableData, setTableData] = useState<any[]>([])

  const [screeningNameHelperText, setScreeningNameHelperText] = useState<string>('')
  const [screeningNameHasError, setScreeningNameHasError] = useState<boolean>(false)
  const [criteriaHelperText, setCriteriaHelperText] = useState<string>('')
  const [criteriaHasError, setCriteriaHasError] = useState<boolean>(false)
  const [indexHelperText, setIndexHelperText] = useState<string>('')
  const [indexHasError, setIndexHasError] = useState<boolean>(false)
  const [countryOfListingHelperText, setCountryOfListingHelperText] = useState<string>('')
  const [countryOfListingHasError, setCountryOfListingHasError] = useState<boolean>(false)

  const [lastScreenRequest, setLastScreenRequest] = useState<ScreenStatisticsRequest | null>(null)

  const [filteredIndustryList, setFilteredIndustryList] = useState<LabelValuePair[]>(industryOptions || [])

  const minimumDate: moment.Moment = moment(AppConfigurations.minimumDate)
  const maximumDate: moment.Moment = moment()

  useEffect(() => {
    getLocallyStoredIndustries(SearchDatabaseTypes.Unknown, sector.value === allEntry.value ? '' : sector?.value)
      .then((value: LabelValuePair[] | null) => value && setFilteredIndustryList(value), () => { })
  }, [sector])

  useEffect(() => {
    if (!filteredIndustryList.some(v => v.value === industry.value)) {
      setIndustry(blankEntry)
    }
  }, [filteredIndustryList])

  const table = {
    tableData: tableData,
    setTableData: setTableData
  }

  const determineDefaultDate = (): moment.Moment => {
    const first = [1, 2, 3]
    const second = [4, 5, 6]
    const third = [7, 8, 9]
    const fourth = [10, 11, 12]

    if (first.indexOf(moment().month() + 1) !== -1) {
      const year = (moment().year() - 1).toString()
      return moment('12-31-' + year)
    }

    if (second.indexOf(moment().month() + 1) !== -1) {
      const year = moment().year()
      return moment('3-31-' + year)
    }

    if (third.indexOf(moment().month() + 1) !== -1) {
      const year = moment().year()
      return moment('6-30-' + year)
    }

    if (fourth.indexOf(moment().month() + 1) !== -1) {
      const year = moment().year()
      return moment('9-30-' + year)
    }

    return moment()
  }

  const [marketCapStart, setMarketCapStart] = useState<number>()
  const [marketCapEnd, setMarketCapEnd] = useState<number>()
  const [ratioStart, setRatioStart] = useState<number>()
  const [ratioEnd, setRatioEnd] = useState<number>()
  const [betaStart, setBetaStart] = useState<number>()
  const [betaEnd, setBetaEnd] = useState<number>()
  const [M3Start, setM3Start] = useState<number>()
  const [M3End, setM3End] = useState<number>()
  const [M12Start, setM12Start] = useState<number>()
  const [M12End, setM12End] = useState<number>()
  const [M36Start, setM36Start] = useState<number>()
  const [M36End, setM36End] = useState<number>()
  const [dividendYieldStart, setDividendYieldStart] = useState<number>()
  const [dividendYieldEnd, setDividendYieldEnd] = useState<number>()
  const [screenDate, setScreenDate] = useState<moment.Moment>(determineDefaultDate())

  const [arrayOfRankAndSort, setArrayOfRankAndSort] = useState<any[]>([])
  const [screeningStatistics, setScreeningStatistics] = useState<any>({})
  const [arrayOfFactors, setArrayOfFactors] = useState<Factor[]>([])

  const [showLoadingModal, setShowLoadingModal] = useState<boolean>(false)


  const resetAll = () => {
    setScreeningName('')
    setIndex(blankEntry)
    setCountryOfListing(blankEntry)
    setCountryOfOperation(blankEntry)
    setIndustry(blankEntry)
    setSector(blankEntry)
    setScreenDate(determineDefaultDate())

    setMarketCapStart(NaN)
    setMarketCapEnd(NaN)
    setRatioStart(NaN)
    setRatioEnd(NaN)
    setBetaStart(NaN)
    setBetaEnd(NaN)
    setM3Start(NaN)
    setM3End(NaN)
    setM12Start(NaN)
    setM12End(NaN)
    setM36Start(NaN)
    setM36End(NaN)
    setDividendYieldStart(NaN)
    setDividendYieldEnd(NaN)

    setFamaInital(true)
    setFamaScreening(false)
    setFamaResults(false)
    
    setArrayOfFactors([])
  }

  const showResetConfirmation = () => {
    famaStateProps.setShowResetModal(true)
  }

  const generateFactorsManual = () => {

    let factorsArray = []

    //MarketCap = 1778
    if (marketCapStart || marketCapEnd) {
      const criteria: CriteriaData = {
        entityID: 1778,
        min: marketCapStart,
        max: marketCapEnd
      }


      let rankAndSort: any = arrayOfRankAndSort.filter((a: any) => {
        return a.entityID === 1778
      }) ?? []



      let rank: RankData = rankAndSort.length > 0 ? rankAndSort[0].rank : { ...defaultRank }
      let sort: SortData = rankAndSort.length > 0 ? rankAndSort[0].sort : {}

      let factor: Factor = {
        criteria: criteria,
        rank: rank,
        sort: sort
      }

      const searchArray: any = arrayOfFactors.filter((item: any) => {
        return item.criteria.entityID === 1778
      })
      if (searchArray.length > 0) {
        if (Object.keys(searchArray[0].rank).length > 0) {
          factor.rank = searchArray[0].rank
        }
        if (Object.keys(searchArray[0].sort).length > 0) {
          factor.sort = searchArray[0].sort
        }
        factorsArray.push(factor)
      } else {
        factorsArray.push(factor)
      }
    }

    if (ratioStart || ratioEnd) {
      //PE ratio = 1786
      const criteria: CriteriaData = {
        entityID: 1786,
        min: ratioStart,
        max: ratioEnd
      }

      let rankAndSort: any = arrayOfRankAndSort.filter((a: any) => {
        return a.entityID === 1786
      }) ?? []

      let rank: RankData = rankAndSort.length > 0 ? rankAndSort[0].rank : { ...defaultRank }
      let sort: SortData = rankAndSort.length > 0 ? rankAndSort[0].sort : {}

      let factor: Factor = {
        criteria: criteria,
        rank: rank,
        sort: sort
      }

      const searchArray: any = arrayOfFactors.filter((item: any) => {
        return item.criteria.entityID === 1786
      })
      if (searchArray.length > 0) {
        if (Object.keys(searchArray[0].rank).length > 0) {
          factor.rank = searchArray[0].rank
        }
        if (Object.keys(searchArray[0].sort).length > 0) {
          factor.sort = searchArray[0].sort
        }
        factorsArray.push(factor)
      } else {
        factorsArray.push(factor)
      }
    }

    if (betaStart || betaEnd) {
      //Beta = 1812
      const criteria: CriteriaData = {
        entityID: 1812,
        min: betaStart,
        max: betaEnd
      }

      let rankAndSort: any = arrayOfRankAndSort.filter((a: any) => {
        return a.entityID === 1812
      }) ?? []

      let rank: RankData = rankAndSort.length > 0 ? rankAndSort[0].rank : { ...defaultRank }
      let sort: SortData = rankAndSort.length > 0 ? rankAndSort[0].sort : {}

      let factor: Factor = {
        criteria: criteria,
        rank: rank,
        sort: sort
      }

      const searchArray: any = arrayOfFactors.filter((item: any) => {
        return item.criteria.entityID === 1812
      })
      if (searchArray.length > 0) {
        if (Object.keys(searchArray[0].rank).length > 0) {
          factor.rank = searchArray[0].rank
        }
        if (Object.keys(searchArray[0].sort).length > 0) {
          factor.sort = searchArray[0].sort
        }
        factorsArray.push(factor)
      } else {
        factorsArray.push(factor)
      }
    }

    if (dividendYieldStart || dividendYieldEnd) {
      //div yield = 1782
      const criteria: CriteriaData = {
        entityID: 1782,
        min: dividendYieldStart,
        max: dividendYieldEnd
      }

      let rankAndSort: any = arrayOfRankAndSort.filter((a: any) => {
        return a.entityID === 1782
      }) ?? []

      let rank: RankData = rankAndSort.length > 0 ? rankAndSort[0].rank : { ...defaultRank }
      let sort: SortData = rankAndSort.length > 0 ? rankAndSort[0].sort : {}

      let factor: Factor = {
        criteria: criteria,
        rank: rank,
        sort: sort
      }

      const searchArray: any = arrayOfFactors.filter((item: any) => {
        return item.criteria.entityID === 1782
      })
      if (searchArray.length > 0) {
        if (Object.keys(searchArray[0].rank).length > 0) {
          factor.rank = searchArray[0].rank
        }
        if (Object.keys(searchArray[0].sort).length > 0) {
          factor.sort = searchArray[0].sort
        }
        factorsArray.push(factor)
      } else {
        factorsArray.push(factor)
      }
    }

    if (M3Start || M3End) {
      //m3 = 1800
      const criteria: CriteriaData = {
        entityID: 1800,
        min: M3Start,
        max: M3End
      }

      let rankAndSort: any = arrayOfRankAndSort.filter((a: any) => {
        return a.entityID === 1800
      }) ?? []

      let rank: RankData = rankAndSort.length > 0 ? rankAndSort[0].rank : { ...defaultRank }
      let sort: SortData = rankAndSort.length > 0 ? rankAndSort[0].sort : {}

      let factor: Factor = {
        criteria: criteria,
        rank: rank,
        sort: sort
      }

      const searchArray: any = arrayOfFactors.filter((item: any) => {
        return item.criteria.entityID === 1800
      })
      if (searchArray.length > 0) {
        if (Object.keys(searchArray[0].rank).length > 0) {
          factor.rank = searchArray[0].rank
        }
        if (Object.keys(searchArray[0].sort).length > 0) {
          factor.sort = searchArray[0].sort
        }
        factorsArray.push(factor)
      } else {
        factorsArray.push(factor)
      }
    }

    if (M12Start || M12End) {
      //m12 = 1804
      const criteria: CriteriaData = {
        entityID: 1804,
        min: M12Start,
        max: M12End
      }

      let rankAndSort: any = arrayOfRankAndSort.filter((a: any) => {
        return a.entityID === 1804
      }) ?? []

      let rank: RankData = rankAndSort.length > 0 ? rankAndSort[0].rank : { ...defaultRank }
      let sort: SortData = rankAndSort.length > 0 ? rankAndSort[0].sort : {}

      let factor: Factor = {
        criteria: criteria,
        rank: rank,
        sort: sort
      }

      const searchArray: any = arrayOfFactors.filter((item: any) => {
        return item.criteria.entityID === 1804
      })
      if (searchArray.length > 0) {
        if (Object.keys(searchArray[0].rank).length > 0) {
          factor.rank = searchArray[0].rank
        }
        if (Object.keys(searchArray[0].sort).length > 0) {
          factor.sort = searchArray[0].sort
        }
        factorsArray.push(factor)
      } else {
        factorsArray.push(factor)
      }
    }

    if (M36Start || M36End) {
      //m36 = 1808
      const criteria: CriteriaData = {
        entityID: 1808,
        min: M36Start,
        max: M36End
      }

      let rankAndSort: any = arrayOfRankAndSort.filter((a: any) => {
        return a.entityID === 1808
      }) ?? []

      let rank: RankData = rankAndSort.length > 0 ? rankAndSort[0].rank : { ...defaultRank }
      let sort: SortData = rankAndSort.length > 0 ? rankAndSort[0].sort : {}

      let factor: Factor = {
        criteria: criteria,
        rank: rank,
        sort: sort
      }

      const searchArray: any = arrayOfFactors.filter((item: any) => {
        return item.criteria.entityID === 1808
      })
      if (searchArray.length > 0) {
        if (Object.keys(searchArray[0].rank).length > 0) {
          factor.rank = searchArray[0].rank
        }
        if (Object.keys(searchArray[0].sort).length > 0) {
          factor.sort = searchArray[0].sort
        }
        factorsArray.push(factor)
      } else {
        factorsArray.push(factor)
      }
    }
    if (!validateCriteria(factorsArray)) {
      setArrayOfFactors(factorsArray)
    }

  }

  let arraySet: any = []
  const filterAndSetArrays = (factor: any) => {
    if (arrayOfRankAndSort) {
      let set = arrayOfRankAndSort.filter((item: any) => {
        return factor.entityID === item.entityID
      }).length === 0 ? arraySet.push({ entityID: factor.criteria.entityID, rank: factor.rank, sort: factor.sort }) : arraySet
    }
  }

  const mapAndSetCriteria = (arrayOfFactors: any) => {
    let mapBaseName = ''
    if (arrayOfFactors) {
      arrayOfFactors.forEach((a: any) => {
        mapBaseName = criteriaMapping(a.criteria.entityID)
        if (mapBaseName === 'Market Cap') {
          if (a.criteria.min) {
            setMarketCapStart(a.criteria.min)
          }
          if (a.criteria.max) {
            setMarketCapEnd(a.criteria.max)
          }

          filterAndSetArrays(a)
        }

        if (mapBaseName === 'PE Ratio') {
          if (a.criteria.min) {
            setRatioStart(a.criteria.min)
          }
          if (a.criteria.max) {
            setRatioEnd(a.criteria.max)
          }
          filterAndSetArrays(a)
        }

        if (mapBaseName === 'Beta') {
          if (a.criteria.min) {
            setBetaStart(a.criteria.min)
          }
          if (a.criteria.max) {
            setBetaEnd(a.criteria.max)
          }
          filterAndSetArrays(a)
        }

        if (mapBaseName === 'Momentum-M3') {
          if (a.criteria.min) {
            setM3Start(a.criteria.min)
          }
          if (a.criteria.max) {
            setM3End(a.criteria.max)
          }
          filterAndSetArrays(a)
        }

        if (mapBaseName === 'Momentum-M12') {
          if (a.criteria.min) {
            setM12Start(a.criteria.min)
          }
          if (a.criteria.max) {
            setM12End(a.criteria.max)
          }
          filterAndSetArrays(a)
        }

        if (mapBaseName === 'Momentum-M36') {
          if (a.criteria.min) {
            setM36Start(a.criteria.min)
          }
          if (a.criteria.max) {
            setM36End(a.criteria.max)
          }
          filterAndSetArrays(a)
        }

        if (mapBaseName === 'Dividend Yield') {
          if (a.criteria.min) {
            setDividendYieldStart(a.criteria.min)
          }
          if (a.criteria.max) {
            setDividendYieldEnd(a.criteria.max)
          }
          filterAndSetArrays(a)
        }

      })
      setArrayOfRankAndSort(arraySet)
    }
  }

  const validateScreeningName = (): boolean => {
    if (screeningName.trim().length === 0) {
      setScreeningNameHasError(true)
      setScreeningNameHelperText('Screening name is required')
      return true
    }
    setScreeningNameHasError(false)
    setScreeningNameHelperText('')

    return false
  }

  const validateIndex = (): boolean => {
    if (!index?.value) {
      setIndexHasError(true)
      setIndexHelperText('Index is required to continue')
      return true
    }
    setIndexHasError(false)
    setIndexHelperText('')

    return false
  }

  const validateCountryOfListing = (): boolean => {
    if (!countryOfListing?.value) {
      setCountryOfListingHasError(true)
      setCountryOfListingHelperText('Country of Listing is required to continue')
      return true
    }
    setCountryOfListingHasError(false)
    setCountryOfListingHelperText('')

    return false
  }



  const validateCriteria = (factorArray: any[]): boolean => {
    let result: boolean = false
    if (factorArray.length === 0) {
      setCriteriaHelperText('At least one criteria must contain a value to continue... Size, Value, Risk, Momentum or Investment')
      setCriteriaHasError(true)
      result = true
    }
    if (factorArray.length > 0) {
      factorArray.forEach((factor: any) => {
        if (factor.criteria.min > factor.criteria.max) {
          let criteriaName = criteriaMapping(factor.criteria.entityID)
          setCriteriaHelperText(`${criteriaName} min value cannot be greater than max value`)
          setCriteriaHasError(true)
          result = true
        }
      })
    }
    if (result === false) {
      setCriteriaHelperText('')
      setCriteriaHasError(false)
    }

    return result
  }

  const doScreening = () => {
    if (arrayOfFactors.length > 0) {
      const screenStatRequest: ScreenStatisticsRequest = {
        universe: {
          sector: sector.value,
          industry: industry.value,
          indices: index.value,
          countryOfOperation: countryOfOperation.value,
          countryOfListing: countryOfListing.value
        },
        factors: arrayOfFactors,
        screenOnDate: screenDate.format('MM-DD-YYYY')
      }

      setScreeningInProgress(true)
      getScreenStatistics(screenStatRequest).then((res: any) => {
        setScreeningStatistics({ ...res })
        if (!showFamaScreening) {
          setFamaInital(!showFamaInitial)
          setFamaScreening(!showFamaScreening)
        }
        setScreeningInProgress(false)
      },
        //Reject promise
        () => {
          console.log('rejected promise!')
          setScreeningInProgress(false)
        })
    }
  }

  useEffect(() => {
    const countryRequest: PopulateCountryRequest = {
      database: 'Unknown'
    }
    populateCountry(countryRequest).then((res: any) => {
      res.unshift(allEntry)
      res.push(blankEntry)
      setCountryOptions([...res])
    },
      //Reject promise
      () => {
        console.log('rejected promise!')
      })

    const sectorRequest: PopulateSectorsRequest = {
      database: 'Unknown'
    }
    populateSectors(sectorRequest).then((res: any) => {
      setSectorOptions([...res])
    },
      //Reject promise
      () => {
        console.log('rejected promise!')
      })

    const industryRequest: PopulateIndustryRequest = {
      database: 'Unknown',
      sector: sector.value ?? ''
    }
    populateIndustry(industryRequest).then((res: any) => {
      setIndustryOptions([...res])
      setFilteredIndustryList([...res])
    },
      //Reject promise
      () => {
        console.log('rejected promise!')
      })

    const indicesRequest: PopulateConstituentIndicesRequest = {
      category: 'MajorUS'
    }
    populateConstituentIndices(indicesRequest).then((res: any) => {
      setIndicesOptions([...res])
    },
      //Reject promise
      () => {
        console.log('rejected promise!')
      })
  }, [])


  const resetAllConfirmationProps = {
    resetAll: resetAll,
    setShowModal: famaStateProps.setShowResetModal,
    showModal: famaStateProps.showResetModal,
    isCancel: false,
    cancelScreening: () => { },
    setIsCancel: famaStateProps.setIsCancel
  }

  useEffect(() => {
    doScreening()
    if (arrayOfFactors.length === 0) {
      setFamaScreening(false)
      setFamaInital(true)
    }
  }, [arrayOfFactors])

  useEffect(() => {
    if (famaStateProps.isEdit && indicesOptions.length !== 0 && countryOptions[0].value !== '' && industryOptions.length !== 0 && sectorOptions.length !== 0) {
      setShowLoadingModal(true)
      let request: LoadScreeningReportRequest
      
      if (famaStateProps.screeningName) {
        request = {
          screenName: famaStateProps.screeningName
        } 
      } else if (famaStateProps.screeningID) {
        request = {
          screenID: famaStateProps.screeningID
        }
      } else {
        GFDToastError('Parameters insufficient to fetch screen report')
        return
      }

      //service call to get the needed data for edit
      loadScreeningReport(request).then((res: any) => {
        setScreeningName(res.name)
        setIndex([...indicesOptions].filter((a: any) => a.value === res.screeningRequest.universe.indices).pop() ?? blankEntry)
        setCountryOfListing([...countryListingOptions].filter((a: any) => a.value === res.screeningRequest.universe.countryOfListing).pop() ?? blankEntry)
        setCountryOfOperation([...countryOptions].filter((a: any) => a.value === res.screeningRequest.universe.countryOfOperation).pop() ?? { label: 'All', value: 'all' })
        setIndustry([...industryOptions].filter((a: any) => a.value === res.screeningRequest.universe.industry).pop() ?? blankEntry)
        setSector([...sectorOptions].filter((a: any) => a.value === res.screeningRequest.universe.sector).pop() ?? blankEntry)
        setScreenDate(moment(res.screeningRequest.screenOnDate))
        setScreeningId(res.screenID)
        setDescription(res.description)

        mapAndSetCriteria(res.screeningRequest.factors)
        setShowLoadingModal(false)


      }, () => {
        GFDToastError('Failed to fetch screen report details')
        setShowLoadingModal(false)
      })
    }
  }, [indicesOptions, industryOptions, countryOptions, sectorOptions])


  //ANON TYPES
  const famaProps = {
    setFamaInital: setFamaInital,
    setFamaScreening: setFamaScreening,
    setFamaResults: setFamaResults
  }


  const goToFamaScreening = () => {
    if (!validateScreeningName() && !validateCountryOfListing()) {
      generateFactorsManual()
    }
  }

  const famaValueProps = {
    screeningInProgress,
    screeningStatistics: screeningStatistics,
    setScreeningStatistics: setScreeningStatistics,
    setArrayOfFactors: setArrayOfFactors,
    marketCap:
    {
      setMarketCapStart: setMarketCapStart,
      setMarketCapEnd: setMarketCapEnd,
    },
    ratio:
    {
      setRatioStart: setRatioStart,
      setRatioEnd: setRatioEnd,
    },
    beta:
    {
      setBetaStart: setBetaStart,
      setBetaEnd: setBetaEnd,
    },
    movement:
    {
      setM3Start: setM3Start,
      setM3End: setM3End,
      setM12Start: setM12Start,
      setM12End: setM12End,
      setM36Start: setM36Start,
      setM36End: setM36End
    },
    dividend: {
      setDividendYieldStart: setDividendYieldStart,
      setDividendYieldEnd: setDividendYieldEnd
    },
    doScreening: doScreening,
    screeningName: screeningName,
    screeningID: screeningID,
    setScreeningName: setScreeningName,
    description: description,
    setDescription: setDescription
  }

  return (
    <>
      {showFamaInitial ?
        <div>
          <h3>Screening Factors </h3><span style={{ fontStyle: 'italic' }}><p>{famaStateProps.isEdit ? 'Editing Report for: ' + (famaStateProps.screeningName || screeningName) : ''}</p></span>

          <Paper elevation={3} style={{ padding: 5 }}>
            <Grid container spacing={2} columns={16}>
              <Grid item md={4}>
                <div>
                  <span style={famaFrenchScreenerStyles.spanTitle}>Screening Name:</span>
                </div>
                <TextField
                  label={
                    <>
                      <span>Screening Name (required) </span>
                      <Tooltip title='Screening name, will be seen on the saved reports screen'>
                        <HelpOutlineIcon style={{ fill: '#8e95ce' }} />
                      </Tooltip>
                    </>
                  }
                  InputLabelProps={{
                    shrink: true,
                    style: { fontWeight: 'bold' }
                  }}
                  variant="outlined"
                  value={screeningName}
                  helperText={screeningNameHelperText}
                  error={screeningNameHasError}
                  onChange={(e) => {
                    if (e.target.value) {
                      setScreeningName(e.target.value)
                    } else {
                      setScreeningName('')
                    }
                  }}
                  margin="dense"
                  size='small'
                />
              </Grid>
              <Grid item md={4}>
                <span style={famaFrenchScreenerStyles.spanTitle}>Country of Listing:</span>
                <Autocomplete
                  disablePortal
                  id='famaListingSelection'
                  onChange={(e, value) => {
                    if (value) {
                      setCountryOfListing(value)
                    } else {
                      setCountryOfListing(blankEntry)
                    }
                  }}
                  getOptionLabel={(option) => option.label || ""}
                  options={countryListingOptions}
                  value={countryOfListing}
                  sx={{ width: 300 }}
                  size='small'
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={
                        <>
                          <span>Select Listing (required)</span>
                          <Tooltip title='Make a selection to narrow the screener to a country where the exchange is located'>
                            <HelpOutlineIcon style={{ fill: '#8e95ce' }} />
                          </Tooltip>
                        </>
                      }
                      InputLabelProps={{
                        shrink: true,
                        style: { fontWeight: 'bold' }
                      }}
                      variant="outlined"
                      helperText={countryOfListingHelperText}
                      error={countryOfListingHasError}
                      margin="dense"
                    />
                  )}
                />

              </Grid>
              <Grid item md={4}>
                <span style={famaFrenchScreenerStyles.spanTitle}>Index:</span>
                <Autocomplete
                  disablePortal
                  id='famaIndicesSelection'
                  onChange={(e, value) => {
                    if (value) {
                      setIndex(value)
                    } else {
                      setIndex(blankEntry)
                    }
                  }}
                  getOptionLabel={(option) => option.label || ""}
                  options={indicesOptions}
                  value={index}
                  sx={{ width: 300 }}
                  size='small'
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={
                        <>
                          <span>Select Index</span>
                          <Tooltip title='Make a selection to narrow the screener to the members that make up the selected index'>
                            <HelpOutlineIcon style={{ fill: '#8e95ce' }} />
                          </Tooltip>
                        </>
                      }
                      InputLabelProps={{
                        shrink: true,
                        style: { fontWeight: 'bold' }
                      }}
                      variant="outlined"
                      helperText={indexHelperText}
                      error={indexHasError}
                      margin="dense"
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} columns={16}>
              <Grid item md={4}>
                <span style={famaFrenchScreenerStyles.spanTitle}>Sector:</span>
                <Autocomplete
                  disablePortal
                  id='famaSectorSelection'
                  onChange={(e, value) => {
                    if (industry !== blankEntry) {
                      setIndustry(blankEntry)
                    }
                    if (value) {
                      setSector(value)
                    } else {
                      setSector(blankEntry)
                    }
                  }}
                  getOptionLabel={(option) => option.label || ""}
                  options={sectorOptions}
                  value={sector}
                  sx={{ width: 300 }}
                  size='small'
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={
                        <>
                          <span>Select Sector  </span>
                          <Tooltip title='Make a selection to focus on a wider range of possible business activity than "Industry"'>
                            <HelpOutlineIcon style={{ fill: '#8e95ce' }} />
                          </Tooltip>
                        </>
                      }
                      InputLabelProps={{
                        shrink: true,
                        style: { fontWeight: 'bold' }
                      }}
                      variant="outlined"
                      margin="dense"
                    />
                  )}
                />
              </Grid>
              <Grid item md={4}>
                <span style={famaFrenchScreenerStyles.spanTitle}>Industry:</span>
                <Autocomplete
                  disablePortal
                  id='famaIndustrySelection'
                  onChange={(e, value) => {
                    if (value) {
                      if (value !== blankEntry && sector === blankEntry) {
                        const newSector = sectorOptions.find((element: any) => element.label === value?.meta)
                        if (newSector) {
                          setSector(newSector)
                        }
                      }
                      setIndustry(value)
                    } else {
                      setIndustry(blankEntry)
                    }
                  }}
                  getOptionLabel={(option) => option.label || ""}
                  options={filteredIndustryList}
                  value={industry}
                  sx={{ width: 300 }}
                  size='small'
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={
                        <>
                          <span>Select Industry  </span>
                          <Tooltip title='Make a selection to focus on a narrower range of possible business activity than "Sector"'>
                            <HelpOutlineIcon style={{ fill: '#8e95ce' }} />
                          </Tooltip>
                        </>
                      }
                      InputLabelProps={{
                        shrink: true,
                        style: { fontWeight: 'bold' }
                      }}
                      variant="outlined"
                      margin="dense"
                    />
                  )}
                />
              </Grid>
              {/* <Grid item md={8}>

              </Grid> */}
            </Grid>
            <Grid container spacing={2} columns={16}>
              <Grid item md={4}>
                <span style={famaFrenchScreenerStyles.spanTitle}>Country of Operation:</span>
                <Autocomplete
                  disablePortal
                  id='famaCountrySelection'
                  onChange={(e, value) => {
                    if (value) {
                      setCountryOfOperation(value)
                    } else {
                      setCountryOfOperation(blankEntry)
                    }
                  }}
                  getOptionLabel={(option) => option.label || ""}
                  options={countryOptions}
                  value={countryOfOperation}
                  sx={{ width: 300 }}
                  size='small'
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={
                        <>
                          <span>Select Country </span>
                          <Tooltip title='Make a selection to narrow the screener to a country where the company began operation or primarily operates'>
                            <HelpOutlineIcon style={{ fill: '#8e95ce' }} />
                          </Tooltip>
                        </>
                      }
                      InputLabelProps={{
                        shrink: true,
                        style: { fontWeight: 'bold' }
                      }}
                      variant="outlined"
                      margin="dense"
                    />
                  )}
                />
              </Grid>
              <Grid item md={8}>
                <div style={{ paddingBottom: 6 }}>
                  <span style={famaFrenchScreenerStyles.spanTitle}>Screening Date Point:</span>
                </div>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    label={
                      <>
                        <span>Screen Date  </span>
                        <Tooltip title='Select a date that will restrict the screener to a full quarter of time ending on the selected date, by default, the last full quarter of data will be selected.'>
                          <HelpOutlineIcon style={{ fill: '#8e95ce' }} />
                        </Tooltip>
                      </>
                    }
                    value={screenDate}
                    onChange={(newValue) => {
                      setScreenDate(newValue as moment.Moment)
                    }}
                    minDate={minimumDate}
                    maxDate={maximumDate}
                    sx={{ fontWeight: 'bold' }}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
            <span style={{ textDecoration: 'underline' }}><h3>Criteria:</h3></span>
            <h5>Size:</h5>
            <Grid container columns={16}>
              <Grid item md={3}>
                <TextField id='famaMarketCapStart'
                  variant='outlined'
                  type='number'
                  value={marketCapStart}
                  onChange={(e) => {
                    if (!isNaN(parseFloat(e.target.value))) {
                      setMarketCapStart(parseFloat(e.target.value))
                    } else {
                      setMarketCapStart(undefined)
                    }
                  }}
                  sx={noInputArrows}
                  label={
                    <>
                      <span>Market Cap (in Millions) </span>
                      <Tooltip title='Market Cap range used for screening, units are in Millions'>
                        <HelpOutlineIcon style={{ fill: '#8e95ce' }} />
                      </Tooltip>
                    </>
                  }
                  InputLabelProps={{
                    shrink: true,
                    style: { fontWeight: 'bold' }
                  }}
                  InputProps={{
                    inputProps: { min: 0, 'title': 'Start Value (in Millions)', 'aria-label': 'Start Value (in Millions)' },
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                  onInput={(e) => { (e.target as HTMLInputElement).value = Math.max(0, parseInt((e.target as HTMLInputElement).value)).toString().slice(0, 9) }}
                  helperText='(Start Value)'
                  size='small'
                />
              </Grid>
              <Grid item md={3}>
                <TextField id='famaMarketCapEnd'
                  variant='outlined'
                  helperText='(End Value)'
                  size='small'
                  type='number'
                  value={marketCapEnd}
                  onChange={(e) => {
                    if (!isNaN(parseFloat(e.target.value))) {
                      setMarketCapEnd(parseFloat(e.target.value))
                    } else {
                      setMarketCapEnd(undefined)
                    }
                  }}
                  sx={noInputArrows}
                  InputProps={{
                    inputProps: { min: 0, 'title': 'End Value (in Millions)', 'aria-label': 'End Value (in Millions)' },
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                  onInput={(e) => { (e.target as HTMLInputElement).value = Math.max(0, parseInt((e.target as HTMLInputElement).value)).toString().slice(0, 9) }}
                />
              </Grid>
            </Grid>
            <h5>Value:</h5>
            <Grid container columns={16}>
              <Grid item md={3}>
                <TextField
                  id='famaRatioStart'
                  variant='outlined'
                  type='number'
                  label={
                    <>
                      <span>P/E Ratio </span>
                      <Tooltip title='The value of a company by P/E Ratio'>
                        <HelpOutlineIcon style={{ fill: '#8e95ce' }} />
                      </Tooltip>
                    </>
                  }
                  InputLabelProps={{
                    shrink: true,
                    style: { fontWeight: 'bold' }
                  }}
                  InputProps={{
                    inputProps: { min: 0, 'title': 'Ratio Start Value', 'aria-label': 'Ratio Start Value' },
                  }}
                  onInput={
                    (e) => {
                      (e.target as HTMLInputElement).value.length > 1 ? (e.target as HTMLInputElement).value = Math.max(parseInt((e.target as HTMLInputElement).value)).toString().slice(0, 9) : e.target = e.target
                    }
                  }
                  helperText='(Start Value)'
                  size='small'
                  value={ratioStart}
                  onChange={(e) => {
                    if (!isNaN(parseFloat(e.target.value))) {
                      setRatioStart(parseFloat(e.target.value))
                    }

                    else {
                      setRatioStart(undefined)
                    }
                  }}
                  sx={noInputArrows}
                />
              </Grid>
              <Grid item md={3}>
                <TextField id='famaRatioEnd'
                  variant='outlined'
                  type='number'
                  InputProps={{
                    inputProps: { min: 0, 'title': 'Ratio End Value', 'aria-label': 'Ratio End Value' },
                  }}
                  onInput={
                    (e) => {
                      (e.target as HTMLInputElement).value.length > 1 ? (e.target as HTMLInputElement).value = Math.max(parseInt((e.target as HTMLInputElement).value)).toString().slice(0, 9) : e.target = e.target
                    }
                  }
                  helperText='(End Value)'
                  size='small'
                  value={ratioEnd}
                  onChange={(e) => {
                    if (!isNaN(parseFloat(e.target.value))) {
                      setRatioEnd(parseFloat(e.target.value))
                    } else {
                      setRatioEnd(undefined)
                    }
                  }}
                  sx={noInputArrows}
                />
              </Grid>
            </Grid>
            <h5>Risk:</h5>
            <Grid container columns={16}>
              <Grid item md={3}>
                <TextField
                  id='famaBetaStart'
                  variant='outlined'
                  type='number'
                  label={
                    <>
                      <span>Beta </span>
                      <Tooltip title='The risk of a stock by beta.'>
                        <HelpOutlineIcon style={{ fill: '#8e95ce' }} />
                      </Tooltip>
                    </>
                  }
                  InputLabelProps={{
                    shrink: true,
                    style: { fontWeight: 'bold' }
                  }}
                  InputProps={{
                    inputProps: { min: 0, 'title': 'Beta Start Value', 'aria-label': 'Beta Start Value' },
                  }}
                  onInput={
                    (e) => {
                      (e.target as HTMLInputElement).value.length > 1 ? (e.target as HTMLInputElement).value = Math.max(parseInt((e.target as HTMLInputElement).value)).toString().slice(0, 9) : e.target = e.target
                    }
                  }
                  helperText='(Start Value)'
                  size='small'
                  value={betaStart}
                  onChange={(e) => {
                    if (!isNaN(parseFloat(e.target.value))) {
                      setBetaStart(parseFloat(e.target.value))
                    } else {
                      setBetaStart(undefined)
                    }
                  }}
                  sx={noInputArrows}
                />
              </Grid>
              <Grid item md={3}>
                <TextField id='famaBetaEnd'
                  variant='outlined'
                  type='number'
                  InputProps={{
                    inputProps: { min: 0, 'title': 'Beta End Value', 'aria-label': 'Beta End Value' },
                  }}
                  onInput={
                    (e) => {
                      (e.target as HTMLInputElement).value.length > 1 ? (e.target as HTMLInputElement).value = Math.max(parseInt((e.target as HTMLInputElement).value)).toString().slice(0, 9) : e.target = e.target
                    }
                  }
                  helperText='(End Value)'
                  size='small'
                  value={betaEnd}
                  onChange={(e) => {
                    if (!isNaN(parseFloat(e.target.value))) {
                      setBetaEnd(parseFloat(e.target.value))
                    } else {
                      setBetaEnd(undefined)
                    }
                  }}
                  sx={noInputArrows}
                />
              </Grid>
            </Grid>
            <h5>Momentum:</h5>
            <Grid container spacing={2} columns={12}>
              <Grid item md={2}>
                <TextField
                  id='famaM3Start'
                  variant='outlined'
                  type='number'
                  label={
                    <>
                      <span>3 Months </span>
                      <Tooltip title='The trend in prior returns over time, 3 mo, 12 mo and 36 mo return periods'>
                        <HelpOutlineIcon style={{ fill: '#8e95ce' }} />
                      </Tooltip>
                    </>
                  }
                  InputLabelProps={{
                    shrink: true,
                    style: { fontWeight: 'bold' }
                  }}
                  InputProps={{
                    inputProps: { min: 0, 'title': '3 Months Momentum Start Value', 'aria-label': '3 Months Momentum Start Value' },
                  }}
                  onInput={
                    (e) => {
                      (e.target as HTMLInputElement).value.length > 1 ? (e.target as HTMLInputElement).value = Math.max(parseInt((e.target as HTMLInputElement).value)).toString().slice(0, 9) : e.target = e.target
                    }
                  }
                  helperText='(Start Value)'
                  size='small'
                  value={M3Start}
                  onChange={(e) => {
                    if (!isNaN(parseFloat(e.target.value))) {
                      setM3Start(parseFloat(e.target.value))
                    } else {
                      setM3Start(undefined)
                    }
                  }}
                  sx={noInputArrows}
                />
              </Grid>
              <Grid item md={2}>
                <TextField id='famaM3End'
                  variant='outlined'
                  type='number'
                  InputProps={{
                    inputProps: { min: 0, 'title': '3 Months Momentum End Value', 'aria-label': '3 Months Momentum End Value' },
                  }}
                  onInput={
                    (e) => {
                      (e.target as HTMLInputElement).value.length > 1 ? (e.target as HTMLInputElement).value = Math.max(parseInt((e.target as HTMLInputElement).value)).toString().slice(0, 9) : e.target = e.target
                    }
                  }
                  helperText='(End Value)'
                  size='small'
                  value={M3End}
                  onChange={(e) => {
                    if (!isNaN(parseFloat(e.target.value))) {
                      setM3End(parseFloat(e.target.value))
                    } else {
                      setM3End(undefined)
                    }
                  }}
                  sx={noInputArrows}
                />
              </Grid>
              <Grid item md={2}>
                <TextField id='famaM12Start' label='12 Months' variant='outlined'
                  type='number'
                  InputLabelProps={{
                    shrink: true,
                    style: { fontWeight: 'bold' }
                  }}
                  InputProps={{
                    inputProps: { min: 0, 'title': '12 Months Momentum Start Value', 'aria-label': '12 Months Momentum Start Value' },
                  }}
                  onInput={
                    (e) => {
                      (e.target as HTMLInputElement).value.length > 1 ? (e.target as HTMLInputElement).value = Math.max(parseInt((e.target as HTMLInputElement).value)).toString().slice(0, 9) : e.target = e.target
                    }
                  }
                  helperText='(Start Value)'
                  size='small'
                  value={M12Start}
                  onChange={(e) => {
                    if (!isNaN(parseFloat(e.target.value))) {
                      setM12Start(parseFloat(e.target.value))
                    } else {
                      setM12Start(undefined)
                    }
                  }}
                  sx={noInputArrows}
                />
              </Grid>
              <Grid item md={2}>
                <TextField id='famaM12End'
                  variant='outlined'
                  type='number'
                  InputProps={{
                    inputProps: { min: 0, 'title': '12 Months Momentum End Value', 'aria-label': '12 Months Momentum End Value' },
                  }}
                  onInput={
                    (e) => {
                      (e.target as HTMLInputElement).value.length > 1 ? (e.target as HTMLInputElement).value = Math.max(parseInt((e.target as HTMLInputElement).value)).toString().slice(0, 9) : e.target = e.target
                    }
                  }
                  helperText='(End Value)'
                  size='small'
                  value={M12End}
                  onChange={(e) => {
                    if (!isNaN(parseFloat(e.target.value))) {
                      setM12End(parseFloat(e.target.value))
                    } else {
                      setM12End(undefined)
                    }
                  }}
                  sx={noInputArrows}
                />
              </Grid>
              <Grid item md={2}>
                <TextField id='famaM36Start' label='36 Months' variant='outlined'
                  type='number'
                  InputLabelProps={{
                    shrink: true,
                    style: { fontWeight: 'bold' }
                  }}
                  InputProps={{
                    inputProps: { min: 0, 'title': '36 Months Momentum Start Value', 'aria-label': '36 Months Momentum Start Value' },
                  }}
                  onInput={
                    (e) => {
                      (e.target as HTMLInputElement).value.length > 1 ? (e.target as HTMLInputElement).value = Math.max(parseInt((e.target as HTMLInputElement).value)).toString().slice(0, 9) : e.target = e.target
                    }
                  }
                  helperText='(Start Value)'
                  size='small'
                  value={M36Start}
                  onChange={(e) => {
                    if (!isNaN(parseFloat(e.target.value))) {
                      setM36Start(parseFloat(e.target.value))
                    } else {
                      setM36Start(undefined)
                    }
                  }}
                  sx={noInputArrows}
                />
              </Grid>
              <Grid item md={2}>
                <TextField id='famaM36End'
                  variant='outlined'
                  type='number'
                  InputProps={{
                    inputProps: { min: 0, 'title': '36 Months Momentum End Value', 'aria-label': '36 Months Momentum End Value' },
                  }}
                  onInput={
                    (e) => {
                      (e.target as HTMLInputElement).value.length > 1 ? (e.target as HTMLInputElement).value = Math.max(parseInt((e.target as HTMLInputElement).value)).toString().slice(0, 9) : e.target = e.target
                    }
                  }
                  helperText='(End Value)'
                  size='small'
                  value={M36End}
                  onChange={(e) => {
                    if (!isNaN(parseFloat(e.target.value))) {
                      setM36End(parseFloat(e.target.value))
                    } else {
                      setM36End(undefined)
                    }
                  }}
                  sx={noInputArrows}
                />
              </Grid>
            </Grid>
            <h5>Investment:</h5>
            <Grid container columns={16}>
              <Grid item md={3}>
                <TextField id='famaDividendStart' variant='outlined'
                  type='number'
                  label={
                    <>
                      <span>Dividend Yld </span>
                      <Tooltip title='Direct return on investment expressed by Dividend Yield range'>
                        <HelpOutlineIcon style={{ fill: '#8e95ce' }} />
                      </Tooltip>
                    </>
                  }
                  InputLabelProps={{
                    shrink: true,
                    style: { fontWeight: 'bold' }
                  }}
                  helperText='(Start Value)'
                  size='small'
                  value={dividendYieldStart}
                  onChange={(e) => {
                    if (!isNaN(parseFloat(e.target.value))) {
                      setDividendYieldStart(parseFloat(e.target.value))
                    } else {
                      setDividendYieldStart(undefined)
                    }
                  }}
                  sx={noInputArrows}
                  InputProps={{
                    inputProps: { min: 0, 'title': 'Dividend Yield Start Value', 'aria-label': 'Dividend Yield Start Value' },
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                  onInput={(e) => { (e.target as HTMLInputElement).value = Math.max(0, parseInt((e.target as HTMLInputElement).value)).toString().slice(0, 3) }}
                />
              </Grid>
              <Grid item md={3}>
                <TextField id='famaDividendEnd'
                  variant='outlined'
                  type='number'
                  helperText='(End Value)'
                  size='small'
                  value={dividendYieldEnd}
                  onChange={(e) => {
                    if (!isNaN(parseFloat(e.target.value))) {
                      setDividendYieldEnd(parseFloat(e.target.value))
                    } else {
                      setDividendYieldEnd(undefined)
                    }
                  }}
                  sx={noInputArrows}
                  InputProps={{
                    inputProps: { min: 0, 'title': 'Dividend Yield End Value', 'aria-label': 'Dividend Yield End Value' },
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                  onInput={(e) => { (e.target as HTMLInputElement).value = Math.max(0, parseInt((e.target as HTMLInputElement).value)).toString().slice(0, 3) }}
                />
              </Grid>
            </Grid>
          </Paper>
          <Grid container spacing={2} columns={16} sx={{ paddingTop: 1 }}>
            <Grid item md={2}>
              <BtnClearReset style={{ marginTop: '10px' }} id='resetAllInitialButton' variant='outlined' onClick={showResetConfirmation}>Reset All</BtnClearReset>
            </Grid>
            <Grid item md={2}>
              <CustomBtnBlue id='goToFamaScreeningButton' variant='contained' onClick={goToFamaScreening}>Next</CustomBtnBlue>
              <IconButton
                title='Request in Progress'
                aria-label='Request in Progress' component='label' sx={{
                  p: '10px',
                  color: '#1976d2',
                  visibility: screeningInProgress ? 'visible' : 'hidden'
                }}>
                <CircularProgress title='Request in Progress' aria-label='Request in Progress' />
              </IconButton>
            </Grid>
            <Grid item md={10}>
              {criteriaHasError ?
                <ul>
                  <li style={{ color: errorRedColor }}><span style={{ color: errorRedColor }}>{criteriaHelperText}</span></li>
                </ul> : <></>}
            </Grid>
          </Grid>

        </div> : <></>}
      <div>
        {showFamaScreening ? <FamaFrenchScreeningTable famaProps={famaProps} famaValueProps={famaValueProps} table={table} showResetConfirmation={showResetConfirmation} setLastScreenRequest={setLastScreenRequest} setErrorResponse={setErrorResponse} /> : <></>}
        {showFamaResults ? <FamaFrenchResults userInfo={userInfo} famaProps={famaProps} famaValueProps={famaValueProps} table={table} famaStateProps={famaStateProps} signOut={signOut} showResetConfirmation={showResetConfirmation} lastScreenRequest={lastScreenRequest} setErrorResponse={setErrorResponse} checkIsTrial={checkIsTrial} /> : <></>}
        {famaStateProps.showResetModal ? <ResetAllConfirmation modalProps={famaStateProps.isCancel ? famaStateProps.cancelModalProps : resetAllConfirmationProps} /> : <></>}
        <LoadingScreen showModal={showLoadingModal} setShowModal={setShowLoadingModal} />
      </div>
    </>
  )
}


const famaFrenchScreenerStyles = {
  spanTitle: {
    fontWeight: 'bold',
    textDecoration: 'underline'
  }

}

export default FamaFrenchStartScreen