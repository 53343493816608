import * as React from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import AccountAPI from './AccountSubComponents/AccountAPI'
import BasicInfo from './AccountSubComponents/BasicInfo'
import Access, { AccessProps } from './AccountSubComponents/Access'
import Activity, { ActivityProps } from './AccountSubComponents/Activity'
import { getAuthTokenFromCookie } from '../../Services/CookieAccessService'
import { getAccessInfo, getActivityInfo, getOrganizationActivityInfo, getOrganizationQuotaInfo, getQuotaBreakdownInfo, getQuotaInfo } from '../../Services/AccountService'
import { LoadAccessInfoResponse, LoadActivityInfoResponse, UserAccessActivity, UserAccessCollection } from '../../Models/DataModels/Common/UserAccessModel'
import { CSSProperties, useEffect, useState } from 'react'
import { RedirectLogin } from '../../Services/RedirectionService'
import { QuotaBreakdownInfo, QuotaInfo } from '../../Models/DataModels/Responses/AccountResponses'
import { useNavigate, useParams } from 'react-router-dom'
import { pathParts } from '../../Models/DataModels/Common/RedirectionModel'
import { TabPanel } from '../Common/Utility/TabPanel'
import SessionTimer, { SessionTimerProps } from '../Common/Modals/SessionTimer/SessionTimer'
import { LogoutReasonType } from '../../Models/DataModels/Requests/AuthRequests'
import { RefreshResponse } from '../../Models/DataModels/Responses/AuthResponses'
import { globalStyles } from '../Common/GlobalSettings/GlobalStyles'

export interface AccountProps {
  checkIsBillable: () => boolean,
  checkIsAnonymous: () => boolean,
  signOut: (logoutReason: LogoutReasonType) => void,
  processRefreshTokenResponse: (response: RefreshResponse) => void
}

const accountTabs: any = {
  API: 0,
  basicInfo: 1,
  accessInfo: 2,
  activity: 3,
  organizationActivity: 4
}

const Account = ({
  checkIsBillable,
  checkIsAnonymous,
  signOut,
  processRefreshTokenResponse
}: AccountProps) => {
  const [tabIndex, setTabIndex] = React.useState(0)
  const navigate = useNavigate()
  const { accountSection } = useParams()

  const [accessInfo, setAccessInfo] = useState<UserAccessCollection>()
  const [requestAccessInfoInTransit, setRequestAccessInfoInTransit] = useState(false)
  const [toggleRefreshAccessInfo, setToggleRefreshAccessInfo] = useState(false)
  const [accessInfoError, setAccessInfoError] = useState<string>()

  const [activityInfo, setActivityInfo] = useState<UserAccessActivity[]>([])
  const [requestActivityInfoInTransit, setRequestActivityInfoInTransit] = useState(false)
  const [toggleRefreshActivityInfo, setToggleRefreshActivityInfo] = useState(false)
  const [activityInfoError, setActivityInfoError] = useState<string>()

  const [organizationActivityInfo, setOrganizationActivityInfo] = useState<UserAccessActivity[]>([])
  const [requestOrganizationActivityInfoInTransit, setRequestOrganizationActivityInfoInTransit] = useState(false)
  const [toggleRefreshOrganizationActivityInfo, setToggleRefreshOrganizationActivityInfo] = useState(false)
  const [organizationActivityInfoError, setOrganizationActivityInfoError] = useState<string>()

  const [organizationQuotaInfo, setOrganizationQuotaInfo] = useState<QuotaInfo[]>([])
  const [requestOrganizationQuotaInfoInTransit, setRequestOrganizationQuotaInfoInTransit] = useState(false)
  const [toggleRefreshOrganizationQuotaInfo, setToggleRefreshOrganizationQuotaInfo] = useState(false)
  const [organizationQuotaInfoError, setOrganizationQuotaInfoError] = useState<string>()

  const [quotaInfo, setQuotaInfo] = useState<QuotaInfo[]>([])
  const [requestQuotaInfoInTransit, setRequestQuotaInfoInTransit] = useState(false)
  const [toggleRefreshQuotaInfo, setToggleRefreshQuotaInfo] = useState(false)
  const [quotaInfoError, setQuotaInfoError] = useState<string>()

  const [quotaBreakdownInfo, setQuotaBreakdownInfo] = useState<QuotaBreakdownInfo[]>([])
  const [requestQuotaBreakdownInfoInTransit, setRequestQuotaBreakdownInfoInTransit] = useState(false)
  const [toggleRefreshQuotaBreakdownInfo, setToggleRefreshQuotaBreakdownInfo] = useState(false)
  const [quotaBreakdownInfoError, setQuotaBreakdownInfoError] = useState<string>()

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue)

    switch (newValue) {
      case accountTabs.accessInfo:
        setToggleRefreshAccessInfo(!toggleRefreshAccessInfo)
        navigate(pathParts.account.access)
        break
      case accountTabs.activity:
        setToggleRefreshActivityInfo(!toggleRefreshActivityInfo)
        setToggleRefreshQuotaInfo(!toggleRefreshQuotaInfo)
        setToggleRefreshQuotaBreakdownInfo(!toggleRefreshQuotaBreakdownInfo)
        navigate(pathParts.account.activity)
        break
      case accountTabs.organizationActivity:
        setToggleRefreshOrganizationActivityInfo(!toggleRefreshOrganizationActivityInfo)
        setToggleRefreshQuotaInfo(!toggleRefreshQuotaInfo)
        setToggleRefreshQuotaBreakdownInfo(!toggleRefreshQuotaBreakdownInfo)
        navigate(pathParts.account.organizationActivity)
        break
      case accountTabs.APIInfo:
        navigate(pathParts.account.API)
        break
      case accountTabs.basicInfo:
        navigate(pathParts.account.basicInfo)
        break
    }
  }

  const executeRefreshAccessInfo = () => {
    setRequestAccessInfoInTransit(true)
    getAccessInfo()
      .then((result: any) => {
        const model = LoadAccessInfoResponse(result)
        setAccessInfo(model)
        setAccessInfoError('')
        setRequestAccessInfoInTransit(false)
      },
        //Reject promise
        () => {
          setAccessInfoError('Failed to get access info!')
          setRequestAccessInfoInTransit(false)
        })
  }

  const executeRefreshActivityInfo = () => {
    setRequestActivityInfoInTransit(true)
    getActivityInfo()
      .then((result: any) => {
        const model = LoadActivityInfoResponse(result)
        setActivityInfo(model)
        setActivityInfoError('')
        setRequestActivityInfoInTransit(false)
      },
        //Reject promise
        () => {
          setActivityInfoError('Failed to get activity info!')
          setRequestActivityInfoInTransit(false)
        })
  }

  const executeRefreshOrganizationActivityInfo = () => {
    setRequestOrganizationActivityInfoInTransit(true)
    getOrganizationActivityInfo()
      .then((result: any) => {
        const model = LoadActivityInfoResponse(result)
        setOrganizationActivityInfo(model)
        setOrganizationActivityInfoError('')
        setRequestOrganizationActivityInfoInTransit(false)
      },
        //Reject promise
        () => {
          setOrganizationActivityInfoError('Failed to get Organization activity info!')
          setRequestOrganizationActivityInfoInTransit(false)
        })
  }

  const executeRefreshQuotaInfo = () => {
    setRequestQuotaInfoInTransit(true)
    getQuotaInfo()
      .then((result: any) => {
        const model = result
        setQuotaInfo(model)
        setQuotaInfoError('')
        setRequestQuotaInfoInTransit(false)
      },
        //Reject promise
        () => {
          setQuotaInfoError('Failed to get quota info!')
          setRequestQuotaInfoInTransit(false)
        })
  }

  const executeRefreshOrganizationQuotaInfo = () => {
    setRequestOrganizationQuotaInfoInTransit(true)
    getOrganizationQuotaInfo()
      .then((result: any) => {
        const model = result
        setOrganizationQuotaInfo(model)
        setOrganizationQuotaInfoError('')
        setRequestOrganizationQuotaInfoInTransit(false)
      },
        //Reject promise
        () => {
          setOrganizationQuotaInfoError('Failed to get Organization quota info!')
          setRequestOrganizationQuotaInfoInTransit(false)
        })
  }

  const executeRefreshQuotaBreakdownInfo = () => {
    setRequestQuotaBreakdownInfoInTransit(true)
    getQuotaBreakdownInfo()
      .then((result: any) => {
        const model = result
        setQuotaBreakdownInfo(model)
        setQuotaBreakdownInfoError('')
        setRequestQuotaBreakdownInfoInTransit(false)
      },
        //Reject promise
        () => {
          setQuotaBreakdownInfoError('Failed to get quota breakdown info!')
          setRequestQuotaBreakdownInfoInTransit(false)
        })
  }

  useEffect(() => {
    if (requestAccessInfoInTransit) return
    executeRefreshAccessInfo()
  }, [toggleRefreshAccessInfo])

  useEffect(() => {
    if (requestActivityInfoInTransit) return
    executeRefreshActivityInfo()
  }, [toggleRefreshActivityInfo])

  useEffect(() => {
    if (requestOrganizationActivityInfoInTransit) return
    executeRefreshOrganizationActivityInfo()
  }, [toggleRefreshOrganizationActivityInfo])

  useEffect(() => {
    if (requestQuotaInfoInTransit) return
    executeRefreshQuotaInfo()
  }, [toggleRefreshQuotaInfo])

  useEffect(() => {
    if (requestOrganizationQuotaInfoInTransit) return
    executeRefreshOrganizationQuotaInfo()
  }, [toggleRefreshOrganizationQuotaInfo])

  useEffect(() => {
    if (requestQuotaBreakdownInfoInTransit) return
    executeRefreshQuotaBreakdownInfo()
  }, [toggleRefreshQuotaBreakdownInfo])


  useEffect(() => {
    if (checkIsAnonymous()) {
      setToggleRefreshAccessInfo(!toggleRefreshAccessInfo)
    } else if (accountSection === undefined) {
      navigate(pathParts.account.API)
    } else {
      navigate(accountSection)
      setTabIndex(accountTabs[accountSection])
    }
  }, [])

  const accessProps: AccessProps = {
    AccessInfo: accessInfo,
    TriggerRefreshAccessInfo: () => { setToggleRefreshAccessInfo(!toggleRefreshAccessInfo) },
    AccessInfoRequestInTransit: requestAccessInfoInTransit,
    AccessInfoError: accessInfoError,
    ClearAccessInfoError: () => { setAccessInfoError('') }
  }

  const activityProps: ActivityProps = {
    ActivityInfo: activityInfo,
    QuotaInfo: quotaInfo,
    QuotaBreakdownInfo: quotaBreakdownInfo,

    TriggerRefreshActivityInfo: () => { setToggleRefreshActivityInfo(!toggleRefreshActivityInfo) },
    TriggerRefreshQuotaInfo: () => { setToggleRefreshQuotaInfo(!toggleRefreshQuotaInfo) },
    TriggerRefreshQuotaBreakdownInfo: () => { setToggleRefreshQuotaBreakdownInfo(!toggleRefreshQuotaBreakdownInfo) },

    ActivityInfoRequestInTransit: requestActivityInfoInTransit,
    QuotaInfoRequestInTransit: requestQuotaInfoInTransit,
    QuotaBreakdownInfoRequestInTransit: requestQuotaBreakdownInfoInTransit,

    ActivityInfoError: activityInfoError,
    QuotaInfoError: quotaInfoError,
    QuotaBreakdownInfoError: quotaBreakdownInfoError,

    ClearActivityInfoError: () => { setActivityInfoError('') },
    ClearQuotaInfoError: () => { setQuotaInfoError('') },
    ClearQuotaBreakdownInfoError: () => { setQuotaBreakdownInfoError('') }
  }

  const organizationActivityProps: ActivityProps = {
    hideQuotaBreakdown: true,
    
    ActivityInfo: organizationActivityInfo,
    QuotaInfo: organizationQuotaInfo,
    QuotaBreakdownInfo: [],

    TriggerRefreshActivityInfo: () => { setToggleRefreshOrganizationActivityInfo(!toggleRefreshOrganizationActivityInfo) },
    TriggerRefreshQuotaInfo: () => { setToggleRefreshOrganizationQuotaInfo(!toggleRefreshOrganizationQuotaInfo) },
    TriggerRefreshQuotaBreakdownInfo: () => {},

    ActivityInfoRequestInTransit: requestOrganizationActivityInfoInTransit,
    QuotaInfoRequestInTransit: requestOrganizationQuotaInfoInTransit,
    QuotaBreakdownInfoRequestInTransit: false,

    ActivityInfoError: organizationActivityInfoError,
    QuotaInfoError: organizationQuotaInfoError,
    QuotaBreakdownInfoError: undefined,

    ClearActivityInfoError: () => { setOrganizationActivityInfoError('') },
    ClearQuotaInfoError: () => { setOrganizationQuotaInfoError('') },
    ClearQuotaBreakdownInfoError: () => {}
  }

  const sessionTimerProps: SessionTimerProps = {
    processRefreshTokenResponse,
    signOut: signOut
  }

  const auth: any = getAuthTokenFromCookie()
  if (!auth) {
    return RedirectLogin()
  }

  const renderForAnonymousUser = () => {
    return <div>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tabIndex} aria-label='admin tab'>
            <Tab label='Access' />
          </Tabs>
        </Box>

        <TabPanel value={tabIndex} index={0}>
          <Access {...accessProps} />
        </TabPanel>
      </Box>
    </div>
  }

  if (checkIsAnonymous()) return renderForAnonymousUser()

  return (
    <div style={ { ...globalStyles.mainPageBody } as CSSProperties }>
      <SessionTimer {...sessionTimerProps} />
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tabIndex} onChange={handleChange} aria-label='account tabs'>
            <Tab label='API' />
            <Tab label='Basic Info' />
            <Tab label='Access' />
            <Tab label='Activity' />
            { checkIsBillable() && <Tab label='Organization Activity' />}
          </Tabs>
        </Box>
        <TabPanel value={tabIndex} index={accountTabs.API}>
          <AccountAPI />
        </TabPanel>
        <TabPanel value={tabIndex} index={accountTabs.basicInfo}>
          <BasicInfo />
        </TabPanel>
        <TabPanel value={tabIndex} index={accountTabs.accessInfo}>
          <Access {...accessProps} />
        </TabPanel>
        <TabPanel value={tabIndex} index={accountTabs.activity}>
          <Activity {...activityProps} />
        </TabPanel>
        { checkIsBillable() && <TabPanel value={tabIndex} index={accountTabs.organizationActivity}>
          <Activity {...organizationActivityProps} />
        </TabPanel>}
      </Box>
    </div>
  )
}

export default Account