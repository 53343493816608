import { Autocomplete, Grid, TextField, Tooltip } from '@mui/material'
import { useEffect, useState } from 'react'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment'
import { AppConfigurations } from '../../../Models/DataModels/Common/AppConfigurationsModel'
import { getLocallyStoredConstituentIndices, getLocallyStoredConstituentSubIndices } from '../../../Services/DropDownValuesService'
import { LabelValuePair } from '../../../Models/DataModels/Common/LabelValuePairModel'
import React from 'react'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { renderAutoComputeOption } from './GFDatabase2Point0'

const membershipTypes = {
  current: 'Current',
  historical: 'Historical',
  asOfDate: 'AsOfDate'
}

const membershipIndexTypes = {
  none: 'None',
  majorUS: 'MajorUS',
  gfdForeign: 'GFDForeign'
}

const ConstituentMembership = ({ searchEngineOptions }: any) => {

  const helpMessages = {
    initial: 'Select a category first and then choose an index',
    optionalSteps: 'Optionally select Sub Index (if applicable)',
  }

  const [categoryList, setCategoryList] = useState<any[]>([
    { label: 'Major US Indices', value: membershipIndexTypes.majorUS },
    { label: 'GFD Foreign Indices', value: membershipIndexTypes.gfdForeign }
  ])

  const [indexList, setIndexList] = useState<any[]>([])
  const [subIndexList, setSubIndexList] = useState<any[]>([])
  const [membershipTypeList, setMembershipTypeList] = useState<LabelValuePair[]>([
    { label: 'Historical Membership', value: membershipTypes.historical },
    { label: 'Current Membership', value: membershipTypes.current },
    { label: 'As of Specific Date', value: membershipTypes.asOfDate }
  ])
  const [selectedMembershipType, setSelectedMembershipType] = useState<LabelValuePair>(membershipTypeList.find((entry: LabelValuePair) => entry.value === searchEngineOptions.membershipType) || membershipTypeList[0])

  const [dateError, setDateError] = useState<boolean>(false)
  const [dateHelperText, setDateHelperText] = useState<string>('')
  const [categoryToolTipMessage, setCategoryToolTipMessage] = useState<string>('')

  const [helpMessage, setHelpMessage] = useState<string>(helpMessages.initial)

  const minimumDate: moment.Moment = moment(AppConfigurations.minimumDate)
  const maximumDate: moment.Moment = moment()

  const setCategoryToolTip = () => {
    switch (searchEngineOptions.category) {
      case ('MajorUS'):
        setCategoryToolTipMessage('A historical record of membership for the primary indexes that cover the US stock markets.')
        break
      case ('GFDForeign'):
        setCategoryToolTipMessage(`A historical record of membership GFD's custom indexes that cover the non-US markets.`)
        break
      default:
        setCategoryToolTipMessage('')
        break
    }
  }

  useEffect(() => {
    setCategoryToolTip()
  }, [searchEngineOptions.category])

  const populateIndexList = (categoryValue: string) => {
    searchEngineOptions.setIndex('')

    if (!categoryValue) {
      setIndexList([])
    }

    getLocallyStoredConstituentIndices(categoryValue).then((value: LabelValuePair[] | null) => value && setIndexList(value), () => { })
  }

  const populateSubIndexList = (indexValue: string) => {
    searchEngineOptions.setSubIndex('')

    if (!indexValue) {
      setSubIndexList([])
    }

    getLocallyStoredConstituentSubIndices(indexValue).then((value: LabelValuePair[] | null) => value && setSubIndexList(value), () => { })
  }

  useEffect(() => {
    searchEngineOptions.setIndex('')
    searchEngineOptions.setSubIndex('')

    if (!searchEngineOptions.category || searchEngineOptions?.category === '') {
      setIndexList([...[]])
      return
    }

    populateIndexList(searchEngineOptions.category)
  }, [searchEngineOptions.category])

  useEffect(() => {
    searchEngineOptions.setSubIndex('')

    if (!searchEngineOptions.index || searchEngineOptions?.index === '') {
      setSubIndexList([...[]])
      return
    }

    populateSubIndexList(searchEngineOptions.index)
  }, [searchEngineOptions.index])

  useEffect(() => {
    if (searchEngineOptions?.category === '' || searchEngineOptions?.index === '') {
      setHelpMessage(helpMessages.initial)
    } else {
      setHelpMessage(helpMessages.optionalSteps)
    }
  }, [searchEngineOptions.index, searchEngineOptions.category])

  useEffect(() => {
    if (!searchEngineOptions.membershipType || searchEngineOptions.membershipType === '') {
      searchEngineOptions.setMembershipType(membershipTypeList[0].value)
    }
  }, [searchEngineOptions.membershipType])

  const updateDateHelperText = (message?: string | null) => {
    if (message?.trim()) {
      setDateHelperText(message)
      setDateError(true)
    } else {
      setDateHelperText('')
      setDateError(false)
    }
  }

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item md={4}>
          <Autocomplete
            disablePortal
            id='selectCategory'
            options={categoryList}
            value={searchEngineOptions.category}
            onChange={(event: any, newValue: any | null) => {
              const value = newValue?.value || membershipIndexTypes.majorUS
              searchEngineOptions.setCategory(value)
            }}
            isOptionEqualToValue={(option, value) => option.value === value?.value}
            getOptionLabel={(option) => {
              if (typeof option === 'string') {
                return categoryList.find(item => item.value === option)?.label || categoryList[0].label
              } else {
                return option?.label || categoryList[0].label
              }
            }}
            renderInput={(params) =>
              <TextField {...params}
                label={<React.Fragment>
                  <span>Choose a Category </span>
                  {searchEngineOptions.category &&
                    <Tooltip title={categoryToolTipMessage}>
                      <HelpOutlineIcon />
                    </Tooltip>
                  }
                </React.Fragment>}
                size='small'
              />}
            renderOption={renderAutoComputeOption}
            sx={constituentMembershipStyles.wholeLineInput}
          />
        </Grid>
        <Grid item md={4}>
          <Autocomplete
            disablePortal
            id='selectIndex'
            options={indexList}
            value={searchEngineOptions.index}
            onChange={(event: any, newValue: any | null) => {
              const value = newValue?.value || ''
              searchEngineOptions.setIndex(value)
              populateSubIndexList(value)
            }}
            isOptionEqualToValue={(option, value) => option.value === value?.value}
            getOptionLabel={(option) => {
              if (typeof option === 'string') {
                return indexList.find(item => item.value === option)?.label || ''
              } else {
                return option?.label || ''
              }
            }}
            renderInput={(params) =>
              <TextField {...params}
                label='Choose an Index'
                size='small'

              />}
            renderOption={renderAutoComputeOption}
            sx={constituentMembershipStyles.wholeLineInput}
          />
        </Grid>
        <Grid item md={4}>
          <Autocomplete
            disablePortal
            id='selectSubIndex'
            options={subIndexList}
            value={searchEngineOptions.subIndex}
            onChange={(event: any, newValue: any | null) => {
              searchEngineOptions.setSubIndex(newValue?.value || '')
            }}
            isOptionEqualToValue={(option, value) => option.value === value?.value}
            getOptionLabel={(option) => {
              if (typeof option === 'string') {
                return subIndexList.find(item => item.value === option)?.label || ''
              } else {
                return option?.label || ''
              }
            }}
            renderInput={(params) =>
              <TextField {...params}
                label='Filter by Sub Index'
                size='small'
              />}
            renderOption={renderAutoComputeOption}
            sx={constituentMembershipStyles.wholeLineInput}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item md={4}>
          <Autocomplete
            disablePortal
            id='selectMembershipType'
            options={membershipTypeList}
            value={selectedMembershipType}
            onChange={(event: any, newValue: any | null) => {
              if (newValue) {
                setSelectedMembershipType(newValue)
              } else {
                setSelectedMembershipType(membershipTypeList[0])
              }
              const value = newValue?.value || membershipTypeList[0].value
              searchEngineOptions.setMembershipType(value)

              if (value !== membershipTypes.asOfDate) {
                searchEngineOptions.setDate(null)
              }
            }}
            renderInput={(params) =>
              <TextField {...params}
                label='Choose a Membership Type'
                size='small'
              />}
            renderOption={renderAutoComputeOption}
            sx={constituentMembershipStyles.wholeLineInput}
            disabled={false}
          />
        </Grid>

        <Grid item md={4}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label='As of Date'
              {...(searchEngineOptions.membership !== membershipTypes.asOfDate ? { disabled: true } : {})}
              value={searchEngineOptions.date}
              onChange={(value: moment.Moment | null) => {
                if (value === null || value >= minimumDate) {
                  searchEngineOptions.setDate(moment(value))
                }
              }}
              slotProps={{
                textField: {
                  id: 'asOfDate',
                  variant: 'outlined',
                  size: 'small',
                  InputLabelProps: {
                    shrink: true
                  },
                  helperText: dateHelperText
                }
              }}
              minDate={minimumDate}
              maxDate={maximumDate}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item>
          <div style={{ verticalAlign: 'middle', color: 'darkred' }}>
            {helpMessage}
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

const constituentMembershipStyles = {
  row: {
    display: 'flex',
    flexWrap: 'wrap' as 'wrap'
  },
  wholeLineInput: {
    paddingBottom: 1,
    width: '100%'
  }
}

export default ConstituentMembership
