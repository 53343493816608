import { Accordion, AccordionDetails, Button, Tooltip } from "@mui/material"
import { TickerData } from "../../../Models/DataModels/Responses/AutoTracResponses"
import AddSeries from "../../Icons/AddSeriesIcon"
import { CustomChip } from "../GlobalSettings/CustomStyles"
import React from "react"

export interface DownloadMembersProps {
    tickers?: TickerData[]
}

const DownloadMembers = ({ 
    tickers 
}: DownloadMembersProps) => {

    const [expanded, setExpanded] = React.useState<boolean>(true)
    
    const handleChangeAccordian = (event: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded)
    }

    const renderMember = (ticker: TickerData, index: number) => {
        return <CustomChip key={`tickerChip${index}`} label={ticker.symbol}
          title={ticker.description}
          style={{ margin: '4px' }}
          icon={<AddSeries />}
        />
    }
    
    const renderMembers = () => {
        const chips: any[] = []

        tickers?.forEach((ticker: TickerData, index: number) => chips.push(renderMember(ticker, index)))

        return chips
    }

    const renderShowHideMembers = () => {
        if (!tickers?.length) return <></>
        return <>
            <Accordion expanded={expanded} onChange={handleChangeAccordian} style={{ marginBottom: '16px' }} >
            <Tooltip title='Click to Expand or Hide Options'>
                <Button variant='text' sx={{ width: '100%', padding: 0, backgroundColor: 'gainsboro', color: 'black' }} onClick={() => { setExpanded(!expanded) }}>{expanded ? `Hide Series (${tickers?.length})` : `Show Series (${tickers?.length})`}</Button>
            </Tooltip>
            <AccordionDetails>
                {renderMembers()}
            </AccordionDetails>
            </Accordion>
        </>
    }

    return renderShowHideMembers()
}

export default DownloadMembers
