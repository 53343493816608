import { Tooltip } from "@mui/material"
import { NotOKResponseModel } from "../../../../../Models/DataModels/Common/NotOKResponseModel"
import { UserInfo } from "../../../../../Models/DataModels/Common/UserInfoModel"
import { LogoutReasonType } from "../../../../../Models/DataModels/Requests/AuthRequests"
import { DeleteBtn } from "../../../../Common/GlobalSettings/CustomStyles"
import CancelScreening from "../../../../Icons/CancelScreeningIcon"
import FamaFrenchStartScreen, { FamaStateProps } from "../FamaFrenchSubComponents/FamaFrenchStartScreen"
import { useState } from "react"

export interface FamaFrenchEditorProps {
    userInfo: UserInfo | null,
    screenReportId: number | null | undefined,
    customIndexId: number | null,
    callback: (isCancel: boolean) => void,
    setResponse: (response: NotOKResponseModel | null) => void,
    signOut: (logoutReason: LogoutReasonType) => void,
    checkIsTrial: () => boolean
}

const FamaFrenchEditor = ({
    userInfo,
    screenReportId,
    customIndexId,
    callback,
    setResponse,
    signOut,
    checkIsTrial
}: FamaFrenchEditorProps) => {
    const [showResetModal, setShowResetModal] = useState<boolean>(false)
    const [isCancel, setIsCancel] = useState<boolean>(false)

    const famaStateProps: FamaStateProps = {
        screeningID: screenReportId,
        customIndexID: customIndexId,
        setIsAddOrEditScreen: callback,
        isEdit: true,
        cancelModalProps: {
            resetAll: () => {},
            showModal: showResetModal,
            setShowModal: setShowResetModal,
            isCancel: true,
            cancelScreening: () => {
                callback(true)
            },
            setIsCancel
        },
        showResetModal,
        setShowResetModal,
        isCancel: false,
        setIsCancel
    }
    return <>
        <div>
            <Tooltip title='Cancel Screening'>
            <DeleteBtn variant='contained' style={{ borderRadius: 5, marginTop: '10px', marginBottom: 5 }}
                onClick={() => {
                    callback(true)
                }}
            >
                <CancelScreening style={{ fontSize: '2em' }}></CancelScreening>
            </DeleteBtn>
            </Tooltip>
            <FamaFrenchStartScreen userInfo={userInfo} famaStateProps={famaStateProps} signOut={signOut} setErrorResponse={setResponse} checkIsTrial={checkIsTrial} />
        </div>
    </>
}

export default FamaFrenchEditor
