import { Grid } from '@mui/material'
import React, { CSSProperties, useEffect, useState } from 'react'
import { getAuthTokenFromCookie, getUserInfoFromCookie } from '../../Services/CookieAccessService'
import { RedirectHome, RedirectLogin } from '../../Services/RedirectionService'
import Item from '../Common/Utility/Item'
import ToolPanel from './FinancialToolsSubComponents/ToolPanel'
import ToolSideBar from './FinancialToolsSubComponents/ToolSideBar'
import { UserInfo, isAnonymous } from '../../Models/DataModels/Common/UserInfoModel'
import { LogoutReasonType } from '../../Models/DataModels/Requests/AuthRequests'
import { RefreshResponse } from '../../Models/DataModels/Responses/AuthResponses'
import SessionTimer, { SessionTimerProps } from '../Common/Modals/SessionTimer/SessionTimer'
import { getUserToolAccessibilities } from '../../Services/AccountService'
import { ResourceAccessType, UserResourceAccessibility } from '../../Models/DataModels/Common/AccountModel'
import { NotOKResponseModel } from '../../Models/DataModels/Common/NotOKResponseModel'
import { ToolsAccesibilityProps, ToolsStockProps } from '../../Models/DataModels/Common/ToolsModel'
import { ErrorHandlerProps, ErrorHandler } from '../Common/Utility/ErrorHandler'
import { globalStyles } from '../Common/GlobalSettings/GlobalStyles'

export interface FinancialToolsProps {
  userInfo: UserInfo | null,
  checkIsTrial: () => boolean,
  signOut: (logoutReason: LogoutReasonType) => void,
  processRefreshTokenResponse: (response: RefreshResponse) => void
}


const FinancialTools = ({
  userInfo,
  checkIsTrial,
  signOut,
  processRefreshTokenResponse
}: FinancialToolsProps) => {

  const [currentPanel, setCurrentPanel] = useState<string>('')
  const [canUseTool, setCanUseTool] = useState<boolean | null>(null)
  const [canUseCapeTool, setCanUseCapeTool] = useState<boolean | null>(null)
  const [canUseCapitalizationTool, setCanUseCapitalizationTool] = useState<boolean | null>(null)
  const [loadToolAccessibilityRequestInProgress, setLoadToolAccessibilityRequestInProgress] = useState<boolean>(false)
  const [hasUSStocksAccess, setHasUSStocksAccess] = useState<boolean>(false)
  const [hasUKStocksAccess, setHasUKStocksAccess] = useState<boolean>(false)
  const [hasGFDIndicesAccess, setHasGFDIndicesAccess] = useState<boolean>(false)
  const [errorResponse, setErrorResponse] = useState<NotOKResponseModel | null>(null)

  useEffect(() => {
    setLoadToolAccessibilityRequestInProgress(true)
    getUserToolAccessibilities().then(
      (accessibilities: UserResourceAccessibility) => {
        setLoadToolAccessibilityRequestInProgress(false)
        const hasUSStocksAccess: boolean = accessibilities?.canAccessGFDUniverse === ResourceAccessType.All || accessibilities?.canAccessUSStocks === ResourceAccessType.All || false
        const hasUKStocksAccess: boolean = accessibilities?.canAccessGFDUniverse === ResourceAccessType.All || accessibilities?.canAccessUKStocks === ResourceAccessType.All || false
        const hasGFDatabaseAccess: boolean = accessibilities?.canAccessGFDUniverse === ResourceAccessType.All || accessibilities?.canAccessGFDatabase2Point0 === ResourceAccessType.All || false
        const hasGFDIndicesAccess: boolean = accessibilities?.canAccessGFDUniverse === ResourceAccessType.All || accessibilities?.canAccessGFDIndices === ResourceAccessType.All || false

        setHasUSStocksAccess(hasUSStocksAccess)
        setHasUKStocksAccess(hasUKStocksAccess)
        setHasGFDIndicesAccess(hasGFDIndicesAccess)

        if (!hasUSStocksAccess && !hasUKStocksAccess) {
          setCanUseTool(false)

        } else {
          setCanUseTool(true)
        }

        setCanUseCapitalizationTool(hasUKStocksAccess || hasUSStocksAccess || hasGFDIndicesAccess)

        if(hasGFDatabaseAccess){
          setCanUseCapeTool(true)
        } else {
          setCanUseCapeTool(false)
        }
      },
      //Reject promise
      (notOKResponseModel: NotOKResponseModel) => {
        setCanUseTool(null)
        setCanUseCapeTool(null)
        setCanUseCapitalizationTool(null)
        setLoadToolAccessibilityRequestInProgress(false)
        setErrorResponse(notOKResponseModel)
      }
    )
  }, [])

  const panelStates = {
    currentPanel: currentPanel,
    setCurrentPanel: setCurrentPanel
  }

  const sessionTimerProps: SessionTimerProps = {
    processRefreshTokenResponse,
    signOut: signOut
  }

  const auth: any = getAuthTokenFromCookie()
  if (!auth) {
    return RedirectLogin()
  }

  const user: UserInfo | null = getUserInfoFromCookie()
  if (!user || user?.isTrialUser || isAnonymous(user)) {
    return RedirectHome()
  }

  const accessibilityProps: ToolsAccesibilityProps = {
    canUseTool: canUseTool,
    canUseCapeTool: canUseCapeTool,
    canUseCapitalizationTool,
    loadRequestInProgress: loadToolAccessibilityRequestInProgress
  }

  const stocksProps: ToolsStockProps = {
    hasUSStocksAccess: hasUSStocksAccess,
    hasUKStocksAccess: hasUKStocksAccess,
    hasGFDIndicesAccess: hasGFDIndicesAccess
  }

  const errorHandlerProps: ErrorHandlerProps = {
    response: errorResponse,
    signOut: signOut
  }

  return (
    <div style={ { ...globalStyles.mainPageBody } as CSSProperties }>
      <Grid container spacing={2} columns={16}>
        <Grid item md={3} flexDirection='column'>
          <Item>
            <ToolSideBar panelStates={panelStates} />
          </Item>
        </Grid>
        <Grid item md={13}>
          <Item>
            <ToolPanel userInfo={userInfo} panelStates={panelStates} signOut={signOut} checkIsTrial={checkIsTrial} accessibilityProps={accessibilityProps} stocksProps={stocksProps} />
          </Item>
        </Grid>
      </Grid>
      <SessionTimer {...sessionTimerProps} />
      <ErrorHandler {...errorHandlerProps} />
    </div>
  )
}

export default FinancialTools