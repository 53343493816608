import { Chip, CircularProgress, ClickAwayListener, Divider, IconButton, InputAdornment, Menu, MenuItem, TextField, Typography } from '@mui/material'
import {SimpleTreeView} from '@mui/x-tree-view'
import { Add, Remove, Restore, Save } from '@mui/icons-material'
import { Toolbar, Tooltip } from '@mui/material'
import { useState, useEffect, useRef } from 'react'
import { 
  getWorkbooks,
  addWorkbook,
  deleteWorkbook,
  renameWorkbook,
  deleteWorkbooks,
  copyWorkbook,
  copyWorkbookFromDownloadQueue
} from '../../../Services/AutoTracService'
import ViewList from '@mui/icons-material/ViewList'
import AlertModal, { AlertButtonType, AlertModalProps } from '../../Common/Modals/AlertModal'
import { CSSProperties } from '@mui/material/styles/createTypography'
import React from 'react'
import { ComponentMessageHandler, ComponentMessageHandlerProps } from '../../Common/Utility/ComponentMessageHandler'
import { LogoutReasonType } from '../../../Models/DataModels/Requests/AuthRequests'
import { NotOKResponseModel } from '../../../Models/DataModels/Common/NotOKResponseModel'
import { MessageResponse, MessageResponseTypes, MessageResponseValueType } from '../../../Models/DataModels/Responses/NotOKResponse'
import { DownloadQueueEntry, WorkbookList } from '../../../Models/DataModels/Responses/AutoTracResponses'
import { PaginationRequest } from '../../../Models/DataModels/Requests/SearchRequests'
import GFDPagination, { GFDPaginationProps } from '../../Common/Utility/GFDPagination'
import { AppConfigurations, companyInfo } from '../../../Models/DataModels/Common/AppConfigurationsModel'
import SelectAll from '../../Icons/SelectAllIcon'
import DeselectAll from '../../Icons/DeselectAllIcon'
import ReloadWorkbookList from '../../Icons/ReloadWorkbookListIcon'
import AddWorkbook from '../../Icons/AddWorkbookIcon'
import RenameWorkbook from '../../Icons/RenameWorkbookIcon'
import DeleteWorkbook from '../../Icons/DeleteWorkbookIcon'
import { UserInfo } from '../../../Models/DataModels/Common/UserInfoModel'
import { globalStyles } from '../../Common/GlobalSettings/GlobalStyles'
import StyledTreeItem from '../../Common/Utility/StyledTreeItem'
import { WorkbookCopyRequest } from '../../../Models/DataModels/Requests/AutoTracRequests'
import { GFDToastError, GFDToastInfo, GFDToastSuccess, GFDToastWarning } from '../../Common/Utility/GFDToastify'

export interface WorkbookSelectionProps {
  userInfo: UserInfo | null,
  refreshWorkbookDetails?: () => void,
  onRefreshWorkbookList?: (workbookList: string[]) => void
  onSelectWorkbook?: (workbookName: string) => void
  ExpandAll: boolean,
  signOut: (logoutReason: LogoutReasonType) => void,
  clearMessagesToggle: boolean,
  clearMessages: () => void,
  copiedDownloadQueueEntries?: Map<number, DownloadQueueEntry>
}

export const WorkbookSelection = ({
  userInfo,
  refreshWorkbookDetails,
  onRefreshWorkbookList,
  onSelectWorkbook,
  ExpandAll,
  signOut,
  clearMessages,
  clearMessagesToggle,
  copiedDownloadQueueEntries
}: WorkbookSelectionProps) => {
  const inputRef = useRef()

  const WorkbookNameGuideTooltip = 'Workbook name cannot contain special characters, allowed characters are alphanumeric, hyphen, space, underscore, parentheses, period, ampersand, percent, comma, colon and plus. Maximum length is 50. ' +
    'Press Enter to Save or Escape to Cancel.'
  const folderTitle = userInfo?.orgName || companyInfo.companyLegalName
  const newTitle = `${companyInfo.companyLegalName} - New Workbook`
  const noWorkbookCreated = 'No workbook created'
  const reservedTitles = [ folderTitle, newTitle, noWorkbookCreated ]

  const [workbook, setWorkbook] = useState<string>('')
  const [workbookList, setWorkbookList] = useState<string[]>([])
  const [expanded, setExpanded] = useState<string[]>([folderTitle])
  const [selected, setSelected] = useState<string[]>([])
  const [showModal, setShowModal] = useState<boolean>(false)
  const [renameMode, setRenameMode] = useState<boolean>(false)
  const [addMode, setAddMode] = useState<boolean>(false)
  const [newName, setNewName] = useState(workbook)

  const [sourceWorkbookName, setSourceWorkbookName] = useState<string | null>()
  
  const [messages, setMessages] = useState<MessageResponse[]>([])
  const [response, setResponse] = useState<NotOKResponseModel | null>()

  const [pageNumber, setPageNumber] = useState(1)
  const [perPageCount, setPerPageCount] = useState(AppConfigurations.workbooksPerPage || 100)
  const [startCount, setStartCount] = useState<number>(0)
  const [endCount, setEndCount] = useState<number>(0)
  const [totalCount, setTotalCount] = useState<number>(0)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [applySearch, setApplySearch] = useState<boolean>(false)
  const [requestInProgress, setRequestInProgress] = useState<boolean>(false)

  const [pasteRequestInProgress, setPasteRequestInProgress] = useState<boolean>(false)

  const isDisabledMode = () => ((addMode || renameMode) && newName?.length)
  const isDisabledProp = (): CSSProperties => isDisabledMode() ? { pointerEvents: 'none', disabled: true } : {}
  
  const getPaginationRequest = (resetPageNumber: boolean, requestTotalCount: boolean): PaginationRequest => {
    let page = pageNumber
    if (!page || page <= 0) {
      page = 1
    }

    let perPage = perPageCount
    if (!perPage) {
      perPage = AppConfigurations.workbooksPerPage || 100
    }

    return {
      pageNumber: resetPageNumber ? 1 : page,
      perPageCount: perPage,
      isTotalCountRequested: requestTotalCount
    } as PaginationRequest
  }

  const getWorkbookListRequest  = (resetPageNumber: boolean, requestTotalCount: boolean) => {
    return {
      paginationRequest: getPaginationRequest(resetPageNumber, requestTotalCount),
      searchTerm
    }
  }

  const treeItemRef = useRef<null | HTMLLIElement>(null)

  const displayMessage = (message: string, type: MessageResponseValueType) => {
    const componentMessage: MessageResponse = {
      message : message as string,
      type
    }
    setResponse(null)
    setMessages([ componentMessage ])
    return
  }

  const displayResponse = (model: NotOKResponseModel) => {
    if (model) {
      setMessages([])
      setResponse(model as NotOKResponseModel)
      return
    }
  }

  useEffect(() => {
    executeRefresh(true, true)
    if (ExpandAll) expand()
  }, [])

  useEffect(() => {
    if (onRefreshWorkbookList) onRefreshWorkbookList(workbookList)
  }, [workbookList])

  useEffect(() => {
    if (!selected.some(s => s === workbook)
      && workbook
      && !addMode
      && !renameMode) {
        setSelected([workbook])
      }
  }, [workbook])

  useEffect(() => {
    executeRefresh(false, false)
  }, [pageNumber])

  useEffect(() => {
    if (applySearch) {
      clearAlert()
      expand()
      executeRefresh(true, true)
      setApplySearch(false)
    }
  }, [applySearch])

  useEffect(() => {
    setApplySearch(true)
  }, [searchTerm])

  const focusTextField = () => {
    try {
      if (inputRef?.current) (inputRef.current as any).focus()
    } catch(e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (addMode || renameMode) {
      focusTextField()
    }
  }, [addMode, renameMode])

  const confirmDelete = () => {
    clearAlert()
    resetModes()
    expand()
    if (!workbook 
      && (!selected.length
      || (selected.length === 1 && reservedTitles.some(t => selected.includes(t)))
      )) return
    setShowModal(true)
  }

  const executeDelete = () => {
    if (workbook) {
      deleteWorkbook({ workbookName: workbook })
      .then((result: any) => {
        if (workbook === sourceWorkbookName) setSourceWorkbookName(null)
        executeRefresh(false, true)
        deselectAll()
      },
      (notOKResponseModel: NotOKResponseModel) => {
        displayResponse(notOKResponseModel)
      })
    } else if (selected.length > 1) {
      deleteWorkbooks({ workbookNames: selected })
      .then((result: any) => {
        if (sourceWorkbookName && selected.includes(sourceWorkbookName)) setSourceWorkbookName(null)
        executeRefresh(false, true)
        deselectAll()
      },
      (notOKResponseModel: NotOKResponseModel) => {
        displayResponse(notOKResponseModel)
      })
    }
  }

  const executeAdd = () => {
    if (!addMode) return

    addWorkbook({workbookName: newName})
    .then((result: any) => {
      executeRefresh(false, true)
      executeSelect(newName)
      displayMessage(`Workbook [${newName}] created.`, MessageResponseTypes.Success)
    },
    (notOKResponseModel: NotOKResponseModel) => {
      displayResponse(notOKResponseModel)
    })
  }

  const executeRename = () => {
    if (!renameMode || workbook === newName) return

    renameWorkbook({currentName: workbook, newName: newName})
    .then((result: any) => {
      executeRefresh(false, true)
      executeSelect(newName)
      displayMessage(`Workbook [${workbook}] renamed to [${newName}].`, MessageResponseTypes.Success)
    },
    (notOKResponseModel: NotOKResponseModel) => {
      displayResponse(notOKResponseModel)
    })
  }

  const executeRefresh = (resetPageNumber: boolean, requestTotalCount: boolean) => {
    setRequestInProgress(true)
    getWorkbooks(getWorkbookListRequest(resetPageNumber, requestTotalCount))
    .then((result: WorkbookList) => {
      setWorkbookList([...result.workbookNames])
      if (result?.pagination) {
        if (pageNumber !== result.pagination.pageNumber) setPageNumber(result.pagination.pageNumber)
        if (perPageCount !== result.pagination.perPageCount) setPerPageCount(result.pagination.perPageCount)
        if (result.pagination.totalCount || result.pagination.totalCount === 0) {
          setTotalCount(result.pagination.totalCount)
        }

        const start = result.pagination.pageNumber ? (result.pagination.pageNumber - 1) * result.pagination.perPageCount + 1 : 0
        const end = result?.workbookNames?.length ? start - 1 + result?.workbookNames?.length : 0
        setStartCount(start)
        setEndCount(end)
      }
      if (result?.searchTerm) {
        if (searchTerm !== result.searchTerm) setSearchTerm(result.searchTerm)
      }
      setRequestInProgress(false)
    },
    (notOKResponseModel: NotOKResponseModel) => {
      setRequestInProgress(false)
      displayResponse(notOKResponseModel)
      setWorkbookList([])
    })
  }

  const executePaste = () => {
    clearAlert()

    if (addMode || renameMode
      || !sourceWorkbookName
      || selected.length !== 1
      || !workbook
    ) return

    if (pasteRequestInProgress) {
      displayMessage('Previous copy/paste request is still in progress', MessageResponseTypes.Warning)
      return
    }

    setPasteRequestInProgress(true)
    const request: WorkbookCopyRequest = {
      sourceWorkbookName: sourceWorkbookName,
      targetWorkbookName: workbook,
      downloadQueueIds: null
    }

    copyWorkbook(request)
      .then((result: any) => {
        if (result?.messages?.length) {
          result.messages.forEach((message: MessageResponse) => {
            switch (message.type) {
              case MessageResponseTypes.Error:
                GFDToastError(message.message)
                break
              case MessageResponseTypes.Info:
                GFDToastInfo(message.message)
                break
              case MessageResponseTypes.Internal:
                GFDToastError(message.message)
                break
              case MessageResponseTypes.Success:
                GFDToastSuccess(message.message)
                break
              case MessageResponseTypes.Warning:
                GFDToastWarning(message.message)
                break
              case MessageResponseTypes.Unknown:
                GFDToastInfo(message.message)
                break
            }
          })
        }
        GFDToastSuccess(`Ticker(s) copied to Workbook [${request.targetWorkbookName}].`)
        if (refreshWorkbookDetails) refreshWorkbookDetails()
        setPasteRequestInProgress(false)
      },
      (notOKResponseModel: NotOKResponseModel) => {
        displayResponse(notOKResponseModel)
        setPasteRequestInProgress(false)
      })
  }

  const executePasteFromDownloadQueue = () => {
    clearAlert()

    if (addMode || renameMode
      || !copiedDownloadQueueEntries?.size
      || selected.length !== 1
      || !workbook
    ) return

    if (pasteRequestInProgress) {
      displayMessage('Previous copy/paste request is still in progress', MessageResponseTypes.Warning)
      return
    }

    const copiedIds: number[] = []
    copiedDownloadQueueEntries?.forEach(entry => copiedIds.push(entry.queueID))

    setPasteRequestInProgress(true)
    const request: WorkbookCopyRequest = {
      downloadQueueIds: copiedIds,
      sourceWorkbookName: null,
      targetWorkbookName: workbook
    }

    copyWorkbookFromDownloadQueue(request)
      .then((result: any) => {
        if (result?.messages?.length) {
          result.messages.forEach((message: MessageResponse) => {
            switch (message.type) {
              case MessageResponseTypes.Error:
                GFDToastError(message.message)
                break
              case MessageResponseTypes.Info:
                GFDToastInfo(message.message)
                break
              case MessageResponseTypes.Internal:
                GFDToastError(message.message)
                break
              case MessageResponseTypes.Success:
                GFDToastSuccess(message.message)
                break
              case MessageResponseTypes.Warning:
                GFDToastWarning(message.message)
                break
              case MessageResponseTypes.Unknown:
                GFDToastInfo(message.message)
                break
            }
          })
        }
        GFDToastSuccess(`Ticker(s) copied to Workbook [${request.targetWorkbookName}].`)
        if (refreshWorkbookDetails) refreshWorkbookDetails()
        setPasteRequestInProgress(false)
      },
      (notOKResponseModel: NotOKResponseModel) => {
        displayResponse(notOKResponseModel)
        setPasteRequestInProgress(false)
      })
  }

  const executeSelect = (workbook: string) => {
    resetModes()
    setWorkbook(workbook)
    if (onSelectWorkbook) onSelectWorkbook(workbook)
  }

  const resetModes = () => {
    setRenameMode(false)
    setAddMode(false)
  }

  const rename = () => {
    clearAlert()
    expand()
    if (!workbook) return
    setRenameMode(true)
    setAddMode(false)
    setNewName(workbook)
  }

  const refresh = () => {
    clearAlert()
    resetModes()
    expand()
    executeRefresh(false, true)
  }

  const expand = () => {
    if (expanded.length === 0)
    {
       setExpanded([ folderTitle ])
    }
  }

  const add = () => {
    clearAlert()
    expand()
    executeSelect('')
    setSelected([])
    setAddMode(true)
    setRenameMode(false)
    setNewName('')
  }

  const cancel = () => {
    clearAlert()
    resetModes()
    setNewName('')
  }

  const clearAlertsInCurrentWindow = () => {
    setMessages([])
    setResponse(null)
  }

  const clearAlert = () => {
    clearAlertsInCurrentWindow()
    clearMessages()
  }

  useEffect(() => {
    clearAlertsInCurrentWindow()
  }, [clearMessagesToggle])

  const handleSelect = (event: React.SyntheticEvent, nodeIds: string[]) => {
    if (isDisabledMode()) {
      event.stopPropagation()
      event.preventDefault()
      return
    }
    
    clearAlert()

    if (
      (addMode 
      && nodeIds.length === 1 
      && nodeIds[0] === newTitle) 
      || (renameMode 
      && nodeIds.length === 1 
      && nodeIds[0] === workbook)
    ) return

    setSelected(nodeIds)
    if (nodeIds.length === 1 && !reservedTitles.some(t => t === nodeIds[0])) {
      executeSelect(nodeIds[0])
    } else {
      executeSelect('')
    }
  }

  const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
    if (isDisabledMode()) {
      event.stopPropagation()
      event.preventDefault()
      return
    }

    clearAlert()

    setExpanded(nodeIds)
  }

  const selectAll = () => {
    clearAlert()
    expand()
    resetModes()
    deselectAll() // clear first
    setSelected(workbookList)
  }

  const deselectAll = () => {
    clearAlert()
    expand()
    setSelected([])
    executeSelect('')
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearAlertsInCurrentWindow()

    if (AppConfigurations.workbookNameRegEx.test(event.target.value)) {
      setNewName(event.target.value)
    }
  }

  const acceptChange = () => {
    clearAlertsInCurrentWindow()

    if (newName === workbook) {
      cancel()
    } else if (newName.length <= 0) {
      displayMessage('New name is empty.', MessageResponseTypes.Error)
    } else if (workbookList.some(w => w === newName)) {
      displayMessage(`Workbook name [${newName}] already exists.`, MessageResponseTypes.Error)
    } else if (reservedTitles.some(t => t === newName)) {
      displayMessage(`Workbook name [${newName}] is reserved.`, MessageResponseTypes.Error)
    } else {
      if (renameMode) {
        executeRename()
      } else if (addMode) {
        executeAdd()
      } else {
        cancel()
      }
    }
  }

  const handleKeyDown = (event: any) => {
    event.stopPropagation()
    if (event.key === 'Enter') {
      acceptChange()
    } else if (event.key === 'Escape') {
      cancel()
    }
  }

  const handleClickOutside = (event: MouseEvent | TouchEvent) => {
    disableWhenAddRenameMode(event)
  }

  const renderEditTextField = () => {
    return (
      <ClickAwayListener mouseEvent={'onMouseDown'} onClickAway={handleClickOutside} disableReactTree={true}>
      <TextField
        inputRef={inputRef}
        style={WorkbookSelectionStyles.EditContainer}
        value={newName}
        variant='outlined'
        onKeyDown={handleKeyDown}
        onClick={(event: any) => event.stopPropagation()}
        onChange={handleChange}
        sx={WorkbookSelectionStyles.Edit}
        autoFocus
        inputProps={{type: 'text', maxLength: 50}}
        title={WorkbookNameGuideTooltip}
        InputProps={{
          endAdornment: 
          <InputAdornment position='end'>
             <IconButton
              aria-label='Cancel Changes'
              title='Cancel Changes'
              onClick={cancel}
            >
              <Restore />
            </IconButton>
            <IconButton
              aria-label='Save Changes'
              title='Save Changes'
              onClick={acceptChange}
              edge='end'
            >
              <Save />
            </IconButton>
          </InputAdornment>
          }}
      />
      </ClickAwayListener>
    )
  }

  const NodeLabelWithContextMenu = ({ label }: any) => {
    const [contextMenu, setContextMenu] = React.useState<{
      mouseX: number,
      mouseY: number
    } | null>(null)
  
    const handleContextMenu = (event: React.MouseEvent) => {
      event.preventDefault()
      setContextMenu(
        contextMenu === null
          ? {
              mouseX: event.clientX + 2,
              mouseY: event.clientY - 6
            }
          : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
            // Other native context menus might behave different.
            // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
            null
      )
    }
  
    const handleCloseContextMenu = () => {
      setContextMenu(null)
    }

    const renderCopiedList = (): string => {
      const list: string[] = []
      copiedDownloadQueueEntries?.forEach(entry => list.push(` ${entry.worksheetName}`))
      return `[${list.toString()} ]`
    }

    const renderContextMenu = () => {
      return <Menu
        id='context-menu'
        open={contextMenu !== null}
        onClose={handleCloseContextMenu}
        anchorReference='anchorPosition'
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        {(selected.length === 1 && selected.includes(label)) && <MenuItem
          key={'renameWorkbookPopUpMenuItem'}
          onClick={(event) => {
            event.stopPropagation()
            event.preventDefault()
            handleCloseContextMenu()
            rename()
          }}
        >
          Rename Workbook
        </MenuItem>}
        {selected.includes(label) && <MenuItem
          key={'deleteWorkbookPopUpMenuItem'}
          onClick={(event) => {
            event.stopPropagation()
            event.preventDefault()
            handleCloseContextMenu()
            confirmDelete()
          }}
        >
          Delete Workbook
        </MenuItem>}
        <MenuItem
          key={'addWorkbookPopUpMenuItem'}
          onClick={(event) => {
            event.stopPropagation()
            event.preventDefault()
            handleCloseContextMenu()
            add()
          }}
        >
          Add Workbook
        </MenuItem>
        {(selected.length === 1 && selected.includes(label) && <Divider />)}
        {(selected.length === 1 && selected.includes(label)) && <MenuItem
          key={'copyWorkbookPopUpMenuItem'}
          onClick={(event) => {
            event.stopPropagation()
            event.preventDefault()
            handleCloseContextMenu()
            setSourceWorkbookName(label)
          }}
        >
          Copy AutoTrac Workbook Contents
        </MenuItem>}
        {(selected.length === 1 && selected.includes(label) && 
          sourceWorkbookName && sourceWorkbookName !== label) && <MenuItem
          key={'pasteWorkbookPopUpMenuItem'}
          onClick={(event) => {
            event.stopPropagation()
            event.preventDefault()
            handleCloseContextMenu()
            executePaste()
          }}
          title={`Contents from AutoTrac Workbook: ${sourceWorkbookName}`}
        >
          Paste AutoTrac Workbook Contents
        </MenuItem>}
        {(selected.length === 1 && selected.includes(label) && Boolean(copiedDownloadQueueEntries?.size) && <Divider />)}
        {(selected.length === 1 && selected.includes(label) && 
          Boolean(copiedDownloadQueueEntries?.size)) && <MenuItem
          key={'pasteWorkbookPopUpMenuItem'}
          onClick={(event) => {
            event.stopPropagation()
            event.preventDefault()
            handleCloseContextMenu()
            executePasteFromDownloadQueue()
          }}
          title={`Contents from OnDemand Workbook(s): ${renderCopiedList()}`}
        >
          Paste OnDemand Workbook Contents 
        </MenuItem>}
      </Menu>
    }
  
    return (
      <div onContextMenu={handleContextMenu} style={{ cursor: 'context-menu' }}>
        <Typography style={{ display: 'inline' }}>{label}</Typography>
        {pasteRequestInProgress && label === workbook ? <Tooltip title='Paste Request in Progress'>
            <IconButton aria-label='Paste Request in Progress' component='label'>
              <CircularProgress color='primary' style={{fontSize: '1.2em', ...globalStyles.circularProgress, display: 'inline'}} title='Paste Request in Progress' aria-label='Paste Request in Progress'/>
            </IconButton>
          </Tooltip> : <></>}
        {renderContextMenu()}
      </div>
    )
  }

  const disableWhenAddRenameMode = (event: any) => {
    if (isDisabledMode()) {
      event.stopPropagation()
      event.preventDefault()
      acceptChange()
      return
    }
    cancel()
  }

  const renderWorkbook = (workbookName: string, index: number) => {
    return (
      renameMode && workbookName === workbook ?
      <StyledTreeItem
        key={index}
        label={renderEditTextField()}
        itemId={workbookName}
        slots={{
          icon: treeItemIcon
        }}
        onDoubleClick={rename}
        title={workbookName}
        ref={workbook === workbookName ? treeItemRef : null}
      />
      :
      <StyledTreeItem
        style={{ ...isDisabledProp() }}
        key={index}
        label={<NodeLabelWithContextMenu style={{ ...isDisabledProp() }} label={workbookName} />}
        itemId={workbookName}
        slots={{
          icon: treeItemIcon
        }}
        onDoubleClick={rename}
        title={workbookName}
        ref={workbook === workbookName ? treeItemRef : null}
      />
    )
  }

  const treeItemIcon = () => {
    return <ViewList style={WorkbookSelectionStyles.TreeIcon} />
  }
  
  const renderAddWorkbook = () => {
    if (addMode) return (
      <StyledTreeItem
        label={renderEditTextField()}
        itemId={newTitle}
        slots={{
          icon: treeItemIcon
        }}
      />
    )
  }
  
  const renderWorkbookList = () => {
    return (<StyledTreeItem label={folderTitle} itemId={folderTitle}>
      {renderAddWorkbook()}
      {addMode || (workbookList && workbookList?.length) ?
      workbookList?.map((workbook, index) => renderWorkbook(workbook, index)) || []
      : <StyledTreeItem
        label={<Typography style={{ fontStyle: 'italic' }}>{noWorkbookCreated}</Typography>}
        itemId={noWorkbookCreated}
      />}
    </StyledTreeItem>)
  }

  const renderToolbar = () => {
    return (
      <>
        <Toolbar style={{...WorkbookSelectionStyles.Toolbar, ...isDisabledProp()} as CSSProperties}>
          <Tooltip title='Select All'>
            <IconButton aria-label='Select All Workbooks' component='label' onClick={selectAll}>
              <SelectAll color='primary' style={{fontSize: '1.2em'}}/>
            </IconButton>
          </Tooltip>
          <Tooltip title='Deselect All'>
            <IconButton aria-label='Deselect All Workbooks' component='label' onClick={deselectAll}>
              <DeselectAll color='primary' style={{fontSize: '1.2em'}}/>
            </IconButton>
          </Tooltip>
          <Tooltip title='Reload Workbook List'>
            <IconButton aria-label='Reload Workbook List' component='label' onClick={refresh}>
              <ReloadWorkbookList color='primary' style={{fontSize: '1.2em'}}/>
            </IconButton>
          </Tooltip>
          <Tooltip title='Add Workbook'>
            <IconButton aria-label='Add Workbook' component='label' onClick={add}>
              <AddWorkbook color='primary' style={{fontSize: '1.2em'}}/>
            </IconButton>
          </Tooltip>
          <Tooltip title='Rename Workbook'>
            <IconButton aria-label='Rename Workbook' component='label' onClick={rename}>
              <RenameWorkbook color='primary' style={{fontSize: '1.2em'}}/>
            </IconButton>
          </Tooltip>
          <Tooltip title='Delete Workbook'>
            <IconButton aria-label='Delete Workbook' component='label' onClick={confirmDelete}>
              <DeleteWorkbook color='primary' style={{fontSize: '1.2em'}}/>
            </IconButton>
          </Tooltip>
          {requestInProgress ? <Tooltip title='Request in Progress'>
            <IconButton aria-label='Request in Progress' component='label'>
              <CircularProgress color='primary' style={{fontSize: '1.2em', ...globalStyles.circularProgress}} title='Request in Progress' aria-label='Request in Progress'/>
            </IconButton>
          </Tooltip> : <></>}
        </Toolbar>
      </>
    )
  }

  const alertModalProps: AlertModalProps = {
    showModal,
    setShowModal,
    AlertTitle: 'Are you sure?',
    AlertContent: `Would you like to delete workbook(s) [${workbook || selected.filter(x => !reservedTitles.includes(x)).join(', ')}]? This procedure is irreversible. Do you want to proceed?`,
    AlertButtons: [
      {
        type: AlertButtonType.Cancel,
        display: 'Cancel',
        onClick: () => {
          setShowModal(false)
        },
        isPrimary: false,
      },
      {
        type: AlertButtonType.OK,
        display: 'Yes',
        onClick: () => {
          setShowModal(false)
          executeDelete()
        },
        isPrimary: true,
      }
    ],
    onAlertClose: () => {
      return true
    }
  }

  const getLabelName = () => {
    const label = `Workbooks (${startCount} - ${endCount} of ${totalCount})`
    return label
  }

  const componentMessageHandlerProps: ComponentMessageHandlerProps = {
    messages,
    setMessages,
    response,
    signOut
  }

  const paginationProps: GFDPaginationProps = {
    searchTerm,
    setSearchTerm,
    startCount,
    endCount,
    perPageCount,
    pageNumber,
    totalCount,
    setPageNumber: (page: number) => { 
      clearAlert()
      setPageNumber(page)
    },
    setPerPageCount
  }

  const AddTree = () => {
    return <Add style={{ ...WorkbookSelectionStyles.TreeIcon, ...isDisabledProp() }} />
  }
  
  const RemoveTree = () => {
    return <Remove style={{ ...WorkbookSelectionStyles.TreeIcon, ...isDisabledProp() }} />
  }

  return (
    <div style={WorkbookSelectionStyles.TreeContainer as CSSProperties}>
      <Chip label={getLabelName()} color='primary' style={WorkbookSelectionStyles.TreeTitle}/>
      <div style={{ ...WorkbookSelectionStyles.TreeHeader, ...isDisabledProp() }}>
        {renderToolbar()}
      </div>
      <div style={WorkbookSelectionStyles.TreeMessage}>
        <ComponentMessageHandler {...componentMessageHandlerProps} />
      </div>
      <div style={{ ...WorkbookSelectionStyles.TreePagination, ...isDisabledProp() }}>
        
      </div>
      <div style={{ ...WorkbookSelectionStyles.TreePagination, ...isDisabledProp() }}>
        <GFDPagination {...paginationProps} />
      </div>
      <div style={WorkbookSelectionStyles.TreeBody}>
        <SimpleTreeView
          sx={{ 
            width: '100%',
            minHeight: '0',
            overflow: 'auto',
          }}
          aria-label='controlled'
          slots={{
            expandIcon: AddTree,
            collapseIcon: RemoveTree
          }}
          defaultExpandedItems={[folderTitle]}
          expandedItems={expanded}
          selectedItems={selected}
          onExpandedItemsChange={handleToggle}
          onSelectedItemsChange={handleSelect}
          multiSelect
        >
          {renderWorkbookList()}
        </SimpleTreeView>
      </div>
      <AlertModal {...alertModalProps} />
    </div>
  )
}

const WorkbookSelectionStyles = {
  TreeIcon: {
    color: 'teal'
  },
  EditContainer: {
    width: '100%'
  },
  Edit: {
    input: {
      padding:'4px 8px',
      backgroundColor: 'whitesmoke',
      fontSize: 'default'
    }
  },
  Toolbar: {
    flexWrap: 'wrap',
    padding: '0 0 0 0',
    minHeight: '48px',
    flexGrow: '0',
    flexBasis: 'auto',
  },
  TreePagination: {
    width: '100%',
    flexGrow: '0',
    flexBasis: 'auto',
    borderBottom: '1px solid rgb(224, 224, 224)',
  },
  TreeMessage: {
    width: '100%',
    flexGrow: '0',
    flexBasis: 'auto',
  },
  TreeTitle: {
    width: '100%',
    backgroundColor: '#007ea8',
    color: 'white',
    borderRadius: 2,
    flexBasis: '32px',
    flexGrow: '0',
  },
  TreeHeader: {
    width: '100%',
    flexGrow: '0',
    flexBasis: 'auto',
    borderBottom: '1px solid rgb(224, 224, 224)',
  },
  TreeBody: {
    width: '100%',
    flexBasis: '0',
    flexGrow: '1',
    display: 'flex',
    overflow: 'auto',
  },
  TreeContainer: {
    width: '100%',
    height: '100%',
    maxHeight: 'inherit',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  }
}

export default WorkbookSelection
