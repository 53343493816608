import { Autocomplete, Grid, TextField, Tooltip } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { useEffect, useState } from 'react'
import { ALLCountryGroupEntry, SearchDatabaseTypes } from '../../../Models/DataModels/Common/FieldPopulationModel'
import DateYearRangeInput from './DateYearRangeInput'
import { LabelValuePair } from '../../../Models/DataModels/Common/LabelValuePairModel'
import { getLocallyStoredCountriesByGroup, getLocallyStoredCountryGroups, getLocallyStoredSectors, getLocallyStoredSeriesTypes } from '../../../Services/DropDownValuesService'
import { countryGroupToolTipList } from '../../Common/Utility/SearchPageToolTips'
import React from 'react'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { renderAutoComputeOption } from './GFDatabase2Point0'

const GFDIndices = ({ searchEngineOptions, theme }: any) => {
  const [countryGroupList, setCountryGroupList] = useState<LabelValuePair[]>([ALLCountryGroupEntry])
  const [countriesByGroup, setCountriesByGroup] = useState<LabelValuePair[]>([])
  const [seriesTypeList, setSeriesTypeList] = useState<LabelValuePair[]>([])
  const [sectorList, setSectorList] = useState<LabelValuePair[]>([])
  const [countryGroupToolTip, setCountryGroupToolTip] = useState<string>('')

  useEffect(() => {
    getLocallyStoredCountryGroups().then((value: LabelValuePair[] | null) => value && setCountryGroupList(value), () => { })
    getLocallyStoredSeriesTypes(SearchDatabaseTypes.GFDIndices, '').then((value: LabelValuePair[] | null) => value && setSeriesTypeList(value), () => { })
    getLocallyStoredSectors(SearchDatabaseTypes.GFDIndices, searchEngineOptions.seriesType).then((value: LabelValuePair[] | null) => value && setSectorList(value), () => { })
  }, [])

  useEffect(() => {
    getLocallyStoredCountriesByGroup(searchEngineOptions.countryGroup).then((value: LabelValuePair[] | null) => value && setCountriesByGroup(value), () => { })
  }, [searchEngineOptions.countryGroup])

  useEffect(() => {
    const toolTip: string = countryGroupToolTipList.find((element: any) => element.label === searchEngineOptions?.countryGroup)?.toolTip || ''
    setCountryGroupToolTip(toolTip)
  }, [searchEngineOptions.countryGroup])

  useEffect(() => {
    getLocallyStoredSectors(SearchDatabaseTypes.GFDIndices, searchEngineOptions.seriesType).then(
      (value: LabelValuePair[] | null) => {
        if (value) {
          setSectorList(value)
          const found: boolean = (searchEngineOptions.sector && value.some(entry => entry.value === searchEngineOptions.sector))
          if (!found)
            searchEngineOptions.setSector(null)
        } else {
          setSectorList([])
          searchEngineOptions.setSector('')
        }
      }, () => { }
    )
  }, [searchEngineOptions.seriesType])

  return (
    <ThemeProvider theme={theme}>
      <div>
        <Grid container spacing={2}>
          <Grid item md={4}>
            <Autocomplete
              disablePortal
              options={countryGroupList}
              defaultValue={ALLCountryGroupEntry}
              isOptionEqualToValue={(option: any, value) => option?.value === value?.value}
              onChange={(e, value) => {
                let actualValue: any = ''
                typeof (value?.value) !== (null) ? actualValue = value?.value : actualValue = ''
                searchEngineOptions.setCountryGroup(actualValue)
              }}
              renderInput={(params) =>
                <TextField {...params}
                  label={<React.Fragment>
                    <span>Filter by Country Group </span>
                    {searchEngineOptions.countryGroup &&
                      <Tooltip title={countryGroupToolTip}>
                        <HelpOutlineIcon />
                      </Tooltip>
                    }
                  </React.Fragment>}
                  size='small'
                />
              }
              renderOption={renderAutoComputeOption}
              sx={GFDIndicesStyles.wholeLineInput}
            />
          </Grid>
          <Grid item md={4}>
            <Autocomplete
              disablePortal
              id='selectCountry'
              options={countriesByGroup}
              isOptionEqualToValue={(option: any, value) => option.value === value.value}
              onChange={(e, value) => {
                let actualValue: any = ''
                typeof (value?.value) !== (null) ? actualValue = value?.value : actualValue = ''
                searchEngineOptions.setCountry(actualValue)
              }}
              renderInput={(params) =>
                <TextField {...params}
                  label='Select Country'
                  size='small'
                />}
              renderOption={renderAutoComputeOption}
              sx={GFDIndicesStyles.wholeLineInput}
            />
          </Grid>
          <Grid item md={4}>
            <Autocomplete
              disablePortal
              id='selectSeriesType'
              options={seriesTypeList}
              value={searchEngineOptions.seriesType || ''}
              onChange={(e, value) => {
                const actualValue = value?.value ? value?.value : ''
                searchEngineOptions.setSeriesType(actualValue)
              }}
              renderInput={(params) =>
                <TextField {...params}
                  label='Filter by Series Type'
                  size='small'
                  value={searchEngineOptions.seriesType || ''}
                />}
              renderOption={renderAutoComputeOption}
              sx={GFDIndicesStyles.wholeLineInput}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={4}>
            <Autocomplete
              disablePortal
              id='selectSector'
              options={sectorList}
              value={searchEngineOptions.sector || ''}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              onChange={(e, value) => {
                let actualValue: any = ''
                typeof (value?.value) !== (null) ? actualValue = value?.value : actualValue = ''
                searchEngineOptions.setSector(actualValue)
              }}
              renderInput={(params) =>
                <TextField {...params}
                  label='Filter by Sector'
                  size='small'
                />}
              renderOption={renderAutoComputeOption}
              sx={GFDIndicesStyles.wholeLineInput}
            />
          </Grid>
          <DateYearRangeInput setIsDatesValid={searchEngineOptions.setDatesValid} setValidStartYear={searchEngineOptions.setStartYear} setValidEndYear={searchEngineOptions.setEndYear} setIsOnlyInYearsRange={searchEngineOptions.setIsOnlyInYearsRange} startYearGridItemSizeOverride={2} EndYearGridItemSizeOverride={2} inRangeCheckboxGridItemSizeOverride={3} />
        </Grid>
      </div>
    </ThemeProvider>
  )
}

const GFDIndicesStyles = {
  row: {
    display: 'flex',
    flexWrap: 'wrap' as 'wrap'
  },
  wholeLineInput: {
    paddingBottom: 1,
    width: '100%'
  }
}

export default GFDIndices
