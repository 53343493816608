import { Snackbar } from '@mui/material'
import Alert from '@mui/material/Alert'
import React, { useState } from 'react'

export interface SiteNotificationProps {
    siteNotificationMessage?: string | null
}

const SiteNotification = ({
    siteNotificationMessage
}: SiteNotificationProps) => {

    const [isVisible, setIsVisible] = useState<boolean>(true)

    const handleClose = () => {
        setIsVisible(false)
    }

    if (!siteNotificationMessage || !isVisible) {
        return <></>
    }

    return (
        <div>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={isVisible}>
                <Alert
                    onClose={handleClose}
                    severity='error'
                    variant='filled'
                    sx={{ width: '100%' }}
                >
                    {siteNotificationMessage}
                </Alert>
            </Snackbar>
        </div>
    )
}

export default SiteNotification
