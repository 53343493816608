import { Grid } from "@mui/material"
import React, { useEffect, useState } from "react"
import { Modal } from "react-bootstrap"
import { CustomBtnCloseCancel } from "../GlobalSettings/CustomStyles"
import { companyInfo } from "../../../Models/DataModels/Common/AppConfigurationsModel"

const TrialOptionsModal = ({ modalProps, chartSettings }: any) => {

    const [filteredSettings, setFilteredSettings] = useState<any[]>([])
    const [formattedSettings, setFormattedSettings] = useState<any[]>([])

    const filterSettings = () => {
        const resultArray: any[] = chartSettings ? Object.keys(chartSettings).map((key) => (key + ': ' + chartSettings[key])) : []

        const filteredResult: any[] = resultArray.filter((element: any) => {
            switch (true) {
                case element.includes('years'):
                    return element
                case element.includes('periodicity'):
                    return element
                case element.includes('scaleType'):
                    return element
                case element.includes('chartType'):
                    return element
                case element.includes('splitAdjusted'):
                    return element
                default:
                    return
            }
        })
        setFilteredSettings(filteredResult)
    }

    const formatArray = () => {
        if (filteredSettings && Array.isArray(filteredSettings) && filteredSettings.length > 0) {
            const formattedArray: any[] = filteredSettings?.map((element: any) => {
                let newStr = element.replace(/([A-Z])/g, ' $1').replace(/^./, (str: string) => { return str.toUpperCase() }).replace(/([:\?]\s+)(.)/g, (str: string) => {
                    return str.toUpperCase()
                })
                return newStr
            })
            setFormattedSettings(formattedArray)
        }
    }

    const handleClose = () => {
        modalProps.setShowModal(false)
    }

    useEffect(() => {
        filterSettings()
    }, [chartSettings])

    useEffect(() => {
        formatArray()
    }, [filteredSettings])

    return (
        <React.Fragment>
            <Modal show={modalProps.showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Graph Options
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Grid container direction={'column'} spacing={1}>
                        {formattedSettings?.map((element: any, index: number) => (
                            <Grid key={`trialSettings${index}`} item>
                                {element}
                            </Grid>
                        ))}
                        <Grid item>
                            <i>
                                These are the default options for a trial user graph. To change these settings and to unlock many more options, please talk to {companyInfo.companyName} sales at {companyInfo.salesPhoneNumberDashFormatted}.
                            </i>
                        </Grid>
                    </Grid>
                </Modal.Body>

                <Modal.Footer>
                    <CustomBtnCloseCancel variant="outlined" onClick={handleClose}>
                        Close
                    </CustomBtnCloseCancel>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export default TrialOptionsModal