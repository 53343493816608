import React from 'react'
import CAPERatios, { CAPERatioProps } from './ToolPanelSubComponents/CAPERatios'
import Capitalization from './ToolPanelSubComponents/Capitalization'
import CurrencyConverter from './ToolPanelSubComponents/CurrencyConverter'
import YieldCurve from './ToolPanelSubComponents/YieldCurve'
import FamaFrench from './ToolPanelSubComponents/FamaFrench'
import CustomIndices from './ToolPanelSubComponents/CustomIndices'
import { pathParts } from '../../../Models/DataModels/Common/RedirectionModel'

const ToolPanel = ({ userInfo, panelStates, signOut, checkIsTrial, accessibilityProps, stocksProps }: any) => {
    const CAPERatioProps: CAPERatioProps = {
        checkIsTrial,
        signOut,
        accessibilityProps
    }

    const renderPanel = (): JSX.Element => {
        const currentPanelLowerCased: string = panelStates.currentPanel.toLowerCase()
        switch (currentPanelLowerCased) {
            case pathParts.tools.famaFrench.toLowerCase():
                return <FamaFrench userInfo={userInfo} signOut={signOut} accessibilityProps={accessibilityProps} checkIsTrial={checkIsTrial}/>
            case pathParts.tools.customIndices.toLowerCase():
                return <CustomIndices userInfo={userInfo} signOut={signOut} accessibilityProps={accessibilityProps} checkIsTrial={checkIsTrial}/>
            case pathParts.tools.yieldCurve.toLowerCase():
                return <YieldCurve signOut={signOut}/>
            case pathParts.tools.currencyConverter.toLowerCase():
                return <CurrencyConverter signOut={signOut} />
            case pathParts.tools.capitalization.toLowerCase():
                return <Capitalization userInfo={userInfo} signOut={signOut} accessibilityProps={accessibilityProps} stocksProps={stocksProps}/>
            case pathParts.tools.CAPERatio.toLowerCase():
                return <CAPERatios {...CAPERatioProps}/>
            default:
                return <></>
        }
    }

    return (
        <div style={{ padding: '20px' }}>
            {renderPanel()}
        </div>
    )
}

export default ToolPanel
