import { getAuthTokenFromCookie } from './CookieAccessService'
import { NotOKResponse } from '../Models/DataModels/Responses/NotOKResponse'
import { EmptyStatusCode, getStatusCodeFromError, isNotOKResponseModel, NotOKResponseModel } from '../Models/DataModels/Common/NotOKResponseModel'
import { getSessionStorageOrDefault, getSessionStorageValueOrDefault, sessionStorageKeys } from './SessionStorageService'

export type HttpRequestType = 'GET' | 'PUT' | 'POST' | 'DELETE'
export const HttpRequest = {
    Get: 'GET' as HttpRequestType,
    Put: 'PUT' as HttpRequestType,
    Post: 'POST' as HttpRequestType,
    Delete: 'DELETE' as HttpRequestType
}

export interface APIRequest {
    relativePath: string,
    method: HttpRequestType,
    requestObject?: object
    isAnonymous?: boolean
}

export const getIP = async (callback?: () => void) => {
    fetch('https://api.ipify.org/?format=json')
        .then(response => response.json())
        .then(data => {
            if (data?.ip) {
                sessionStorage.setItem(sessionStorageKeys.customIPHeader, data.ip)
            }
            if (callback) {
                callback()
            }
        })
        .catch(error => console.error('Error fetching:', error))
}

export const url = process.env.REACT_APP_URL || 'https://localhost:44375'

export const getAPICallResult = async (request: APIRequest, isBackgroundAPICall?: boolean, returnRawResponse?: boolean, authToken?: any, signalKey?: string) => {
    if (!request) {
        return Promise.reject('Request required')
    }

    let requestHeader: Headers
    if (request.isAnonymous && request.isAnonymous === true) {
        requestHeader = new Headers({
            'Content-Type': 'application/json'
        })
    } else {
        const auth: any = authToken || getAuthTokenFromCookie()
        requestHeader = new Headers({
            'Authorization': 'Bearer ' + auth,
            'Content-Type': 'application/json'
        })
    }

    // const customIPHeaderValue: string = getSessionStorageValueOrDefault(sessionStorageKeys.customIPHeader, '')
    // requestHeader.append(sessionStorageKeys.customIPHeader, customIPHeaderValue)

    const body = request.requestObject ? { body: JSON.stringify(request.requestObject) } : {}
    const path = request.relativePath.startsWith('/') ? request.relativePath : '/' + request.relativePath

    const result = await fetch(url + path, {
        method: request.method,
        headers: requestHeader,
        ...body,
        ...(signalKey && { signal: getAbortSignal(signalKey) })
    })
        .then(async response => {

            if (returnRawResponse ?? false) {
                return response
            }

            const textResponse = await response.text()
            let jsonResponse = undefined

            try {
                jsonResponse = JSON.parse(textResponse)
            } catch (exception) {
                console.log('Not a JSON response!')
            }

            // check for error response
            if (!response.ok) {
                const returnError: NotOKResponseModel = {
                    isBackgroundCall: isBackgroundAPICall || false,
                    statusCode: response.status
                }
                const notOKJSON = jsonResponse as NotOKResponse
                if (notOKJSON) {
                    returnError.notOKResponse = notOKJSON
                } else {
                    // get error message from body or default to response statusText
                    const error = (jsonResponse && jsonResponse.message) || response.statusText || textResponse
                    const errorMessage = 'Status Code: ' + response.status + ' Error: ' + error
                    console.log(errorMessage)
                    returnError.anyOtherError = error
                }

                return Promise.reject(returnError)
            }
            return jsonResponse || textResponse
        },
            (error: any) => {
                if (isNotOKResponseModel(error)) return Promise.reject(error)

                const returnError: NotOKResponseModel = {
                    isBackgroundCall: isBackgroundAPICall || false,
                    statusCode: getStatusCodeFromError(error),
                    anyOtherError: error
                }

                return Promise.reject(returnError)
            })
        .catch(error => {
            if (isNotOKResponseModel(error)) return Promise.reject(error)

            const returnError: NotOKResponseModel = {
                isBackgroundCall: isBackgroundAPICall || false,
                statusCode: getStatusCodeFromError(error),
                anyOtherError: error
            }
            return Promise.reject(returnError)
        })

    return result
}

export default getAPICallResult

export const abortKeys = {
    search: 'search' as string,
    capitalizationHistoricalChart: 'capitalizationHistoricalChart' as string,
    searchChartLoading: 'searchChartLoading' as string
}

export const abortReasonKeys = {
    cancel: 'CANCELLED' as string
}

const abortControllersTable = new Map<string, AbortController>()

export const abortRequest = (key: string, reason: string = abortReasonKeys.cancel) => {
    abortControllersTable.get(key)?.abort(reason)
}

const getAbortSignal = (key: string): AbortSignal => {
    abortRequest(key)
    const newAbortController: AbortController = new AbortController()
    abortControllersTable.set(key, newAbortController)
    return newAbortController.signal
}
