import { Avatar, Chip, FormControlLabel, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, Typography } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { ChangeEvent, ReactNode, useEffect, useState } from 'react'
import { CSSProperties } from '@mui/material/styles/createTypography'
import { ScreenStatisticsRequest } from '../../../../../Models/DataModels/Requests/ScreeningRequests'
import { getCustomIndex, getCustomIndexMembers, saveCustomIndex } from '../../../../../Services/CustomIndexService'
import DataFrequencyValue, { DataFrequencyValueType } from '../../../../../Models/DataModels/Common/DataFrequencyModel'
import CompositeWeightValue, { CompositeWeightValueType } from '../../../../../Models/DataModels/Common/CompositeWeightModel'
import { CustomIndexMembersRequest, CustomIndexRequest, CustomIndexSaveRequest } from '../../../../../Models/DataModels/Requests/ToolsRequests'
import AlertModal, { AlertButtonType, AlertModalProps } from '../../../../Common/Modals/AlertModal'
import { CustomIndex, CustomIndexMember, CustomIndexMembersResponse } from '../../../../../Models/DataModels/Responses/ToolsResponses'
import { useNavigate } from 'react-router-dom'
import { paths } from '../../../../../Models/DataModels/Common/RedirectionModel'
import CancelIcon from '@mui/icons-material/Cancel'
import { ComponentMessageHandler, ComponentMessageHandlerProps } from '../../../../Common/Utility/ComponentMessageHandler'
import { NotOKResponseModel } from '../../../../../Models/DataModels/Common/NotOKResponseModel'
import { MessageResponse, MessageResponseTypes, MessageResponseValueType } from '../../../../../Models/DataModels/Responses/NotOKResponse'
import { SortFieldType, SortFields, SortOrderString, SortOrderType } from '../../../../../Models/DataModels/Common/SortModel'
import { SortProps } from '../../../../Search/SubComponents/SearchResults'
import { PaginationRequest } from '../../../../../Models/DataModels/Requests/SearchRequests'
import { LogoutReasonType } from '../../../../../Models/DataModels/Requests/AuthRequests'
import GFDTablePagination, { GFDTablePaginationProps } from '../../../../Common/Utility/GFDTablePagination'
import { CircularProgress } from '@mui/material'
import DefaultMaterialTheme from '../../../../Common/GlobalSettings/DefaultMaterialTheme'
import MaterialTable from '@material-table/core'
import MaterialTableOptions from '../../../../Common/GlobalSettings/MaterialTableOptions'
import MemberSearch, { MemberSearchProps } from './MemberSearch'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import RefreshMembersList from '../../../../Icons/RefreshMembersIcon'
import DeleteSelected from '../../../../Icons/DeleteSelectedIcon'
import DeleteSelectedOutline from '../../../../Icons/DeleteSelectedOutlineIcon'
import SaveIndexL from '../../../../Icons/SaveIndexL'
import FamaFrenchIcon from '../../../../Icons/FamaFrenchIcon'
import moment from 'moment'
import { AppConfigurations } from '../../../../../Models/DataModels/Common/AppConfigurationsModel'
import AddSeries from '../../../../Icons/AddSeriesIcon'
import { CustomBtnCloseCancel, CustomBtnBlue, CheckboxBlue, CustomChip } from '../../../../Common/GlobalSettings/CustomStyles'
import EnhancedTableHead, { ColumnData } from '../../../../Common/Utility/EnhancedTableHead'
import AddSelectedSeries from '../../../../Icons/AddSelectedSeriesIcon'
import { Info } from '@mui/icons-material'

export const nameRegEx = new RegExp('^.[a-zA-Z0-9]*$')
export const descriptionRegEx = new RegExp('^[a-zA-Z0-9- _().&%?,:+]*$')

export enum CustomIndexEditorMode {
    Create,
    Edit
}

export enum CustomIndexType {
    FamaFrench,
    StandAlone
}

export const renderDataFrequencyMenuItems = () => {
    return Object.values(DataFrequencyValue).map(
        value => <MenuItem key={value} value={value}>{value}</MenuItem>
    )
}

export const renderCompositeWeightMenuItems = () => {
    return Object.values(CompositeWeightValue).map(
        value => <MenuItem key={value} value={value}>{value}</MenuItem>
    )
}

export interface CustomIndexEditorProps {
    screenParameters?: ScreenStatisticsRequest,
    screenReportID?: number,

    customIndex?: CustomIndex, // pass custom index if details are loaded in calling component
    setCustomIndex?: (customIndex: CustomIndex) => void, // to pass back newly created index

    customIndexID?: number | null, // pass index for details to be loaded in this component
    setCustomIndexID?: (customIndexID: number) => void, // to pass back newly created index ID

    callback?: (isCancel: boolean) => void,
    signOut: (logoutReason: LogoutReasonType) => void,
    membersToBeAdded?: Map<string, CustomIndexMember>
}

const CustomIndexEditor = ({
    screenParameters,
    customIndex,
    customIndexID,
    setCustomIndexID,
    screenReportID,
    callback,
    signOut,
    membersToBeAdded
}: CustomIndexEditorProps) => {
    const navigate = useNavigate()

    const [symbol, setSymbol] = useState<string>(customIndex?.symbol || '.')
    const [description, setDescription] = useState<string>(customIndex?.description || '')

    const [members, setMembers] = useState<CustomIndexMember[]>([])

    const [startDate, setStartDate] = useState<moment.Moment | null>(!customIndex && !customIndexID ? moment().subtract(1, "year") : customIndex?.requestedStartDate ? moment(customIndex?.requestedStartDate) : null)
    const [startDateError, setStartDateError] = useState<string | null>(null)
    const [periodicity, setPeriodicity] = useState<DataFrequencyValueType>(customIndex?.periodicity as DataFrequencyValueType || DataFrequencyValue.Daily)
    const [weight, setWeight] = useState<CompositeWeightValueType>(customIndex?.weight as CompositeWeightValueType || CompositeWeightValue.MarketCap)
    const [totalReturn, setTotalReturn] = useState<boolean>(customIndex?.isTotalReturn || false)

    const [showModal, setShowModal] = useState<boolean>(false)

    const [resetSearchTermToggle, setResetSearchTermToggle] = useState<boolean>(false)

    const [fetchCustomIndexRequestInProgress, setFetchCustomIndexRequestInProgress] = useState<boolean>(false)
    const [fetchMembersRequestInProgress, setFetchMembersRequestInProgress] = useState<boolean>(false)
    const [saveIndexRequestInProgress, setSaveIndexRequestInProgress] = useState<boolean>(false)

    const [messages, setMessages] = useState<MessageResponse[]>([])
    const [response, setResponse] = useState<NotOKResponseModel | null>()

    const [pageNumber, setPageNumber] = useState<number>(1)
    const [perPageCount, setPerPageCount] = useState<number>(10)
    const [totalCount, setTotalCount] = useState<number>(0)

    const [sortData, setSortData] = useState<SortProps>({ sortField: SortFieldType.None, sortOrder: SortOrderType.None })

    const [addMembers, setAddMembers] = useState<Map<string, CustomIndexMember>>(membersToBeAdded || new Map<string, CustomIndexMember>())
    const [deleteMembers, setDeleteMembers] = useState<Map<string, CustomIndexMember>>(new Map<string, CustomIndexMember>())

    const [refreshScreen, setRefreshScreen] = useState<boolean>(false)

    const [disableSubmit, setDisableSubmit] = useState<boolean>(false)

    const [showSyncConfirmationModal, setShowSyncConfirmationModal] = useState<boolean>(false)
    
    const hasCustomIndexID = customIndexID || customIndex?.tickerID

    const saveConfirmAlertModalProps: AlertModalProps = {
        showModal: showSyncConfirmationModal,
        setShowModal: setShowSyncConfirmationModal,
        AlertTitle: 'Are you sure?',
        AlertContent: hasCustomIndexID ? 
            `Are you sure you want to save changes to Custom Index ${symbol}? This will result in the Custom Index data being regenerated. Do you want to proceed?`
            : `Are you sure you want to create Custom Index ${symbol}? This will result in the Custom Index data being generated. Do you want to proceed?`,
        AlertButtons: [
          {
            type: AlertButtonType.Cancel,
            display: 'Cancel',
            onClick: () => {
              setShowSyncConfirmationModal(false)
            },
            isPrimary: false,
          },
          {
            type: AlertButtonType.OK,
            display: 'Yes',
            onClick: () => {
              setShowSyncConfirmationModal(false)
              processSaveCustomIndex()
            },
            isPrimary: true,
          },
        ],
        onAlertClose: () => {
          return true
        }
      }

    const clearAlertsInCurrentWindow = () => {
        setMessages([])
        setResponse(null)
    }

    const clearAlert = () => {
        clearAlertsInCurrentWindow()
        // clearMessages()
    }

    //useEffect(() => {
    //    clearAlertsInCurrentWindow()
    //}, [clearMessagesToggle])

    const displayMessage = (message: string, type: MessageResponseValueType) => {
        const componentMessage: MessageResponse = {
            message: message as string,
            type
        }
        setResponse(null)
        setMessages([componentMessage])
        return
    }

    const displayResponse = (model: NotOKResponseModel) => {
        if (model) {
            setMessages([])
            setResponse(model as NotOKResponseModel)
            return
        }
    }

    const getPaginationRequest = (resetPageNumber: boolean, requestTotalCount: boolean): PaginationRequest => {
        let page = pageNumber
        if (!page || page <= 0) {
            page = 1
        }

        let perPage = perPageCount
        if (!perPage) {
            perPage = 100
        }

        return {
            pageNumber: resetPageNumber ? 1 : page,
            perPageCount: perPage,
            isTotalCountRequested: requestTotalCount
        } as PaginationRequest
    }

    const getCustomIndexMembersRequest = (resetPageNumber: boolean, requestTotalCount: boolean): CustomIndexMembersRequest => {
        return {
            customIndexID: customIndexID || customIndex?.tickerID || 0,
            pagination: getPaginationRequest(resetPageNumber, requestTotalCount),
            sortField: sortData.sortField,
            sortOrder: sortData.sortOrder
        }
    }

    const processCustomIndexMembersResponse = (result: CustomIndexMembersResponse) => {
        if (result?.pagination) {
            if (pageNumber !== result.pagination.pageNumber) setPageNumber(result.pagination.pageNumber)
            if (perPageCount !== result.pagination.perPageCount) setPerPageCount(result.pagination.perPageCount)

            if (result.pagination.totalCount || result.pagination.totalCount === 0) {
                setTotalCount(result.pagination.totalCount)
            }
        }

        if (sortData.sortOrder !== (result?.sortOrder || SortOrderType.None)) result.sortOrder = result?.sortOrder || SortOrderType.None
        if (sortData.sortField !== (result?.sortField || SortFieldType.None)) result.sortField = result?.sortField || SortFieldType.None

        const entries: CustomIndexMember[] = result.memberDetails || []
        setMembers(entries)
        setFetchMembersRequestInProgress(false)
    }

    const refreshCustomIndexMembers = (resetPageNumber: boolean, requestTotalCount: boolean) => {
        if (!hasCustomIndexID) {
            return
        }

        if (fetchMembersRequestInProgress) {
            return
        }

        setFetchMembersRequestInProgress(true)
        getCustomIndexMembers(getCustomIndexMembersRequest(resetPageNumber, requestTotalCount))
            .then((result: CustomIndexMembersResponse) => {
                processCustomIndexMembersResponse(result)
            },
                //Reject promise
                (notOKResponseModel: NotOKResponseModel) => {
                    displayResponse(notOKResponseModel)
                    setFetchMembersRequestInProgress(false)
                })
    }

    const fetchCustomIndex = () => {
        if (fetchCustomIndexRequestInProgress) return

        if (hasCustomIndexID) {
            const request: CustomIndexRequest = {
                customIndexID: customIndexID || customIndex?.tickerID || 0 // can never be zero since hasCustomIndexID is true
            }
            setFetchCustomIndexRequestInProgress(true)
            getCustomIndex(request)
                .then((response: any) => {
                    setFetchCustomIndexRequestInProgress(false)

                    const index = response?.customIndex as CustomIndex
                    if (index) {
                        setSymbol(index.symbol || '')
                        setDescription(index.description || '')
                        setStartDate(index?.requestedStartDate ? moment(index?.requestedStartDate) : null)
                        setPeriodicity(index?.periodicity as DataFrequencyValueType || DataFrequencyValue.Daily)
                        setWeight(index?.weight as CompositeWeightValueType || CompositeWeightValue.MarketCap)
                        setTotalReturn(index?.isTotalReturn || false)
                        refreshCustomIndexMembers(true, true)
                    } else {
                        displayMessage('Failed to fetch custom index', MessageResponseTypes.Error)
                    }
                },
                    //Reject promise
                    (notOKResponseModel: NotOKResponseModel) => {
                        displayResponse(notOKResponseModel)
                        setFetchCustomIndexRequestInProgress(false)
                    })
        }
    }

    useEffect(() => {
        refreshCustomIndexMembers(false, false)
    }, [pageNumber, sortData])

    useEffect(() => {
        if (pageNumber !== 1) {
            setPageNumber(1)
            return
        }
        refreshCustomIndexMembers(true, false)
    }, [perPageCount])

    useEffect(() => {
        fetchCustomIndex()
    }, [])

    useEffect(() => {
        fetchCustomIndex()
    }, [customIndexID])

    const redirect = (isCancel: boolean) => {
        if (callback) {
            callback(isCancel)
        } else {
            navigate(paths.financialTools.customIndices)
        }
    }

    const alertModalProps: AlertModalProps = {
        showModal,
        setShowModal,
        AlertTitle: 'Custom Index Saved',
        AlertContent: `Custom Index ${symbol} saved successfully!`,
        AlertButtons: [
            {
                type: AlertButtonType.OK,
                display: 'OK',
                onClick: () => {
                    setShowModal(false)
                    redirect(false)
                },
                isPrimary: true,
            },
        ],
        onAlertClose: () => {
            return true
        },
        showCloseButton: false
    }

    const validate = (): boolean => {
        if (!symbol?.trim().length || symbol?.trim() === '.') {
            displayMessage('Symbol is required', MessageResponseTypes.Error)
            return false
        }
        if (!screenReportID && !members?.length && !addMembers?.size) {
            displayMessage('At least one member is required', MessageResponseTypes.Error)
            return false
        }
        return true
    }

    const validateAndConfirmSaveCustomIndex = () => {
        clearAlert()
        if (!validate()) return
        setShowSyncConfirmationModal(true)
    }

    const processSaveCustomIndex = () => {
        if (saveIndexRequestInProgress) return

        const added: string[] = []
        addMembers?.forEach((value: CustomIndexMember, key: string) => added.push(key))

        const deleted: number[] = []
        deleteMembers?.forEach((value: CustomIndexMember, key: string) => deleted.push(value.tickerID))

        const request: CustomIndexSaveRequest = {
            ...hasCustomIndexID ? { customIndexID: customIndex?.tickerID || customIndexID || 0 } : {},
            ...screenReportID ? { screenReportID: screenReportID } : {},
            ...screenParameters ? { screeningParameters: screenParameters } : {},
            symbol,
            description,
            weight,
            periodicity,
            isTotalReturn: totalReturn,
            startDate: startDate === null ? null : new Date(startDate.format('MM/DD/YYYY')),
            generateIndex: true,
            generateNow: true,
            addMembers: added,
            removeMembers: deleted
        }

        setSaveIndexRequestInProgress(true)
        saveCustomIndex(request).then((response: any) => {
            console.log('Response: ', response)
            if (setCustomIndexID) setCustomIndexID(response.customIndexID)
            setShowModal(true)
            setSaveIndexRequestInProgress(false)
        },
            (notOKResponseModel: NotOKResponseModel) => {
                displayResponse(notOKResponseModel)
                setSaveIndexRequestInProgress(false)
            })
    }

    const handleChangeSymbol = (event: React.ChangeEvent<HTMLInputElement>) => {
        clearAlert()
        let name = event.target.value?.trim()?.toUpperCase()
        if (!name?.startsWith('.')) name = '.' + name || ''
        if (nameRegEx.test(name)) {
            setSymbol(name)
        }
    }

    const handleChangeDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
        clearAlert()
        let value = event.target.value?.trim()
        if (descriptionRegEx.test(value)) {
            setDescription(value)
        }
    }

    const handleChangePeriodicity = (
        event: SelectChangeEvent<DataFrequencyValueType>,
        child: ReactNode,
    ) => {
        clearAlert()
        setPeriodicity(event.target.value as DataFrequencyValueType)
    }

    const handleChangeWeight = (
        event: SelectChangeEvent<CompositeWeightValueType>,
        child: ReactNode,
    ) => {
        clearAlert()
        setWeight(event.target.value as CompositeWeightValueType)
    }

    const invalidDateErrorString = 'Invalid date'

    const runStartDateChecks = () => {
        if (!startDate) {
            setStartDateError(null)
        } else if (!startDate.isValid()) {
            setStartDateError(invalidDateErrorString)
        } else {
            setStartDateError(null)
        }
    }

    const handleChangeStartDate = (
        value: moment.Moment | null
    ) => {
        clearAlert()
        if (value?.isValid()) {
            setStartDate(value)
        } else {
            setStartDate(null)
        }
    }

    useEffect(() => {
        if (saveIndexRequestInProgress || !setDisableSubmit) return
        const disable: boolean = Boolean(startDateError)
        setDisableSubmit(disable)
    }, [startDateError])

    useEffect(() => {
        runStartDateChecks()
    }, [startDate])

    const renderTitle = () => {
        const title = 'Members'

        return (<>
            {title}
            <IconButton title='Refresh in Progress'
                aria-label='Refresh in Progress' component='label' sx={{
                    p: '10px',
                    color: '#1976d2',
                    visibility: fetchMembersRequestInProgress ? 'visible' : 'hidden'
                }}>
                <CircularProgress title='Refresh in Progress' aria-label='Refresh in Progress'/>
            </IconButton>
            <IconButton sx={{
                visibility: fetchMembersRequestInProgress ? 'hidden' : 'visible',
                p: '10px'
            }}
                title='Refresh Members List' aria-label='Refresh Members List' component='label'
                onClick={() => {
                    clearAlert()
                    refreshCustomIndexMembers(false, true)
                }}>
                <RefreshMembersList></RefreshMembersList>
            </IconButton>
            {isFamaFrenchIndex ? <></> : renderDeleteAll()}
            {isFamaFrenchIndex ? <></> : renderUndoDeletions()}
            {isFamaFrenchIndex ? <></> : 
                <Typography style={{ fontWeight: 'bold', marginLeft: '8px', display: 'inline-block' }}>
                    <Chip style={{ fontWeight: 'bold' }}
                        avatar={<Info />}
                        label='Members will be marked for deletion. The delete operation will be committed only on Save.'
                        variant='outlined'
                    />
                </Typography>
            }
        </>)
    }

    const getDefaultSort = (column: string) => {
        if (['tickerName', 'description'].includes(column) &&
          sortData.sortField === SortFields[column]) {
            return SortOrderString[sortData.sortOrder]
        }
        return undefined
    }

    const onDeleteAll = () => {
        members?.forEach((member: CustomIndexMember) => {
            if (!deleteMembers.has(member.tickerName)) {
                deleteMembers.set(member.tickerName, member)
            }
        })
        setRefreshScreen(!refreshScreen)
    }

    const onUndoDeletions = () => {
        deleteMembers.clear()
        setRefreshScreen(!refreshScreen)
    }

    const onUndoAdditions = () => {
        addMembers.clear()
        setRefreshScreen(!refreshScreen)
    }

    const onDeleteUndelete = (checked: boolean, rowData: CustomIndexMember) => {
        if (!rowData) return

        if (checked) {
            deleteMember(rowData.tickerName, rowData)
        } else {
            undeleteMember(rowData.tickerName)
        }
    }

    const deleteMember = (tickerName: string, ticker: CustomIndexMember) => {
        if (!deleteMembers.has(tickerName)) {
            deleteMembers.set(tickerName, ticker)
            setRefreshScreen(!refreshScreen)
        }
    }

    const undeleteMember = (tickerName: string) => {
        if (deleteMembers.has(tickerName)) {
            deleteMembers.delete(tickerName)
            setRefreshScreen(!refreshScreen)
        }
    }

    const addMember = (tickerName: string, ticker: CustomIndexMember): boolean => {
        if (!addMembers.has(tickerName)) {
            if ((addMembers.size + totalCount - deleteMembers.size) > (AppConfigurations.maximumCustomIndexMembers || 100)) {
                displayMessage(`Stopped at [${tickerName}]. Cannot add anymore as it would result in the Custom Index having more than ${AppConfigurations.maximumCustomIndexMembers || 100} members`, MessageResponseTypes.Warning)
                return false
            }
            addMembers.set(tickerName, ticker)
            setRefreshScreen(!refreshScreen)
            return true
        }
        return false
    }

    const unaddMember = (tickerName: string) => {
        if (addMembers.has(tickerName)) {
            addMembers.delete(tickerName)
            setRefreshScreen(!refreshScreen)
        }
    }

    const renderColumns = (): any[] => {
        const deleteColumn = isFamaFrenchIndex ? [] : [
            {
                title: 'Delete', field: 'button', sorting: false,
                render: (rowData: any) =>
                    rowData && (
                        <CheckboxBlue
                            checked={deleteMembers.has(rowData.tickerName)}
                            icon={<DeleteSelectedOutline color={'primary'} />}
                            checkedIcon={<DeleteSelected color={'error'} />}
                            id='deleteMemberCheckBox'
                            onChange={(event: ChangeEvent<HTMLInputElement>, checked: boolean) => onDeleteUndelete(checked, rowData)}
                            inputProps={{
                                'title': 'Delete/Undo Delete',
                                'aria-label': 'Delete/Undo Delete'
                            }}
                        />
                    )
            }
        ]

        return [
            ...deleteColumn,
            { title: 'Symbol', field: 'tickerName', sorting: true, width: '20%' },
            { title: 'Description', field: 'description', sorting: true },
            { title: '_id', field: 'tickerID', type: 'numeric', hidden: true }
        ]
    }

    const componentMessageHandlerProps: ComponentMessageHandlerProps = {
        messages,
        setMessages,
        response,
        signOut
    }

    const onSort = (columnNumber: number) => {
        clearAlert()

        const props: SortProps = {
            sortField: sortData.sortField,
            sortOrder: SortOrderType.Ascending
        }

        const baseColumn: number = isFamaFrenchIndex ? 0 : 1 // account for delete column 

        try {
            switch (columnNumber) {
                case baseColumn + 0:
                    props.sortField = SortFieldType.Symbol
                    break
                case baseColumn + 1:
                    props.sortField = SortFieldType.Description
                    break
                default:
                    props.sortField = SortFieldType.None
                    break
            }

            if (props.sortField === sortData.sortField) {
                if (sortData.sortOrder === SortOrderType.Ascending) {
                    props.sortOrder = SortOrderType.Descending
                } else if (sortData.sortOrder === SortOrderType.Descending) {
                    props.sortField = SortFieldType.None
                    props.sortOrder = SortOrderType.None
                }
            }

            setSortData(props)
        }
        catch (e) {
            console.log('Exception while sorting: ', e)
        }
    }

    const pageData: GFDTablePaginationProps = {
        perPageCount: perPageCount,
        pageNumber: pageNumber,
        totalCount: totalCount,
        setPageNumber: setPageNumber,
        setPerPageCount: setPerPageCount
    }

    const addMemberToCustomIndex = (entry: string) => {
        const tickers = entry.indexOf('~') > 0 ? [entry] : entry.split(',') 

        tickers.forEach(tickerName => {
            let description = tickerName

            let index = tickerName?.indexOf('~')
            if (index && index >= 1) {
                tickerName = tickerName.substring(0, index - 1).trim()

                description = description.substring(index + 1)
                index = description?.indexOf('~')
                if (index && index >= 1) {
                    description = description.substring(0, index - 1)
                }
            }

            tickerName = tickerName?.trim()?.toUpperCase()
            if (!tickerName) return

            const ticker: CustomIndexMember = {
                tickerID: 0,
                tickerName,
                description
            }
            if (!addMember(tickerName, ticker)) return
        })

        setResetSearchTermToggle(!resetSearchTermToggle)
    }

    const searchProps: MemberSearchProps = {
        addMemberToCustomIndex,
        resetSearchTermToggle
    }

    const renderDeletedMember = (tickerName: string, ticker: CustomIndexMember) => {
        return <CustomChip label={tickerName}
            title={ticker.description}
            style={{ margin: '4px' }}
            icon={<DeleteOutlineIcon />}
            deleteIcon={
                <CancelIcon
                    onMouseDown={(event: any) => event.stopPropagation()}
                />
            }
            onDelete={(event: any) => undeleteMember(tickerName)}
        />
    }

    const renderAddedMember = (tickerName: string, ticker: CustomIndexMember) => {
        return <CustomChip label={tickerName}
            title={ticker.description}
            style={{ margin: '4px' }}
            icon={<AddSeries />}
            deleteIcon={
                <CancelIcon
                    onMouseDown={(event: any) => event.stopPropagation()}
                />
            }
            onDelete={(event: any) => unaddMember(tickerName)}
        />
    }

    const renderDeleteAll = () => {
        return <IconButton title='Delete All'
            aria-label='Delete All' component='label' sx={{
                p: '10px',
                color: '#1976d2'
            }}
            onClick={onDeleteAll}
        >
            <DeleteSelectedOutline />
        </IconButton>
    }

    const renderUndoDeletions = () => {
        return <IconButton title='Undo All Deletions'
            aria-label='Undo All Deletions' component='label' sx={{
                p: '10px',
                color: '#1976d2'
            }}
            onClick={onUndoDeletions}
        >
            <DeleteSelected color={'error'} />
        </IconButton>
    } 

    const renderDeletedMembers = () => {
        const chips: any[] = []

        deleteMembers.forEach((ticker: CustomIndexMember, tickerName: string) => chips.push(renderDeletedMember(tickerName, ticker)))

        return chips
    }

    const renderAddedMembers = () => {
        const chips: any[] = []

        addMembers.forEach((ticker: CustomIndexMember, tickerName: string) => chips.push(renderAddedMember(tickerName, ticker)))

        return chips
    }

    const isFamaFrenchIndex: boolean = Boolean(screenReportID || customIndex?.screenReportID)

    const renderMembers = () => {
        return <>
            {isFamaFrenchIndex ?
                <></>
                :
                <div style={styles.rowContainer as CSSProperties}>
                    <div style={styles.columnItem as CSSProperties}>
                        <div style={{ marginTop: '16px' }}>
                            <Typography style={{ fontWeight: 'bold', marginBottom: '8px' }}>
                                Add Members <Chip
                                    avatar={<Info />}
                                    label='Members will be marked for addition. The add operation will be committed only on Save.'
                                    variant='outlined'
                                />
                            </Typography>
                            <MemberSearch {...searchProps} />
                        </div>
                    </div>
                </div>
            }
            {addMembers.size ?
                <div style={styles.rowContainer as CSSProperties}>
                    <div style={styles.columnItem as CSSProperties}>
                        <div style={{ marginTop: '8px' }}>
                            <Typography style={{ fontWeight: 'bold', marginBottom: '8px' }}>
                                Members marked for Addition
                                <Chip
                                    style={{marginLeft: '16px'}}
                                    avatar={<Avatar><AddSelectedSeries/></Avatar>}
                                    label='Undo All Additions'
                                    variant='outlined'
                                    onClick={onUndoAdditions}
                                /> 
                            </Typography>
                            {renderAddedMembers()}
                        </div>
                    </div>
                </div>
                : <></>}
            {hasCustomIndexID ? <div style={styles.rowContainer as CSSProperties}>
            <div style={styles.columnItem as CSSProperties}>
                    <ThemeProvider theme={DefaultMaterialTheme}>
                        <MaterialTable
                            columns={renderColumns()}
                            data={members}
                            title={renderTitle()}
                            options={{
                                ...MaterialTableOptions,
                                paginationPosition: 'top',
                                maxBodyHeight: '50vh',
                                selection: false
                            }}
                            components={{
                                Pagination: props => (<GFDTablePagination {...pageData} />),
                                Header:
                                    props => (
                                        <EnhancedTableHead { ...{ sortData: { getDefaultSort, onSort }, columns: renderColumns().map(column => column as ColumnData) } }/>
                                    )
                            }}
                        />
                    </ThemeProvider>
                </div>
            </div> : <></>}

            {deleteMembers.size ?
                <div style={styles.rowContainer as CSSProperties}>
                    <div style={styles.columnItem as CSSProperties}>
                        <div style={{ marginTop: '8px' }}>
                            <Typography style={{ fontWeight: 'bold', marginBottom: '8px' }}>
                                Members marked for Deletion
                                <Chip
                                    style={{marginLeft: '16px'}}
                                    avatar={<Avatar><DeleteSelected color={'error'} /></Avatar>}
                                    label='Undo All Deletions'
                                    variant='outlined'
                                    onClick={onUndoDeletions}
                                /> 
                            </Typography>
                            {renderDeletedMembers()}
                        </div>
                    </div>
                </div>
                : <></>}

        </>
    }

    const renderFetchCustomIndexInProgress = () => {
        return fetchCustomIndexRequestInProgress && <IconButton title='Refresh in Progress'
            aria-label='Refresh in Progress' component='label' sx={{
                p: '10px',
                color: '#1976d2',
                visibility: fetchMembersRequestInProgress ? 'visible' : 'hidden'
            }}>
            <CircularProgress title='Refresh in Progress' aria-label='Refresh in Progress'/>
        </IconButton>
    }

    const renderHeader = () => {
        return <>
            {hasCustomIndexID ? isFamaFrenchIndex ?
                <>
                    <Typography variant='h6' style={{ fontWeight: 'bold' }}>
                        Edit Fama French Custom Index <IconButton title={'Fama French Custom Index'} style={{ backgroundColor: '#e7c3fa', width: '32px', height: '32px', marginLeft: '2px', marginRight: '2px' }}>
                            <FamaFrenchIcon />
                        </IconButton>
                        {renderFetchCustomIndexInProgress()}
                    </Typography>
                    <div style={{ marginTop: '8px' }}>
                        Only the properties can be edited for Fama French Custom Index. The Screen Report associated with this can be modified and the change in results can be synchronized with the Custom Index by clicking on the sync icon in the Fama French tool.
                    </div>
                </>
                :
                <Typography variant='h6' style={{ fontWeight: 'bold' }}>
                    Edit Custom Index {renderFetchCustomIndexInProgress()}
                </Typography>
                :
                <>
                    <Typography variant='h6' style={{ fontWeight: 'bold' }}>
                        Custom Index {renderFetchCustomIndexInProgress()}
                    </Typography>

                    {isFamaFrenchIndex ?
                        <div style={{ marginTop: '8px' }}>
                            The resulting equities that passed the Screen can be used to create a Custom Index. Enter the properties of the Custom Index below. Once saved, the compositized data for the Custom Index will be generated based on the properties below. If the Screen Report is modified and the results change, the Custom Index can be synchronized by clicking on the sync icon in the Fama French tool.
                        </div>
                        :
                        <div style={{ marginTop: '8px' }}>
                            Members added here can be used to create a Custom Index. Enter the properties of the Custom Index below. Once saved, the compositized data for the Custom Index will be generated based on the properties below. The Index can be edited or re-generated at any time by choosing Edit from the Custom Indices table.
                        </div>
                    }
                </>}
            <div style={{ marginTop: '8px' }}>
                Please note, Index takes some time to generate. You can check on the status in Custom Indices tool.
            </div>
        </>
    }

    const readOnlyProp: CSSProperties = ((saveIndexRequestInProgress || fetchMembersRequestInProgress || fetchCustomIndexRequestInProgress) ?? false) ? { pointerEvents: 'none' } : {}

    const renderButtons = (isTop: boolean) => {
        const postfix: string = isTop ? 'Top' : 'Bottom'
        const justifyContent = { justifyContent: 'right' }
        return <div style={{ ...styles.rowContainer, ...justifyContent } as CSSProperties}>

            <CustomBtnCloseCancel
                id={`cancelButton${postfix}`} 
                title={saveIndexRequestInProgress ? 'Close' : 'Cancel'}
                style={{ marginLeft: 8 }}
                variant='outlined'
                onClick={() => redirect(true)}
            >
                Cancel
            </CustomBtnCloseCancel>

            {saveIndexRequestInProgress ?
                <IconButton title='Save Index in Progress'
                    aria-label='Save Index in Progress' component='label' sx={{
                        p: '10px',
                    }}
                >
                    <CircularProgress title='Save Index in Progress' aria-label='Save Index in Progress'/>
                </IconButton>
                :
                <CustomBtnBlue
                    id={`saveIndexButton${postfix}`}
                    title='Save Custom Index'
                    style={{ marginLeft: 8, ...readOnlyProp }}
                    variant='contained'
                    onClick={validateAndConfirmSaveCustomIndex}
                    startIcon={<SaveIndexL />}
                    {...(saveIndexRequestInProgress || disableSubmit ? { disabled: true } : {})}
                >
                    Save Index
                </CustomBtnBlue>
            }

        </div>
    }

    return <>
        <AlertModal {...alertModalProps} />

        {renderHeader()}

        <div>
            <ComponentMessageHandler {...componentMessageHandlerProps} />
        </div>

        {renderButtons(true)}

        <div style={{ ...readOnlyProp }}>
            <div style={styles.rowContainer as CSSProperties}>
                <div style={styles.columnItem as CSSProperties}>
                    <TextField id='indexSymbol' label="Index Symbol (must start with '.')" variant='standard'
                        style={styles.innerItem as CSSProperties}
                        value={symbol}
                        onChange={handleChangeSymbol}
                    />
                </div>
                <div style={styles.columnItem as CSSProperties}>
                    <TextField id='indexTitle' label='Index Title' variant='standard'
                        style={styles.innerItem as CSSProperties}
                        value={description}
                        onChange={handleChangeDescription}
                    />
                </div>
                <div style={{ ...styles.columnItem as CSSProperties, alignSelf: 'flex-end' }}>
                    <FormControlLabel
                        control={<CheckboxBlue
                            checked={totalReturn}
                            onChange={(event, checked) => setTotalReturn(checked)}
                        />}
                        label='Total Return'
                        labelPlacement='end'
                    />
                </div>
            </div>

            <div style={styles.rowContainer as CSSProperties}>
                <div style={styles.columnItem as CSSProperties}>
                    <InputLabel id='indexStartDate'>Start Date</InputLabel>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                            key={'indexStartDate'}
                            value={startDate}
                            minDate={moment(AppConfigurations.minimumDate)}
                            maxDate={moment()}
                            onChange={handleChangeStartDate}
                            sx={{ ...styles.innerItem, fontWeight: 'bold' }}
                            slotProps={{
                                textField: {
                                    error: startDateError !== null,
                                    helperText: startDateError
                                },
                                field: { clearable: true, onClear: () => handleChangeStartDate(null) },
                            }}
                        />
                    </LocalizationProvider>
                </div>
                <div style={styles.columnItem as CSSProperties}>
                    <InputLabel id='indexPeriodicityLabel'>Periodicity</InputLabel>
                    <Select
                        style={styles.innerItem as CSSProperties}
                        labelId='indexPeriodicityLabel'
                        id='indexPeriodicity'
                        value={periodicity}
                        label='Periodicity'
                        onChange={handleChangePeriodicity}
                    >
                        {renderDataFrequencyMenuItems()}
                    </Select>
                </div>
                <div style={styles.columnItem as CSSProperties}>
                    <InputLabel id='indexWeightLabel'>Weight</InputLabel>
                    <Select
                        style={styles.innerItem as CSSProperties}
                        labelId='indexWeightLabel'
                        id='indexWeight'
                        value={weight}
                        label='Weight'
                        onChange={handleChangeWeight}
                    >
                        {renderCompositeWeightMenuItems()}
                    </Select>
                </div>
            </div>

            {renderMembers()}
        </div>

        {renderButtons(false)}
        {showSyncConfirmationModal && <AlertModal {...saveConfirmAlertModalProps} />}
    </>
}

const styles = {
    innerItem: {
        width: '80%',
        flexGrow: '0',
        flexBasis: 'auto',
        marginBottom: '4px',
    },
    columnContainer: {
        width: '100%',
        height: '100%',
        maxHeight: 'inherit',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
    },
    columnItem: {
        width: '100%',
        flexGrow: '0',
        flexBasis: 'auto',
        marginBottom: '4px',
    },
    columnFillItem: {
        width: '100%',
        flexBasis: '0',
        flexGrow: '1',
        display: 'flex',
        overflow: 'auto',
    },
    rowContainer: {
        width: '100%',
        // height: '100%',
        maxHeight: 'inherit',
        display: 'flex',
        flexDirection: 'row',
        overflow: 'auto',
        marginTop: '8px'
    },
    rowItem: {
        height: '100%',
        flexGrow: '0',
        flexBasis: 'auto',
        marginRight: '4px',
    },
    rowFillItem: {
        height: '100%',
        flexBasis: '0',
        flexGrow: '1',
        display: 'flex',
        overflow: 'auto',
    }
}

export default CustomIndexEditor
