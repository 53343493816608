import { Typography } from "@mui/material"

export const GFDUniverseDetailedDescription = (): JSX.Element => {
    return (
        <div style={{ textAlign: 'left' }}>
            <Typography variant='h6'>GFD Universe Quick Search:</Typography>
            <p>This option allows you to search all series from across the range of GFD data products.  This can include series that are not part of your current subscription.</p>
        </div>
    )
}

export const GFDatabase2Point0DetailedDescription = (): JSX.Element => {
    return (
        <div style={{ textAlign: 'left' }}>
            <Typography variant='h6'>GFDatabase 2.0:</Typography>
            <p>This option will narrow your search to series that are part of our global macro data. In addition to popular benchmark indexes, you can also find data concerning commodities, exchange rates, interest rates, government debt and macroeconomic activity.  Many of these series will contain extended histories (noted with a “GFD Extension” at the end of the series title) that can aid you in your research and data modeling. </p>
        </div>
    )
}

export const GFDIndicesDetailedDescription = (): JSX.Element => {
    return (
        <div style={{ textAlign: 'left' }}>
            <Typography variant='h6'>GFD Indices:</Typography>
            <p>This option will narrow your search to our custom indexes that provide a deep history for all major asset classes.  This includes comparative data such as market and sector level indexes, ratios and yields as well as premium between the major asset classes such as equities, bonds and treasuries.</p>
        </div>
    )
}


export const RealEstateDatabaseDetailedDescription = (): JSX.Element => {
    return (
        <div style={{ textAlign: 'left' }}>
            <Typography variant='h6'>Real Estate Database:</Typography>
            <p>Our Real Estate Database includes country-level and regional-level real estate price indexes as well as statistics pertaining to real estate activity and supply. </p>
        </div>
    )
}

export const USStocksDetailedDescription = (): JSX.Element => {
    return (
        <div style={{ textAlign: 'left' }}>
            <Typography variant='h6'>US Stocks:</Typography>
            <p>Use this option to access data on individual equities, with daily equity data going back to 1957 and monthly equity data going back to the 1790s. This includes a record of corporate actions like dividends and stock splits, as well as annual and quarterly fundamental data.</p>
        </div>
    )
}

export const UKStocksDetailedDescription = (): JSX.Element => {
    return (
        <div style={{ textAlign: 'left' }}>
            <Typography variant='h6'>UK Stocks:</Typography>
            <p>This option will allow you to access historical data for individual equities from around the globe. This includes primary coverage of individual equity and fixed income securities traded out of London from the mid-1600s to 1985. This includes a record of corporate actions and share capitalization when applicable. Additionally, we provide historical coverage of major Canadian, European and Asian stock exchanges.</p>
        </div>
    )
}

export const ConstiuentMembershipDetailedDescription = (): JSX.Element => {
    return (
        <div style={{ textAlign: 'left' }}>
            <Typography variant='h6'>Constituent Membership:</Typography>
            <p>Use this option to gain access to a historical record of companies that been included in a major stock index.</p>
        </div>
    )
}

export const EventsInTimeDetailedDescription = (): JSX.Element => {
    return (
        <div style={{ textAlign: 'left' }}>
            <Typography variant='h6'>Events-In-Time:</Typography>
            <p>Major historical events often shape the trajectory of history. GFD has compiled a searchable record of events throughout recorded history. You can use this option to search for specific events that may aid you in guiding the factors that may have contributed to asset performance over your selected period of investigation.</p>
        </div>
    )
}
