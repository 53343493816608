import MaterialTable from '@material-table/core'
import { IconButton } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import React, { useEffect, useRef, useState } from 'react'
import { CSSProperties } from '@mui/material/styles/createTypography'
import MaterialTableOptions from '../../../../Common/GlobalSettings/MaterialTableOptions'
import AlertModal, { AlertButtonType, AlertModalProps } from '../../../../Common/Modals/AlertModal'
import GFDTablePagination, { GFDTablePaginationProps } from '../../../../Common/Utility/GFDTablePagination'
import { CustomIndexFields, CustomIndexSortFieldType, CustomIndexSortFields, SortOrderString, SortOrderType } from '../../../../../Models/DataModels/Common/SortModel'
import { ComponentMessageHandler, ComponentMessageHandlerProps } from '../../../../Common/Utility/ComponentMessageHandler'
import { NotOKResponseModel } from '../../../../../Models/DataModels/Common/NotOKResponseModel'
import { MessageResponse, MessageResponseTypes, MessageResponseValueType } from '../../../../../Models/DataModels/Responses/NotOKResponse'
import { LogoutReasonType } from '../../../../../Models/DataModels/Requests/AuthRequests'
import { CustomIndex } from '../../../../../Models/DataModels/Responses/ToolsResponses'
import CustomIndexEditor, { CustomIndexEditorProps } from './CustomIndexEditor'
import { CircularProgress } from '@mui/material'
import { Tooltip } from '@mui/material'
import GraphwLines from '../../../../Icons/GraphwLines'
import DownLoad from '../../../../Icons/DownLoad'
import EditIndices from '../../../../Icons/EditIndicesIcon'
import RefreshMembersList from '../../../../Icons/RefreshMembersIcon'
import DeleteSelected from '../../../../Icons/DeleteSelectedIcon'
import { CustomIndexDeleteRequest, CustomIndicesRequest, FileDownloadRequest } from '../../../../../Models/DataModels/Requests/ToolsRequests'
import { deleteCustomIndex, exportCustomIndices } from '../../../../../Services/CustomIndexService'
import AddNewScreening from '../../../../Icons/AddNewScreeningIcon'
import ExporttoList from '../../../../Icons/ExporttoListIcon'
import { GFDToastSuccess } from '../../../../Common/Utility/GFDToastify'
import { downloadFileFromServer } from '../../../../../Services/DownloadService'
import { globalStyles } from '../../../../Common/GlobalSettings/GlobalStyles'
import { AppConfigurations } from '../../../../../Models/DataModels/Common/AppConfigurationsModel'
import DownloadAction, { DownloadActionProps } from '../../../../Common/Utility/DownloadAction'
import { TickerData } from '../../../../../Models/DataModels/Responses/AutoTracResponses'
import { CustomBtnAddEdit } from '../../../../Common/GlobalSettings/CustomStyles'
import DefaultMaterialTheme from '../../../../Common/GlobalSettings/DefaultMaterialTheme'
import EnhancedTableHead from '../../../../Common/Utility/EnhancedTableHead'
import FamaFrenchEditor, { FamaFrenchEditorProps } from './FamaFrenchEditor'
import { UserInfo } from '../../../../../Models/DataModels/Common/UserInfoModel'
import ScreenReportActions from './ScreenReportActions'
import GraphModal, { GraphModalProps } from '../../../../GraphAndChartResults/GraphSubcomponents/GraphModal'
import { DateFormatValueType } from '../../../../../Models/DataModels/Common/DateFormatModel'
import ExportOutputFormatSelectionModal from '../../../../Common/Modals/ExportOutputFormatSelectionModal'

export interface CustomIndexSortProps {
  sortField: CustomIndexSortFieldType,
  sortOrder: SortOrderType
}

export interface CustomIndicesListProps {
  refreshCustomIndicesList: () => void,
  refreshCustomIndicesRequestInProgress: boolean,
  customIndices: CustomIndex[],
  pageData: GFDTablePaginationProps,
  sortData: {
    sortProps: CustomIndexSortProps,
    setSortProps: (sortProps: CustomIndexSortProps) => void
  },
  userInfo: UserInfo | null,
  signOut: (logoutReason: LogoutReasonType) => void,
  clearMessagesToggle: boolean,
  clearMessages: () => void,
  canUseTool: boolean | null | undefined,
  checkIsTrial: () => boolean
}

interface DeleteRequestProps {
  request: CustomIndexDeleteRequest,
  names: string[]
}

const CustomIndicesList = ({
  refreshCustomIndicesList,
  refreshCustomIndicesRequestInProgress,
  customIndices,
  pageData,
  sortData,
  userInfo,
  signOut,
  clearMessagesToggle,
  clearMessages,
  canUseTool,
  checkIsTrial
}: CustomIndicesListProps) => {
  const [suspendRefreshCustomIndices, setSuspendRefreshCustomIndices] = useState<boolean>(false)

  const getDefaultSort = (column: string) => {
    if (CustomIndexFields.includes(column) &&
      sortData.sortProps.sortField === CustomIndexSortFields[column]) {
      return SortOrderString[sortData.sortProps.sortOrder]
    }
    return undefined
  }

  const getColumns = () => {
    const columns: any[] = [
      { title: 'Symbol', field: 'symbol', sorting: true },
      {
        title: 'Fama French', field: 'screenReportID', sorting: true, width: '6%',
        render: (rowData: any) => <ScreenReportActions
          customIndex={rowData}
          setSuspendAutoRefresh={setSuspendRefreshCustomIndices}
          onSync={refreshCustomIndicesList}
          setEditScreenReportId={setEditScreenReportId}
          setScreenReportCustomIndexId={setScreenReportCustomIndexId}
          clearAlert={clearAlert}
          setResponse={setResponse}
          signOut={signOut}
        />
      },
      { title: 'Description', field: 'description', sorting: true },
      { title: 'Member Count', field: 'memberCount', type: 'numeric', sorting: true, width: '2%' },
      { title: 'Weight', field: 'weight', type: 'string', sorting: true },
      { title: 'Periodicity', field: 'periodicity', type: 'string', sorting: true },
      { title: 'Updated Date', field: 'lastUpdatedDate', type: 'date', sorting: true },
      { title: 'Status', field: 'status', type: 'string', sorting: true },
      { title: 'Requested Start Date', field: 'requestedStartDate', type: 'date', sorting: false },
      { title: 'Start', field: 'seriesBegin', type: 'date', sorting: true },
      { title: 'End', field: 'seriesEnd', type: 'date', sorting: true },
      { title: '_id', field: 'tickerID', type: 'numeric', hidden: true },
      {
        title: "Quick Actions",
        field: "button",
        sorting: false,
        render: (rowData: any) =>
          rowData && (
            <>
              <IconButton
                title='Edit'
                onClick={() => {
                  clearAlert()
                  setEditIndex(rowData)
                }}
                size='small'
              >
                <EditIndices></EditIndices>
              </IconButton>

              <IconButton
                title='Graph'
                onClick={() => {
                  clearAlert()
                  viewChart(rowData.symbol as string)
                }}
                size='small'
              >
                <GraphwLines></GraphwLines>
              </IconButton>

              <IconButton
                title='Download'
                onClick={() => {
                  clearAlert()
                  processDownloadRequest([rowData])
                }}
                size='small'
              >
                <DownLoad></DownLoad>
              </IconButton>
            </>
          ),
        headerStyle: {
          whiteSpace: 'nowrap'
        }
      }
    ]
    return columns
  }

  const [deleteRequest, setDeleteRequest] = useState<DeleteRequestProps | null>()
  const [tickersToDownload, setTickersToDownload] = useState<TickerData[] | null>(null)
  const [triggerProcessDownload, setTriggerProcessDownload] = useState<boolean>(false)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [columns, setColumns] = useState(getColumns())

  const [createIndex, setCreateIndex] = useState<boolean>(false)
  const [editIndex, setEditIndex] = useState<CustomIndex | null>()
  const [editScreenReportId, setEditScreenReportId] = useState<number | null>()
  const [screenReportCustomIndexId, setScreenReportCustomIndexId] = useState<number | null>(null)

  const [messages, setMessages] = useState<MessageResponse[]>([])
  const [response, setResponse] = useState<NotOKResponseModel | null>()

  const [exportInProgress, setExportInProgress] = useState<boolean>(false)
  const [showExportOutputFormatModal, setShowExportOutputFormatModal] = useState<boolean>(false)

  const [showGraphModal, setShowGraphModal] = useState<boolean>(false)
  const [selectedSeriesSymbol, setSelectedSeriesSymbol] = useState<string>('')

  const displayMessage = (message: string, type: MessageResponseValueType) => {
    const componentMessage: MessageResponse = {
      message: message as string,
      type
    }
    setResponse(null)
    setMessages([componentMessage])
    return
  }

  const displayResponse = (model: NotOKResponseModel) => {
    if (model) {
      setMessages([])
      setResponse(model as NotOKResponseModel)
      return
    }
  }

  const clearAlertsInCurrentWindow = () => {
    setMessages([])
    setResponse(null)
  }

  const clearAlert = () => {
    clearAlertsInCurrentWindow()
    clearMessages()
  }

  useEffect(() => {
    clearAlertsInCurrentWindow()
  }, [clearMessagesToggle])

  useEffect(() => {
    setColumns(getColumns())
  }, [customIndices])

  const oneMinuteInMilliseconds = 60 * 1000

  const useInterval = (callback: any, refreshCustomIndicesRequestInProgress: boolean, suspendRefreshCustomIndices: boolean) => {
    const savedCallback: any = useRef()
   
    useEffect(() => {
      savedCallback.current = callback
    })
   
    useEffect(() => {
      const tick = () => {
        if (savedCallback?.current) savedCallback.current()
      }
   
      const id = setInterval(tick, 0.5 * oneMinuteInMilliseconds)
      return () => clearInterval(id)
    }, [refreshCustomIndicesRequestInProgress, suspendRefreshCustomIndices])
  }

  useInterval(() => {
      if (!refreshCustomIndicesRequestInProgress && !suspendRefreshCustomIndices) {
        console.log('Suspend: ', suspendRefreshCustomIndices)
        refreshCustomIndicesList()
      }
    }, refreshCustomIndicesRequestInProgress, suspendRefreshCustomIndices)

  const alertModalProps: AlertModalProps = {
    showModal,
    setShowModal,
    AlertTitle: 'Are you sure?',
    AlertContent: `Would you like to delete the ${deleteRequest?.request?.customIndexIDs?.length || 0} item(s): [${deleteRequest?.names?.join(', ')}]? This procedure is irreversible. Do you want to proceed?`,
    AlertButtons: [
      {
        type: AlertButtonType.Cancel,
        display: 'Cancel',
        onClick: () => {
          setShowModal(false)
          resetDeleteRequest()
        },
        isPrimary: false
      },
      {
        type: AlertButtonType.OK,
        display: 'Yes',
        onClick: () => {
          setShowModal(false)
          executeDeleteRequest()
        },
        isPrimary: true
      },
    ],
    onAlertClose: () => {
      resetDeleteRequest()
      return true
    }
  }

  useEffect(() => {
    if (!deleteRequest) return
    setShowModal(true)
  }, [deleteRequest])

  useEffect(() => {
    setColumns(getColumns())
  }, [customIndices])

  const processDeleteRequest = (data: any) => {
    if (deleteRequest) {
      displayMessage('Previous delete request is in progress!', MessageResponseTypes.Info)
      return
    }

    if (!data) return

    const customIndexIDs = Object.values(data).map((d: any) => d?.tickerID)
    if (customIndexIDs.length === 0) return
    const request: CustomIndexDeleteRequest = {
      customIndexIDs
    }
    setDeleteRequest({ request, names: Object.values(data).map((d: any) => d?.symbol) })
  }

  const processDownloadRequest = (rowData: any) => {
    if (!rowData) return

    const tickers = rowData.map((row: any) => {
      const value = {
        id: row?.tickerID,
        symbol: row?.symbol,
        description: row?.description
      } as TickerData
      return value
    })

    setTickersToDownload(tickers)
    setTriggerProcessDownload(true)
  }

  const resetDeleteRequest = () => {
    setDeleteRequest(undefined)
  }

  const executeDeleteRequest = () => {
    if (!deleteRequest) return
    deleteCustomIndex(deleteRequest.request)
      .then((result: any) => {
        displayMessage(`${deleteRequest?.request?.customIndexIDs?.length || 0} CustomIndex(s) [${deleteRequest?.names?.join(', ')}] deleted.`, MessageResponseTypes.Success)
        refreshCustomIndicesList()
        resetDeleteRequest()
      },
        (notOKResponseModel: NotOKResponseModel) => {
          resetDeleteRequest()
          displayResponse(notOKResponseModel)
        })
  }

  const renderExport = () => {
    return exportInProgress ?
      <Tooltip title='Export in Progress'>
        <IconButton aria-label='Export in Progress' component='label' style={{ marginLeft: '10px' }}>
          <CircularProgress color='primary' style={{ fontSize: '1.2em', ...globalStyles.circularProgress }} title='Export in Progress' aria-label='Export in Progress' />
        </IconButton>
      </Tooltip>
      :
      <Tooltip title={'Export All Custom Indices'} >
        <span>
          <IconButton
            onClick={() => {
              setShowExportOutputFormatModal(true)
            }}
            title='Export All Custom Indices'
            aria-label='Export All Custom Indices'
          >
            <ExporttoList
              style={{ fontSize: '1.25em' }}
            />
          </IconButton>
        </span>
      </Tooltip>
  }

  const renderTitle = () => {
    const title = 'My Custom Indices'

    return (<>
      {canUseTool ?? false ? <React.Fragment>
        {title}


        <Tooltip title='Create Index'>
          <CustomBtnAddEdit variant='contained' style={{ borderRadius: 5, marginBottom: 3, scale: '70%' }}
            onClick={
              () => setCreateIndex(true)
            }
            aria-label='Create Index'
          >
            <AddNewScreening style={{ fontSize: '2em' }}></AddNewScreening>
          </CustomBtnAddEdit>
        </Tooltip>

        {renderExport()}

        <IconButton sx={{
          visibility: refreshCustomIndicesRequestInProgress ? 'hidden' : 'visible'
        }}
          title='Refresh Members List' aria-label='Refresh Members List' component='label'
          onClick={() => {
            clearAlert()
            refreshCustomIndicesList()
          }}>
          <RefreshMembersList style={{ fontSize: '1.25em' }}></RefreshMembersList>
        </IconButton>
        <IconButton title='Refresh in Progress'
          aria-label='Refresh in Progress' component='label' sx={{
            p: '10px',
            color: '#1976d2',
            visibility: refreshCustomIndicesRequestInProgress ? 'visible' : 'hidden'
          }}>
          <CircularProgress style={{ color: '#007ea8' }} title='Refresh in Progress' aria-label='Refresh in Progress' />
        </IconButton>
      </React.Fragment>
        :
        <React.Fragment />
      }
    </>)
  }

  const onSort = (columnNumber: number) => {
    clearAlert()

    const props: CustomIndexSortProps = {
      sortField: sortData.sortProps.sortField,
      sortOrder: SortOrderType.Ascending
    }

    try {
      switch (columnNumber) {
        case 0:
          props.sortField = CustomIndexSortFieldType.Symbol
          break
        case 1:
          props.sortField = CustomIndexSortFieldType.ScreenReportID
          break
        case 2:
          props.sortField = CustomIndexSortFieldType.Description
          break
        case 3:
          props.sortField = CustomIndexSortFieldType.MemberCount
          break
        case 4:
          props.sortField = CustomIndexSortFieldType.Weight
          break
        case 5:
          props.sortField = CustomIndexSortFieldType.Periodicity
          break
        case 6:
          props.sortField = CustomIndexSortFieldType.LastUpdatedDate
          break
        case 7:
          props.sortField = CustomIndexSortFieldType.Status
          break
        case 9:
          props.sortField = CustomIndexSortFieldType.SeriesBegin
          break
        case 10:
          props.sortField = CustomIndexSortFieldType.SeriesEnd
          break
        default:
          props.sortField = CustomIndexSortFieldType.None
          break
      }

      if (props.sortField === sortData.sortProps.sortField) {
        if (sortData.sortProps.sortOrder === SortOrderType.Ascending) {
          props.sortOrder = SortOrderType.Descending
        } else if (sortData.sortProps.sortOrder === SortOrderType.Descending) {
          props.sortField = CustomIndexSortFieldType.None
          props.sortOrder = SortOrderType.None
        }
      }

      sortData.setSortProps(props)
    }
    catch (e) {
      console.log('Exception while sorting: ', e)
    }
  }

  const componentMessageHandlerProps: ComponentMessageHandlerProps = {
    messages,
    setMessages,
    response,
    signOut
  }

  const refreshOnCallbackFromCustomIndexEditor = (isCancel: boolean) => {
    if (!isCancel) {
      // clear sort order so that sort defaults to descending order by updated date and the newly created/edited index is listed at the top
      sortData.sortProps.sortField = CustomIndexSortFieldType.None
      sortData.sortProps.sortOrder = SortOrderType.None
      refreshCustomIndicesList()
    }
  }

  const createCustomIndexEditorProps: CustomIndexEditorProps | null = createIndex ? {
    callback: (isCancel: boolean) => {
      setCreateIndex(false)
      refreshOnCallbackFromCustomIndexEditor(isCancel)
    },
    signOut
  } : null

  const renderCreateIndex = () => {
    return createIndex && createCustomIndexEditorProps && <div style={{
      width: '100%',
      margin: '0',
      display: 'flex',
      flexDirection: 'column'
    }}
    >
      <CustomIndexEditor {...createCustomIndexEditorProps} />
    </div>
  }

  const processFileDownloadRequest = (downloadRequest: FileDownloadRequest) => {
    const onComplete = (result: boolean) => {
      if (result) GFDToastSuccess(`${downloadRequest?.filename} downloaded!`)
      setExportInProgress(false)
    }
    downloadFileFromServer(downloadRequest, setResponse, onComplete)
  }

  const exportList = (outputDateFormat: DateFormatValueType) => {
    setExportInProgress(true)
    const exportRequest: CustomIndicesRequest = {
      pagination: null,
      sortField: sortData.sortProps.sortField,
      sortOrder: sortData.sortProps.sortOrder,
      dateFormat: outputDateFormat
    }
    exportCustomIndices(exportRequest)
      .then((response: FileDownloadRequest) => {
        processFileDownloadRequest(response)
      },
        //Reject promise
        (notOKResponseModel: NotOKResponseModel) => {
          setExportInProgress(false)
          setResponse(notOKResponseModel)
        })
  }

  const customIndexEditorProps: CustomIndexEditorProps | null = editIndex ? {
    customIndex: editIndex,
    callback: (isCancel: boolean) => {
      setEditIndex(null)
      refreshOnCallbackFromCustomIndexEditor(isCancel)
    },
    signOut
  } : null

  const renderEditIndex = () => {
    return editIndex && customIndexEditorProps && <div style={{
      width: '100%',
      margin: '0',
      display: 'flex',
      flexDirection: 'column'
    }}
    >
      <CustomIndexEditor {...customIndexEditorProps} />
    </div>
  }

  const famaFrenchEditorProps: FamaFrenchEditorProps | null = editScreenReportId ? {
    userInfo,
    screenReportId: editScreenReportId,
    customIndexId: screenReportCustomIndexId,
    callback: (isCancel: boolean) => {
      setEditScreenReportId(null)
      setScreenReportCustomIndexId(null)
      refreshCustomIndicesList()
    },
    setResponse,
    signOut,
    checkIsTrial: checkIsTrial
  } : null

  const renderFamaFrenchEditor = () => {
    return editScreenReportId && famaFrenchEditorProps && <div style={{
      width: '100%',
      margin: '0',
      display: 'flex',
      flexDirection: 'column'
    }}
    >
      <FamaFrenchEditor {...famaFrenchEditorProps} />
    </div>
  }

  const renderDownloadAction = () => {
    const downloadActionProps: DownloadActionProps = {
      showButton: false,
      downloadTriggerProps: {
        triggerProcessDownload,
        resetTriggerProcessDownload: () => setTriggerProcessDownload(false),
        resetRowData: () => setTickersToDownload(null)
      },
      isComposite: true,
      rowData: tickersToDownload,
      maxTickerLimit: AppConfigurations.maximumTickers || 300,
      signOut: signOut
    }
    return <DownloadAction {...downloadActionProps} />
  }

  const viewChart = (symbol: string) => {
    setSelectedSeriesSymbol(symbol)
    setShowGraphModal(true)
  }

  const graphModalProps: GraphModalProps = {
    showModal: showGraphModal,
    setShow: setShowGraphModal,
    seriesName: selectedSeriesSymbol,
    signOut
  }

  const renderList = () => {
    return !editIndex && !createIndex && !editScreenReportId && <>
      {renderDownloadAction()}

      {deleteRequest ? <div>
        <IconButton title='Delete in Progress'
          aria-label='Delete in Progress' component='label' sx={{
            p: '10px',
            color: '#1976d2',
          }}>
          <CircularProgress title='Delete in Progress' aria-label='Delete in Progress' />
        </IconButton>
      </div> : <></>}

      <ThemeProvider theme={DefaultMaterialTheme}>
        <MaterialTable
          style={{
            width: '100%',
            height: '100%',
            margin: '20px',
            border: '1px solid rgb(224, 224, 224)',
            overflow: 'auto',
          }}
          columns={columns}
          data={customIndices}
          title={renderTitle()}
          options={{
            ...MaterialTableOptions,
            paginationPosition: 'top',
            maxBodyHeight: '100%'
          }}
          actions={[
            {
              tooltip: 'Delete Selected Custom Indices',
              icon: () => <DeleteSelected style={{ fontSize: '1.25em' }} />,
              onClick: (evt, data) => {
                clearAlert()
                processDeleteRequest(data)
              }
            },
            {
              tooltip: 'Download Selected Custom Indices',
              icon: () => <DownLoad style={{ fontSize: '1.25em' }} />,
              onClick: (evt, data) => {
                clearAlert()
                processDownloadRequest(data)
              }
            }
          ]}
          components={{
            Pagination: props => (<GFDTablePagination {...pageData} />),
            Header:
              props => (
                <EnhancedTableHead {...{ sortData: { getDefaultSort, onSort }, columns, allowSelection: true, onSelectAll: props?.onAllSelected, selectedCount: props?.selectedCount, rowCount: props?.dataCount }} />
              )
          }}
        />
      </ThemeProvider>
    </>
  }

  return (
    <div style={styles.container as CSSProperties}>

      <div style={styles.item as CSSProperties}>
        <ComponentMessageHandler {...componentMessageHandlerProps} />
      </div>

      {renderCreateIndex()}
      {renderEditIndex()}
      {renderFamaFrenchEditor()}
      {renderList()}

      <AlertModal {...alertModalProps} />
      {showGraphModal && <GraphModal {...graphModalProps} />}
      <ExportOutputFormatSelectionModal show={showExportOutputFormatModal} setShow={setShowExportOutputFormatModal} exportAction={exportList} />
    </div>
  )
}

const stylesTitleWithoutDownload: CSSProperties = {
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  fontSize: '1rem',
  padding: '8px'
}

const stylesTitleWithDownload: CSSProperties = {
  ...stylesTitleWithoutDownload,
  float: 'left',
  maxWidth: '85%'
}

const styles = {
  modalTitleWorkbook: {
    fontStyle: 'italic',
    marginTop: '8px'
  },

  tabPanel: {
    downloadQueue: {
      paddingLeft: '0px',
      paddingRight: '0px',
      paddingTop: '0px',
      paddingBottom: '8px'
    }
  },

  container: {
    width: '100%',
    height: '100%',
    maxHeight: 'inherit',
    display: 'flex',
    flexDirection: 'column',
    //overflow: 'auto',
  },

  item: {
    width: '100%',
    flexGrow: '0',
    flexBasis: 'auto',
    marginBottom: '4px',
  },

  fillItem: {
    width: '100%',
    flexBasis: '0',
    flexGrow: '1',
    display: 'flex',
    overflow: 'auto',
  },

  customIcon: {
    width: '30px',
    height: '30px',
    marginRight: '10px'
  },

  headerWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignContent: 'center',
    width: '100%',
    background: 'linear-gradient(90deg, #8dc8e7, #c2e8fc)',
    padding: '12px 20px 8px 20px',
    borderRadius: '40px'
  },

  reportWrapper: {
    paddingTop: '20px'
  },

  iconButton: {
    marginLeft: '8px',
    marginRight: '8px',
    marginTop: '15px'
  }
}

export default CustomIndicesList
