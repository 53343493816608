import { SortOrderType } from "./SortModel"

export interface Impersonator {
    impersonatorID?: number,
    name?: string,
    username?: string
}

export interface UserInfo {
    orgID?: number,
    orgType?: string,
    orgName?: string,
    position?: string,
    userID?: number,
    templateID?: number,
    active?: boolean,
    firstName?: string,
    lastName?: string,
    phone?: string,
    email?: string,
    address?: string,
    specialCategory?: string,
    isTrialUser?: boolean,
    impersonator?: Impersonator,
    isCookieAgreed?: boolean,
    isBillable?: boolean
}

export const isAnonymous = (userInfo?: UserInfo | null) => {
    return userInfo?.specialCategory?.trim()?.toLowerCase() === 'anonymous'
}

export enum SearchUserSortFieldEnum
{
    None = 'None',
    OrganizationName = 'OrganizationName',
    OrganizationType = 'OrganizationType',
    FirstName = 'FirstName',
    LastName = 'LastName',
    Position = 'Position',
    Email = 'Email',
    Phone = 'Phone'
}

export const SearchUserFieldNames = {
    ID: 'userID',
    OrganizationName: 'orgName',
    OrganizationType: 'orgType',
    FirstName: 'firstName',
    LastName: 'lastName',
    Position: 'position',
    Email: 'email',
    Phone: 'phone'
}

export const SearchUserSortFields: {[key: string]: SearchUserSortFieldEnum } = {
    [SearchUserFieldNames.OrganizationName]: SearchUserSortFieldEnum.OrganizationName,
    [SearchUserFieldNames.OrganizationType]: SearchUserSortFieldEnum.OrganizationType,
    [SearchUserFieldNames.FirstName]: SearchUserSortFieldEnum.FirstName,
    [SearchUserFieldNames.LastName]: SearchUserSortFieldEnum.LastName,
    [SearchUserFieldNames.Position]: SearchUserSortFieldEnum.Position,
    [SearchUserFieldNames.Email]: SearchUserSortFieldEnum.Email,
    [SearchUserFieldNames.Phone]: SearchUserSortFieldEnum.Phone
}
  
export const SearchUserFields = Object.keys(SearchUserSortFields)

export interface SearchUserSortProps {
    sortField: SearchUserSortFieldEnum,
    sortOrder: SortOrderType
}
