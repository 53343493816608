export const bases = {
    searchHome: '/',
    searchHomeAlternative: '/search',
    account: '/account/',
    admin: '/admin/',
    autoTrac: '/autoTrac',
    financialTools: '/tools/',
    forgotPassword: '/forgotPassword',
    graph: '/graph',
    help: '/help/',
    login: '/login',
    register: '/register',
    resetForgotPassword: '/resetForgotPassword/:token',
    API: '/API'
}

export const autoTracParts = {
    autoTrac: 'autoTrac',
    autoTracSections: {
        basic: 'basic',
        advanced: 'advanced',
        tips: 'tips'
    }
}
export const graphingParts = {
    graphing: 'graphing',
    graphingSections: {
        basic: 'basic',
        advanced: 'advanced',
        tools: 'tools',
        tips: 'tips'
    }
}

export const financialToolsHelpParts = {
    financialTools: 'financialTools',
    financialToolsSections: {
        basic: 'basic',
        CAPEratio: 'CAPEratio',
        capitalization: 'capitalization',
        currencyConverter: 'currencyConverter',
        famaFrench: 'famaFrench',
        myIndices: 'myIndices',
        yieldCurve: 'yieldCurve'
    }
}

export const actionsHelpParts = {
    actions: 'actions',
    actionsSections: {
        basic: 'basic',
        quickActions: 'quickActions',
        toolBar: 'toolbar'
    }
}

export const pathParts = {
    account: {
        API: 'API',
        basicInfo: 'basicInfo',
        access: 'access',
        activity: 'activity',
        organizationActivity: 'organizationActivity'
    },
    admin: {
        onlineUsers: 'onlineUsers',
        impersonateUser: 'impersonateUser',
        accessCacheQueue: 'accessCacheQueue'
    },
    famaFrench: {
        screenID: ':expandedScreenID'
    },
    graph: {
        lineGraph: 'lineGraph',
        symbol: ':symbol',
        barChart: 'barChart',
        pieChart: 'pieChart'
    },
    help: {
        helpDefault: '/help/searchHelp',
        helpSingle: '/help/',
        actions: actionsHelpParts.actions,
        actionsSections: {
            basic: `${actionsHelpParts.actions}/${actionsHelpParts.actionsSections.basic}`,
            quickActions: `${actionsHelpParts.actions}/${actionsHelpParts.actionsSections.quickActions}`,
            toolbar: `${actionsHelpParts.actions}/${actionsHelpParts.actionsSections.toolBar}`
        },
        searchHelp: '/searchHelp',
        searchHelpItems: {
            basic: 'searchHelp/basicSearch',
            advanced: 'searchHelp/advancedSearch',
            USUKStock: 'searchHelp/USUKStock',
            eventsInTime: 'searchHelp/eventsInTime',
            constituentMembership: 'searchHelp/constituentMembership',
            toolsSearch: 'searchHelp/toolsSearch'
        },
        financialTools: financialToolsHelpParts.financialTools,
        financialToolsSections: {
            basic: `${financialToolsHelpParts.financialTools}/${financialToolsHelpParts.financialToolsSections.basic}`,
            CAPEratio: `${financialToolsHelpParts.financialTools}/${financialToolsHelpParts.financialToolsSections.CAPEratio}`,
            capitalization: `${financialToolsHelpParts.financialTools}/${financialToolsHelpParts.financialToolsSections.capitalization}`,
            currencyConverter: `${financialToolsHelpParts.financialTools}/${financialToolsHelpParts.financialToolsSections.currencyConverter}`,
            famaFrench: `${financialToolsHelpParts.financialTools}/${financialToolsHelpParts.financialToolsSections.famaFrench}`,
            myIndices: `${financialToolsHelpParts.financialTools}/${financialToolsHelpParts.financialToolsSections.myIndices}`,
            yieldCurve: `${financialToolsHelpParts.financialTools}/${financialToolsHelpParts.financialToolsSections.yieldCurve}`
        },
        graphing: graphingParts.graphing,
        graphingSections: {
            basic: `${graphingParts.graphing}/${graphingParts.graphingSections.basic}`,
            advanced: `${graphingParts.graphing}/${graphingParts.graphingSections.advanced}`,
            tools: `${graphingParts.graphing}/${graphingParts.graphingSections.tools}`,
            tips: `${graphingParts.graphing}/${graphingParts.graphingSections.tips}`
        },
        autoTrac: autoTracParts.autoTrac,
        autoTracSections: {
            basic: `${autoTracParts.autoTrac}/${autoTracParts.autoTracSections.basic}`,
            advanced: `${autoTracParts.autoTrac}/${autoTracParts.autoTracSections.advanced}`,
            tips: `${autoTracParts.autoTrac}/${autoTracParts.autoTracSections.tips}`
        }
    },
    search: {
        GFDUniverse: 'GFDUniverse',
        GFDatabase2Point0: 'GFDatabase2Point0',
        GFDatabase: 'GFDatabase',
        GFDIndices: 'GFDIndices',
        constituentMembership: 'constituentMembership',
        eventsInTime: 'eventsInTime',
        USStocks: 'USStocks',
        UKStocks: 'UKStocks',
        searchDefault: '/search/GFDUniverse',
        searchSingle: '/search/'
    },
    selectedResults: 'selectedResults',
    tools: {
        famaFrench: 'famaFrench',
        CAPERatio: 'CAPERatio',
        capitalization: 'capitalization',
        currencyConverter: 'currencyConverter',
        yieldCurve: 'yieldCurve',
        customIndices: 'customIndices'
    },
    api: {
        api: 'API.ashx',
        autotrac: 'AutoTrac.ashx',
        bulk: 'Bulk.ashx'
    }
}

export const paths = {
    account: {
        base: bases.account,
        API: `${bases.account}/${pathParts.account.API}`,
        basicInfo: `${bases.account}/${pathParts.account.basicInfo}`,
        access: `${bases.account}/${pathParts.account.access}`,
        activity: `${bases.account}/${pathParts.account.activity}`
    },
    admin: {
        base: bases.admin,
        impersonateUser: `${bases.admin}/${pathParts.admin.impersonateUser}`,
        onlineUsers: `${bases.admin}/${pathParts.admin.onlineUsers}`,
        accessCacheQueue: `${bases.admin}/${pathParts.admin.accessCacheQueue}`
    },
    autoTrac: bases.autoTrac,
    financialTools: {
        base: bases.financialTools,
        famaFrench: `${bases.financialTools}${pathParts.tools.famaFrench}`,
        customIndices: `${bases.financialTools}/${pathParts.tools.customIndices}`,
        CAPERatio: `${bases.financialTools}/${pathParts.tools.CAPERatio}`,
        capitalization: `${bases.financialTools}/${pathParts.tools.capitalization}`,
        currencyConverter: `${bases.financialTools}/${pathParts.tools.currencyConverter}`,
        yieldCurve: `${bases.financialTools}/${pathParts.tools.yieldCurve}`,

    },
    forgotPassword: bases.forgotPassword,
    graph: {
        base: bases.graph,
        barChart: `${bases.graph}/${pathParts.graph.barChart}`,
        lineGraph: `${bases.graph}/${pathParts.graph.lineGraph}`,
        pieChart: `${bases.graph}/${pathParts.graph.pieChart}`
    },
    help: {
        base: bases.help
    },
    login: bases.login,
    register: bases.register,
    resetForgotPassword: bases.resetForgotPassword,
    searchHome: {
        base: bases.searchHome,
        graph: bases.graph,
        graphBySymbol: `${bases.graph}/${pathParts.graph.symbol}`,
        selectedResults: bases.searchHome + pathParts.selectedResults
    },
    api: {
        base: bases.API,
        api: `${bases.API}/${pathParts.api.api}`,
        autotrac: `${bases.API}/${pathParts.api.autotrac}`,
        bulk: `${bases.API}/${pathParts.api.bulk}`
    }
}
