import { Autocomplete, Chip, CircularProgress, Grid, IconButton, MenuItem, Paper, Stack, TextField, Tooltip, Alert } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { CapitalizationResultType, DefaultHistorySummaryPreviousYears, summaryInfoOptions } from '../../Capitalization'
import { CapitalizationSummary } from '../../../../../../Models/DataModels/Common/ToolsModel'
import { LabelValuePair } from '../../../../../../Models/DataModels/Common/LabelValuePairModel'
import { NotOKResponseModel } from '../../../../../../Models/DataModels/Common/NotOKResponseModel'
import { getMonthsDropdownOptions, allEntry, blankEntry, SOUCurrencyEntry } from '../../../../../../Models/DataModels/Common/FieldPopulationModel'
import AlertModal, { AlertButtonType, AlertModalProps } from '../../../../../Common/Modals/AlertModal'
import moment from 'moment'
import { GetCapitalizationSummaryRequest } from '../../../../../../Models/DataModels/Requests/ToolsRequests'
import { getCapitalizationSummary } from '../../../../../../Services/ToolsService'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { capitalizationTooltipKeys, getTooltipMessage } from '../../../../../../Models/DataModels/Common/TooltipContents'
import { customToolTip } from '../../../../../Common/Modals/CustomToolTips'
import { GFDToastError } from '../../../../../Common/Utility/GFDToastify'
import { CustomBtnBlue, BtnClearReset, CheckboxBlue } from '../../../../../Common/GlobalSettings/CustomStyles'

export interface CapitalizationStartScreenProps {
    setErrorResponse: (errorResponse: NotOKResponseModel | null) => void,
    setShowStartScreen: (show: boolean) => void,
    selectedMonth: number,
    setSelectedMonth: (month: number) => void,
    selectedYear: number,
    setSelectedYear: (year: number) => void,
    indexOptions: LabelValuePair[],
    listingCountryOptions: LabelValuePair[],
    operatingCountryOptions: LabelValuePair[],
    sectorOptions: LabelValuePair[],
    industryOptions: LabelValuePair[],
    currencyOptions: LabelValuePair[],
    selectedIndex: LabelValuePair,
    setSelectedIndex: (item: LabelValuePair) => void,
    selectedCurrency: LabelValuePair,
    setSelectedCurrency: (item: LabelValuePair) => void,
    setCapResultType: (resultType: CapitalizationResultType) => void,
    setSummaryResult: (summaryResult: CapitalizationSummary[]) => void,
    constituentSelected: boolean,
    setConstituentSelected: (constituentSelected: boolean) => void,
    selectedListingCountry: LabelValuePair,
    setSelectedListingCountry: (selectedListingCountry: LabelValuePair) => void,
    selectedOperatingCountry: LabelValuePair,
    setSelectedOperatingCountry: (selectedOperatingCountry: LabelValuePair) => void,
    selectedSector: LabelValuePair,
    setSelectedSector: (selectedSector: LabelValuePair) => void,
    selectedIndustry: LabelValuePair,
    setSelectedIndustry: (selectedIndustry: LabelValuePair) => void,
    yearOverYearInputFieldsEnabled: boolean,
    setYearOverYearInputFieldsEnabled: (yearOverYearInputFieldsEnabled: boolean) => void,
    yearOverYearStartDate: moment.Moment,
    setYearOverYearStartDate: (yearOverYearStartDate: moment.Moment) => void,
    selectedSummaryInfo: string,
    setSelectedSummaryInfo: (selectedSummaryInfo: string) => void,
    historicalChartChecked: boolean,
    setHistoricalChartChecked: (historicalChartChecked: boolean) => void,
    summaryPreviousYears: number,
    setSummaryPreviousYears: (summaryPreviousYears: number) => void,
    setShowLoadingModal: (showLoadingModal: boolean) => void,
    doGetCapitalizationListRequest: () => void,
    canUseCapTool: boolean | null | undefined,
    exchangeListLoading: boolean,
    setCurrentSummaryRequest: (currentSummaryRequest: GetCapitalizationSummaryRequest | null) => void
}

const CapitalizationStartScreen = ({
    setErrorResponse,
    setShowStartScreen,
    selectedMonth,
    setSelectedMonth,
    selectedYear,
    setSelectedYear,
    indexOptions,
    listingCountryOptions,
    operatingCountryOptions,
    sectorOptions,
    industryOptions,
    currencyOptions,
    selectedIndex,
    setSelectedIndex,
    selectedCurrency,
    setSelectedCurrency,
    setCapResultType,
    setSummaryResult,
    constituentSelected,
    setConstituentSelected,
    selectedListingCountry,
    setSelectedListingCountry,
    selectedOperatingCountry,
    setSelectedOperatingCountry,
    selectedSector,
    setSelectedSector,
    selectedIndustry,
    setSelectedIndustry,
    yearOverYearInputFieldsEnabled,
    setYearOverYearInputFieldsEnabled,
    yearOverYearStartDate,
    setYearOverYearStartDate,
    selectedSummaryInfo,
    setSelectedSummaryInfo,
    historicalChartChecked,
    setHistoricalChartChecked,
    summaryPreviousYears,
    setSummaryPreviousYears,
    setShowLoadingModal,
    doGetCapitalizationListRequest,
    canUseCapTool,
    exchangeListLoading,
    setCurrentSummaryRequest
}: CapitalizationStartScreenProps) => {

    const capitalizationStyles = {
        spanTitle: {
            fontWeight: 'bold',
        }
    }

    const minSelectionYear: number = 1601

    const minimumDate: moment.Moment = moment(`${minSelectionYear}-01-01`)
    const maximumDate: moment.Moment = moment()

    const MinPreviousYears: number = 1
    const MaxPreviousYears: number = 500

    const [constituentChipColor, setConstituentChipColor] = useState<any>('primary')
    const [summaryChipColor, setSummaryChipColor] = useState<any>('default')
    const [yearDatePickerValue, setYearDatePickerValue] = useState<moment.Moment>(moment(`${selectedYear}-01-01`))

    const [showResetAllConfirmModal, setShowResetAllConfirmModal] = useState<boolean>(false)

    const yoyCheckBoxAriaText = (): string => {
        return yearOverYearInputFieldsEnabled ? 'Uncheck to exclude Year Over Year Percentage Change' : 'Check to set Custom Year Over Year Percentage Change Start Date'
    }

    const historicalChartCheckBoxAriaText = (): string => {
        return historicalChartChecked ? 'Uncheck to exclude historical summary chart' : 'Check to show historical summary chart'
    }

    const resetInput = () => {
        const PreviousMonthIndex: number = moment().month() - 1
        setSelectedMonth((PreviousMonthIndex < 0 ? 11 : PreviousMonthIndex) + 1)
        setSelectedYear(moment().year())
        setYearDatePickerValue(moment())
        setSelectedIndex(indexOptions[0])
        setSelectedListingCountry(listingCountryOptions[0])
        setSelectedOperatingCountry(operatingCountryOptions[0])
        setSelectedSector(blankEntry)
        setSelectedIndustry(blankEntry)
        setSelectedCurrency(currencyOptions[0])
        setYearOverYearInputFieldsEnabled(false)
        setYearOverYearStartDate(moment().subtract(1, 'years'))
        setHistoricalChartChecked(false)
        setSelectedSummaryInfo(summaryInfoOptions[0])
        setSummaryPreviousYears(DefaultHistorySummaryPreviousYears)
    }

    const resetAllAlertProps: AlertModalProps = {
        showModal: showResetAllConfirmModal,
        setShowModal: setShowResetAllConfirmModal,
        AlertTitle: 'Clear input',
        AlertContent: 'Are you sure you would like to clear the fields?',
        AlertButtons: [
            {
                type: AlertButtonType.Cancel,
                display: 'Cancel',
                onClick: () => { setShowResetAllConfirmModal(false) },
                isPrimary: false
            },
            {
                type: AlertButtonType.OK,
                display: 'OK',
                onClick: () => {
                    resetInput()
                    setShowResetAllConfirmModal(false)
                },
                isPrimary: true
            }
        ],
        onAlertClose: () => {
            return true
        },
        Size: 'sm'
    }

    const handleResetAll = () => {
        setShowResetAllConfirmModal(true)
    }

    const getResultFlag = (): CapitalizationResultType => {
        let resultTypeFlags: CapitalizationResultType = CapitalizationResultType.Summary
        if (historicalChartChecked) {
            resultTypeFlags = resultTypeFlags | CapitalizationResultType.Chart
        }
        return resultTypeFlags
    }

    const doGetCapitalizationSummaryRequest = () => {
        const getCapSummaryRequest: GetCapitalizationSummaryRequest = {
            date: new Date(selectedYear, selectedMonth - 1),
            index: selectedIndex.value,
            operatingCountry: selectedOperatingCountry.value,
            listingCountry: selectedListingCountry.value,
            sector: selectedSector.value,
            industry: selectedIndustry.value,
            currency: selectedCurrency.value,
            summary: selectedSummaryInfo
        }

        setShowLoadingModal(true)
        getCapitalizationSummary(getCapSummaryRequest).then(
            (capSummaryList: CapitalizationSummary[]) => {
                const resultTypeFlags: CapitalizationResultType = getResultFlag()
                setSummaryResult(capSummaryList)
                setCapResultType(resultTypeFlags)
                setShowStartScreen(false)
                setShowLoadingModal(false)
                setCurrentSummaryRequest(getCapSummaryRequest)
            },
            //Reject promise
            (notOKResponseModel: NotOKResponseModel) => {
                if (historicalChartChecked) {
                    const resultTypeFlags: CapitalizationResultType = getResultFlag()
                    setSummaryResult([])
                    setCapResultType(resultTypeFlags)
                    setShowStartScreen(false)
                    setCurrentSummaryRequest(getCapSummaryRequest)
                } else {
                    setErrorResponse(notOKResponseModel)
                    setCurrentSummaryRequest(null)
                }
                setShowLoadingModal(false)
            }
        )
    }

    const handleSubmit = () => {

        const capDateMoment: moment.Moment = moment()
        capDateMoment.year(selectedYear)
        capDateMoment.month(selectedMonth - 1)

        const currentDateMomemnt: moment.Moment = moment()

        if ((capDateMoment.year() === currentDateMomemnt.year() && capDateMoment.month() === currentDateMomemnt.month()) || capDateMoment > currentDateMomemnt) {
            GFDToastError('Cannot use current calendar year\'s month or future date!')
            return
        }

        if (selectedCurrency) {
            if (selectedCurrency.value !== '' && selectedCurrency.value !== SOUCurrencyEntry.value) {
                const lowerLimitYear: number = 2000
                if (selectedYear < lowerLimitYear) {
                    GFDToastError(`Cannot select year less than ${lowerLimitYear} if currency other than source is selected.`)
                    return
                }
                const lowerLimitPreviousYears: number = 10
                if (!constituentSelected && historicalChartChecked && summaryPreviousYears > lowerLimitPreviousYears) {
                    GFDToastError(`Previous years selected cannot be more than ${lowerLimitPreviousYears} if currency other than source is selected.`)
                    return
                }
            }
        }

        if (constituentSelected) {
            doGetCapitalizationListRequest()
        } else {
            doGetCapitalizationSummaryRequest()
        }
    }

    const handleConstituentChipClick = (event: React.MouseEvent<HTMLDivElement>) => {
        if (!constituentSelected) {
            setConstituentSelected(!constituentSelected)
        }
    }

    const handleSummaryChipClick = (event: React.MouseEvent<HTMLDivElement>) => {
        if (constituentSelected) {
            setConstituentSelected(!constituentSelected)
        }
    }

    useEffect(() => {
        if (constituentSelected) {
            setConstituentChipColor('primary')
            setSummaryChipColor('default')
        } else {
            setConstituentChipColor('default')
            setSummaryChipColor('primary')
        }
    }, [constituentSelected])

    return (
        <div>
            {!canUseCapTool ?
                <>
                    <Alert style={{ padding: '0px 0px 0px 8px' }}
                        severity='error'
                    >
                        Sorry, Capitalization Tool is only available to users with subscription to US Stocks, UK Stocks or GFD Indices.
                    </Alert>
                </>
                :
                <>
                    <Grid container spacing={2} columns={16}>
                        <Grid item md={2}>
                            <h3>Options</h3>
                        </Grid>
                        <Grid item md={10}>
                            <p>Select constituent to retrieve market capitalization data. Select the summary option to retrieve summary data. Select Index and other dropdown options to narrow down the result.</p>
                        </Grid>
                    </Grid>
                    <Paper elevation={3} style={{ padding: 15 }}>
                        <AlertModal {...resetAllAlertProps} />
                        <Stack direction='row' spacing={2}>
                            <Chip label='Constituent' style={!constituentSelected ? { backgroundColor: 'rgba(0,0,0,0.12)' } : { backgroundColor: '#007ea8' }} color={constituentChipColor} onClick={handleConstituentChipClick} />
                            <Chip label='Summary' style={constituentSelected ? { backgroundColor: 'rgba(0,0,0,0.12)' } : { backgroundColor: '#007ea8' }} color={summaryChipColor} onClick={handleSummaryChipClick} />
                        </Stack>
                        <Grid container spacing={2} columns={12} sx={{ paddingTop: 1 }}>
                            <Grid item md={9}>
                                <Grid container spacing={2} columns={16} justifyContent='flex-start' sx={{ paddingTop: 0 }}>
                                    <Grid item>
                                        <div>
                                            <span style={capitalizationStyles.spanTitle}>Month:</span>
                                        </div>
                                        <TextField
                                            id='capMonthSelection'
                                            label='Select Month'
                                            variant='outlined'
                                            margin='dense'
                                            size='small'
                                            value={selectedMonth}
                                            select
                                            sx={{ width: 300 }}
                                            InputLabelProps={{
                                                shrink: true,
                                                style: { fontWeight: 'bold' }
                                            }}
                                            onChange={(e: any) => {
                                                setSelectedMonth(e.target.value)
                                            }}
                                        >
                                            {getMonthsDropdownOptions().map((option: number) => (
                                                <MenuItem key={option} value={option}>{option}</MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    <Grid item>
                                        <div>
                                            <span style={capitalizationStyles.spanTitle}>Year:</span>
                                        </div>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <DatePicker
                                                label='Select Year'
                                                views={['year']}
                                                value={yearDatePickerValue}
                                                onChange={(value: moment.Moment | null) => {
                                                    if (value && value.isValid() && value.year() >= minSelectionYear) {
                                                        setYearDatePickerValue(value)
                                                        setSelectedYear(value.year())
                                                    }
                                                }}
                                                slotProps={{
                                                    textField: {
                                                        id: 'capYearSelection',
                                                        variant: 'outlined',
                                                        size: 'small',
                                                        margin: 'dense',
                                                        InputLabelProps: {
                                                            shrink: true,
                                                            style: { fontWeight: 'bold' }
                                                        }
                                                    },
                                                    field: {
                                                        clearable: true,
                                                        onClear: () => {
                                                            const now: moment.Moment = moment()
                                                            setYearDatePickerValue(now)
                                                            setSelectedYear(now.year())
                                                        }
                                                    },
                                                }}
                                                minDate={minimumDate}
                                                maxDate={maximumDate}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} columns={12}>
                            <Grid item md={6}>
                                <span style={capitalizationStyles.spanTitle}>
                                    Index:
                                </span>
                                {customToolTip(constituentSelected ? getTooltipMessage(capitalizationTooltipKeys.constituent.index) : getTooltipMessage(capitalizationTooltipKeys.summary.index))}
                                <Autocomplete
                                    disablePortal
                                    id='capIndexSelection'
                                    onChange={(e, value) => {
                                        if (value) {
                                            setSelectedIndex(value)
                                        } else {
                                            setSelectedIndex(blankEntry)
                                        }
                                    }}
                                    isOptionEqualToValue={(option, value) => option.value === value.value}
                                    getOptionLabel={(option) => option.label || ''}
                                    options={indexOptions}
                                    value={selectedIndex}
                                    sx={{ width: 400 }}
                                    size='small'
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={'Select Index'}
                                            variant='outlined'
                                            margin='dense'
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} columns={16}>
                            <Grid item md={12}>
                                <Grid container spacing={2} columns={12} justifyContent='flex-start'>
                                    <Grid item md={5}>
                                        <span style={capitalizationStyles.spanTitle}>
                                            Listing Country:
                                            {exchangeListLoading && (
                                                <Tooltip title='Loading Fresh Exchange List...'>
                                                    <IconButton aria-label='Loading' component='label' sx={{
                                                        color: '#1976d2',
                                                    }}>
                                                        <CircularProgress size={15} aria-label='Loading Fresh Exchange List...' />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                        </span>
                                        {customToolTip(constituentSelected ? getTooltipMessage(capitalizationTooltipKeys.constituent.countryListing) : getTooltipMessage(capitalizationTooltipKeys.summary.countryListing))}
                                        <Autocomplete
                                            disablePortal
                                            id='capListCountrySelection'
                                            disabled={exchangeListLoading}
                                            onChange={(e, value) => {
                                                if (value) {
                                                    setSelectedListingCountry(value)
                                                } else {
                                                    setSelectedListingCountry(allEntry)
                                                }
                                            }}
                                            isOptionEqualToValue={(option, value) => option.value === value.value}
                                            getOptionLabel={(option) => option.label || ''}
                                            options={listingCountryOptions}
                                            value={selectedListingCountry}
                                            fullWidth
                                            size='small'
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={'Select Listing Country'}
                                                    variant='outlined'
                                                    margin='dense'
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item md={5}>
                                        <span style={capitalizationStyles.spanTitle}>Operating Country:</span>
                                        {customToolTip(constituentSelected ? getTooltipMessage(capitalizationTooltipKeys.constituent.countryOperation) : getTooltipMessage(capitalizationTooltipKeys.summary.countryOperation))}
                                        <Autocomplete
                                            disablePortal
                                            id='capOpCountrySelection'
                                            onChange={(e, value) => {
                                                if (value) {
                                                    setSelectedOperatingCountry(value)
                                                } else {
                                                    setSelectedOperatingCountry(allEntry)
                                                }
                                            }}
                                            isOptionEqualToValue={(option, value) => option.value === value.value}
                                            getOptionLabel={(option) => option.label || ''}
                                            options={operatingCountryOptions}
                                            value={selectedOperatingCountry}
                                            fullWidth
                                            size='small'
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={'Select Operating Country'}
                                                    variant='outlined'
                                                    margin='dense'
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} columns={16}>
                            <Grid item md={12}>
                                <Grid container spacing={2} columns={12} justifyContent='flex-start'>
                                    <Grid item md={5}>
                                        <span style={capitalizationStyles.spanTitle}>Sector:</span>
                                        {customToolTip(constituentSelected ? getTooltipMessage(capitalizationTooltipKeys.constituent.sector) : getTooltipMessage(capitalizationTooltipKeys.summary.sector))}
                                        <Autocomplete
                                            disablePortal
                                            id='capSectorSelection'
                                            onChange={(e, value) => {
                                                if (selectedIndustry !== blankEntry) {
                                                    setSelectedIndustry(blankEntry)
                                                }
                                                if (value) {
                                                    setSelectedSector(value)
                                                } else {
                                                    setSelectedSector(blankEntry)
                                                }
                                            }}
                                            isOptionEqualToValue={(option, value) => option.value === value.value}
                                            getOptionLabel={(option) => option.label || ''}
                                            options={sectorOptions}
                                            value={selectedSector}
                                            size='small'
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={'Select Sector'}
                                                    variant='outlined'
                                                    margin='dense'
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item md={5}>
                                        <span style={capitalizationStyles.spanTitle}>Industry:</span>
                                        {customToolTip(constituentSelected ? getTooltipMessage(capitalizationTooltipKeys.constituent.industry) : getTooltipMessage(capitalizationTooltipKeys.summary.industry))}
                                        <Autocomplete
                                            disablePortal
                                            id='capIndustrySelection'
                                            onChange={(e, value) => {
                                                if (value) {
                                                    if (value !== blankEntry && selectedSector === blankEntry) {
                                                        const newSector = sectorOptions.find((element: any) => element.label === value?.meta)
                                                        if (newSector) {
                                                            setSelectedSector(newSector)
                                                        }
                                                    }
                                                    setSelectedIndustry(value)
                                                } else {
                                                    setSelectedIndustry(blankEntry)
                                                }
                                            }}
                                            isOptionEqualToValue={(option, value) => option.value === value.value}
                                            getOptionLabel={(option) => option.label || ''}
                                            options={industryOptions}
                                            value={selectedIndustry}
                                            size='small'
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={'Select Industry'}
                                                    variant='outlined'
                                                    margin='dense'
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} columns={16}>
                            <Grid item md={8}>
                                <span style={capitalizationStyles.spanTitle}>Currency:</span>
                                {customToolTip(constituentSelected ? getTooltipMessage(capitalizationTooltipKeys.constituent.currency) : getTooltipMessage(capitalizationTooltipKeys.summary.currency))}
                                <Autocomplete
                                    disablePortal
                                    id='capCurrencySelection'
                                    onChange={(e, value) => {
                                        if (value) {
                                            setSelectedCurrency(value)
                                        } else {
                                            setSelectedCurrency(blankEntry)
                                        }
                                    }}
                                    isOptionEqualToValue={(option, value) => option.value === value.value}
                                    getOptionLabel={(option) => option.label || ''}
                                    options={currencyOptions}
                                    value={selectedCurrency}
                                    sx={{ width: 500 }}
                                    size='small'
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={'Select Currency'}
                                            variant='outlined'
                                            margin='dense'
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                        {constituentSelected ?
                            <Grid container direction='row' justifyContent='flex-start' alignItems='center' columns={16} spacing={1} sx={{ paddingTop: 0, paddingBottom: 3, fontWeight: 'bold' }}>
                                <Grid item>
                                    <CheckboxBlue id='yearOverYearInputFieldsEnabledCheckBox' checked={yearOverYearInputFieldsEnabled}
                                        onChange={(e: any) => {
                                            setYearOverYearInputFieldsEnabled(!yearOverYearInputFieldsEnabled)
                                        }}
                                        inputProps={{
                                            'title': yoyCheckBoxAriaText(),
                                            'aria-label': yoyCheckBoxAriaText()
                                        }}
                                    />
                                </Grid>
                                <Grid item md={4} sx={capitalizationStyles.spanTitle}>
                                    <label>Set Custom Year Over Year Percentage Change Start Date {customToolTip(getTooltipMessage(capitalizationTooltipKeys.constituent.yearOverYear))}</label>
                                </Grid>
                                <Grid item md={11}>
                                    <Grid container spacing={2} columns={16} sx={{ paddingTop: 1 }}>
                                        <Grid item>
                                            <div>
                                                <span style={capitalizationStyles.spanTitle}>Month:</span>
                                            </div>
                                            <TextField
                                                id='YOYMonthSelection'
                                                label='Select Month'
                                                disabled={!yearOverYearInputFieldsEnabled}
                                                variant='outlined'
                                                margin='dense'
                                                size='small'
                                                value={(yearOverYearStartDate.month() + 1).toString()}
                                                select
                                                sx={{ width: 300 }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                    style: { fontWeight: 'bold' }
                                                }}
                                                onChange={(e: any) => {
                                                    const YOYMonthValue: number = e.target.value as number
                                                    const newYOYDate: moment.Moment = moment({ year: yearOverYearStartDate.year(), month: YOYMonthValue - 1 })
                                                    setYearOverYearStartDate(newYOYDate)
                                                }}
                                            >
                                                {getMonthsDropdownOptions().map((option: number) => (
                                                    <MenuItem key={option} value={option}>{option}</MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        <Grid item>
                                            <div>
                                                <span style={capitalizationStyles.spanTitle}>Year:</span>
                                            </div>
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <DatePicker
                                                    label='Select Year'
                                                    views={['year']}
                                                    value={yearOverYearStartDate}
                                                    disabled={!yearOverYearInputFieldsEnabled}
                                                    onChange={(value: moment.Moment | null) => {
                                                        if (value && value.isValid() && value.year() >= minSelectionYear) {
                                                            const YOYYearValue: number = value.year()
                                                            const newYOYDate: moment.Moment = moment({ year: YOYYearValue, month: yearOverYearStartDate.month() })
                                                            setYearOverYearStartDate(newYOYDate)
                                                        }
                                                    }}
                                                    slotProps={{
                                                        textField: {
                                                            id: 'YOYYearSelection',
                                                            variant: 'outlined',
                                                            size: 'small',
                                                            margin: 'dense',
                                                            InputLabelProps: {
                                                                shrink: true,
                                                                style: { fontWeight: 'bold' }
                                                            }
                                                        },
                                                        field: {
                                                            clearable: true,
                                                            onClear: () => {
                                                                const oneYearAgo: moment.Moment = moment().subtract(1, 'years')
                                                                oneYearAgo.month(yearOverYearStartDate.month())
                                                                setYearOverYearStartDate(oneYearAgo)
                                                            }
                                                        },
                                                    }}
                                                    minDate={minimumDate}
                                                    maxDate={maximumDate}
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            :
                            <>
                                <Grid container spacing={2} columns={16}>
                                    <Grid item md={4}>
                                        <span style={capitalizationStyles.spanTitle}>Summary Information:</span>
                                        {customToolTip(getTooltipMessage(capitalizationTooltipKeys.summary.info))}
                                        <TextField
                                            id='capSummaryInfoSelection'
                                            label='Select Summary Type'
                                            variant='outlined'
                                            margin='dense'
                                            size='small'
                                            value={selectedSummaryInfo}
                                            select
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                                style: { fontWeight: 'bold' }
                                            }}
                                            onChange={(e: any) => {
                                                setSelectedSummaryInfo(e.target.value)
                                            }}
                                        >
                                            {summaryInfoOptions.map((option: string) => (
                                                <MenuItem key={option} value={option}>{option}</MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    <Grid item md={12}>
                                        <Grid container direction='row' justifyContent='flex-start' alignItems='center' columns={16} sx={{ paddingTop: 3, paddingBottom: 3, fontWeight: 'bold' }}>
                                            <Grid item>
                                                <CheckboxBlue id='showCapSummaryChartCheckBox' checked={historicalChartChecked}
                                                    onChange={(e: any) => {
                                                        setHistoricalChartChecked(e.currentTarget.checked)
                                                    }}
                                                    inputProps={{
                                                        'title': historicalChartCheckBoxAriaText(),
                                                        'aria-label': historicalChartCheckBoxAriaText()
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item sx={capitalizationStyles.spanTitle}>
                                                <label>{customToolTip(getTooltipMessage(capitalizationTooltipKeys.summary.historicalChart))}Show Historical Summary Chart for Previous</label>
                                            </Grid>
                                            <Grid item>
                                                <TextField id='summaryCharYears' type='number' variant='standard' sx={{ width: 60, paddingLeft: 1, paddingright: 1 }}
                                                    InputProps={{
                                                        inputProps: {
                                                            max: MaxPreviousYears,
                                                            min: MinPreviousYears,
                                                            'title': 'Enter the number of previous years to show',
                                                            'aria-label': 'Enter the number of previous years to show'
                                                        }
                                                    }}
                                                    value={summaryPreviousYears}
                                                    onChange={(e: any) => {
                                                        var inputtedValue = parseInt(e.target.value)
                                                        if (inputtedValue > MaxPreviousYears) {
                                                            inputtedValue = MaxPreviousYears
                                                        }
                                                        if (inputtedValue < MinPreviousYears) {
                                                            inputtedValue = MinPreviousYears
                                                        }
                                                        setSummaryPreviousYears(inputtedValue)
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <label>Years</label>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </>
                        }
                        <Grid container spacing={2} columns={16} sx={{ paddingTop: 1 }}>
                            <Grid item md={2}>
                                <BtnClearReset id='resetAllCapitalizationInputButton' variant='outlined' onClick={handleResetAll}>Reset All</BtnClearReset>
                            </Grid>
                            <Grid item md={2}>
                                <CustomBtnBlue id='goToCapitalizationResultButton' variant='contained' onClick={handleSubmit}>Submit</CustomBtnBlue>
                            </Grid>
                        </Grid>
                    </Paper>
                </>
            }
        </div>
    )
}

export default CapitalizationStartScreen
