
export enum ChangePasswordResultCodes {
    Success = 0,
    InvalidCurrentPassword = 1 << 0,
    OldNewPasswordMatch = 1 << 1,
    NewConfirmPasswordMismatch = 1 << 2,
    InvalidPassword = 1 << 3,
    EmailNotificationFailure = 1 << 4,
    Failure = 1 << 5
}

export enum ResetForgotPasswordResultCodes {
    Success = 0,
    OldNewPasswordMatch = 1 << 0,
    NewConfirmPasswordMismatch = 1 << 1,
    InvalidPassword = 1 << 2,
    Failure = 1 << 3
}

export enum RegisterUserResultCodes {
    Success = 0,
    InputDataValidationError = 1,
    UserBlackListed = 2,
    UserAlreadyExists = 3,
    RestrictedDomainUser = 4,
    Failure = 5,
    EmailFailure = 6,
    InvalidAnonymousUser = 7
}

export enum RegisterUserInputValidationResultCodes {
    AllValid = 0,
    Blank_FirstName = 1 << 0,
    Blank_LastName = 1 << 1,
    Blank_Organization = 1 << 2,
    Blank_OrgType = 1 << 3,
    Blank_City = 1 << 4,
    Blank_State = 1 << 5,
    Blank_ZipCode = 1 << 6,
    Blank_Country = 1 << 7,
    Blank_Email = 1 << 8,
    Blank_ReEnterEmail = 1 << 9,
    Blank_ContactSource = 1 << 10,
    CharErrorFirstName = 1 << 11,
    CharErrorLastName = 1 << 12,
    CharErrorPosition = 1 << 13,
    CharErrorAddress = 1 << 14,
    CharErrorPhone = 1 << 15,
    CharErrorEmail = 1 << 16,
    EmailFormatError = 1 << 17,
    PhoneFormatError = 1 << 18,
    EmailConfirmEmailMismatch = 1 << 19
}

export enum ResourceAccessType {
    None = 'None',
    Partial = 'Partial',
    All = 'All',
    Loading = 'Loading'
}

export interface UserResourceAccessibility {
    canAccessGFDatabase2Point0 : ResourceAccessType
    canAccessGFDatabase : ResourceAccessType
    canAccessGFDUniverse : ResourceAccessType
    canAccessGFDIndices : ResourceAccessType
    canAccessUSStocks : ResourceAccessType
    canAccessUKStocks : ResourceAccessType
    canAccessRealEstate : ResourceAccessType
    canAccessConstituentMembership : ResourceAccessType
    canAccessEventsInTime : ResourceAccessType
}
