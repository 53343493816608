import { GraphColors, LineColor } from '../../../Models/DataModels/Common/ColorModel'
import ColorSettings, { ColorSettingsProps } from './GraphOptionsModalSubComponents.tsx/ColorSettings'
import { Dialog, DialogContent, DialogTitle, Paper, PaperProps } from '@mui/material'
import Draggable from 'react-draggable'

const PaperComponent = (props: PaperProps) => {
  return (
    <Draggable
      handle='#draggable-dialog-title'
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  )
}

export enum ColorType {
  Main,
  TechnicalIndicator,
  ValueIndicator,
  FinancialRatioIndicator
}
export interface ColorSelectionModalProps {
  colorSettings: GraphColors,
  setColor: (lineColor: LineColor, type: ColorType) => void
  showModal: boolean,
  setShowModal: (show: boolean) => void,
  onAlertClose: () => boolean
}

const ColorSelectionModal = ({
  colorSettings,
  setColor,
  showModal,
  setShowModal,
  onAlertClose
}: ColorSelectionModalProps) => {
  
  const body = () => {
    const colorSettingsProps: ColorSettingsProps = {
      colorSettings,
      setColor
    }
    
    return (<div style={Styles.Selection}>
      <ColorSettings {...colorSettingsProps}/>
    </div>)
  }
  
  const handleClose = () => {
    if (onAlertClose) onAlertClose()
    setShowModal(false)
  }

  return (
    <Dialog
      fullWidth={true}
      open={showModal}
      onClose={handleClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {'Customize Graph Line Colors'}
      </DialogTitle>
      <DialogContent>
        {body()}
      </DialogContent>
    </Dialog>
  )
}

const Styles = { 
  Selection: {
    width: '100%',
    display: 'flex',
    flexFlow: 'column',
    flexGrow: '1',
    flexBasis: '1',
    overflow: 'auto',
  }
}

export default ColorSelectionModal
