import { CSSProperties, ReactNode, useEffect, useRef, useState } from "react"
import { UserInfo } from "../../../../../Models/DataModels/Common/UserInfoModel"
import { LogoutReasonType } from "../../../../../Models/DataModels/Requests/AuthRequests"
import { MessageResponse, MessageResponseTypes, MessageResponseValueType } from "../../../../../Models/DataModels/Responses/NotOKResponse"
import { NotOKResponseModel } from "../../../../../Models/DataModels/Common/NotOKResponseModel"
import { PaginationRequest } from "../../../../../Models/DataModels/Requests/SearchRequests"
import { ComponentMessageHandler, ComponentMessageHandlerProps } from "../../../../Common/Utility/ComponentMessageHandler"
import GFDPagination, { GFDPaginationProps } from "../../../../Common/Utility/GFDPagination"
import { CircularProgress, IconButton, Toolbar, Tooltip, RadioGroup, FormControlLabel, Accordion, Button, AccordionDetails, TextField, SelectChangeEvent, InputLabel, Select } from "@mui/material"
import { Add, AddCircle, Cancel, Remove, ViewList } from "@mui/icons-material"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import StyledTreeItem from "../../../../Common/Utility/StyledTreeItem"
import ReloadWorkbookList from "../../../../Icons/ReloadWorkbookListIcon"
import { globalStyles } from "../../../../Common/GlobalSettings/GlobalStyles"
import { getCustomIndices, saveCustomIndex } from "../../../../../Services/CustomIndexService"
import { CustomIndexSaveRequest, CustomIndicesRequest } from "../../../../../Models/DataModels/Requests/ToolsRequests"
import { CustomIndexSortFieldType, SortOrderType } from "../../../../../Models/DataModels/Common/SortModel"
import { CustomIndex, CustomIndexMember, CustomIndicesResponse } from "../../../../../Models/DataModels/Responses/ToolsResponses"
import moment from "moment"
import DataFrequencyValue, { DataFrequencyValueType } from "../../../../../Models/DataModels/Common/DataFrequencyModel"
import CompositeWeightValue, { CompositeWeightValueType } from "../../../../../Models/DataModels/Common/CompositeWeightModel"
import { descriptionRegEx, nameRegEx, renderCompositeWeightMenuItems, renderDataFrequencyMenuItems } from "./CustomIndexEditor"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import { AppConfigurations } from "../../../../../Models/DataModels/Common/AppConfigurationsModel"
import { SimpleTreeView } from "@mui/x-tree-view"
import SaveIndexL from "../../../../Icons/SaveIndexL"
import AlertModal, { AlertButtonType, AlertModalProps } from "../../../../Common/Modals/AlertModal"
import AddSeries from "../../../../Icons/AddSeriesIcon"
import CancelTicker from "../../../../Icons/CancelTicker"
import { CustomBtnBlue, CustomBtnCloseCancel, RadioBlue, CheckboxBlue, CustomChip } from "../../../../Common/GlobalSettings/CustomStyles"

export interface CustomIndexSelectionProps {
  onClose: () => void,
  membersToBeAdded?: Map<string, CustomIndexMember>,
  userInfo?: UserInfo | null,
  signOut: (logoutReason: LogoutReasonType) => void
}

export const CustomIndexSelection = ({
  onClose,
  membersToBeAdded,
  userInfo,
  signOut
}: CustomIndexSelectionProps) => {
  const maxPageCount = 10

  const choices = {
    select: 'select',
    create: 'create'
  }

  interface CustomIndexEntry {
    symbol: string,
    description: string,
    tickerID: number
  }

  const [saveIndexRequestInProgress, setSaveIndexRequestInProgress] = useState<boolean>(false)
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false)

  const [symbol, setSymbol] = useState<string>('.')
  const [description, setDescription] = useState<string>('')
  const [startDate, setStartDate] = useState<moment.Moment | null>(moment().subtract(1, "year"))
  const [startDateError, setStartDateError] = useState<string | null>(null)
  const [periodicity, setPeriodicity] = useState<DataFrequencyValueType>(DataFrequencyValue.Daily)
  const [weight, setWeight] = useState<CompositeWeightValueType>(CompositeWeightValue.MarketCap)
  const [totalReturn, setTotalReturn] = useState<boolean>(false)

  const [addMembers, setAddMembers] = useState<Map<string, CustomIndexMember>>(membersToBeAdded || new Map<string, CustomIndexMember>())
  const [expandMembers, setExpandMembers] = useState<boolean>(true)

  const [choice, setChoice] = useState(choices.select)
  const [customIndexSymbol, setCustomIndexSymbol] = useState<string>()
  const [customIndexList, setCustomIndexList] = useState<CustomIndexEntry[]>([])
  const [expanded, setExpanded] = useState<string[]>([])
  const [selected, setSelected] = useState<string[]>([])
  const [showModal, setShowModal] = useState<boolean>(false)

  const [refreshScreen, setRefreshScreen] = useState<boolean>(false)
  
  const [messages, setMessages] = useState<MessageResponse[]>([])
  const [response, setResponse] = useState<NotOKResponseModel | null>()

  const [pageNumber, setPageNumber] = useState(1)
  const [perPageCount, setPerPageCount] = useState(maxPageCount)
  const [startCount, setStartCount] = useState<number>(0)
  const [endCount, setEndCount] = useState<number>(0)
  const [totalCount, setTotalCount] = useState<number>(0)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [applySearch, setApplySearch] = useState<boolean>(false)
  const [requestInProgress, setRequestInProgress] = useState<boolean>(false)

  const folderTitle = 'Custom Indices'

  const getCustomIndexListPaginationRequest = (resetPageNumber: boolean, requestTotalCount: boolean): PaginationRequest => {
    let page = pageNumber
    if (!page || page <= 0) {
      page = 1
    }

    let perPage = perPageCount || maxPageCount

    return {
      pageNumber: resetPageNumber ? 1 : page,
      perPageCount: perPage,
      isTotalCountRequested: requestTotalCount
    } as PaginationRequest
  }

  const getCustomIndexListRequest = (resetPageNumber: boolean, requestTotalCount: boolean): CustomIndicesRequest => {
    return {
      pagination: getCustomIndexListPaginationRequest(resetPageNumber, requestTotalCount),
      sortField: CustomIndexSortFieldType.None,
      sortOrder: SortOrderType.None,
      searchTerm,
      excludeFamaFrenchIndices: true
    }
  }

  const processCustomIndexListResponse = (result: CustomIndicesResponse) => {
    const results = result?.customIndices?.map((entry: CustomIndex) => {
      return { 
        description: entry.description, 
        symbol: entry.symbol, 
        tickerID: entry.tickerID
      } as CustomIndexEntry
    })
  
    setCustomIndexList(results || [])

    if (result?.pagination) {
      if (pageNumber !== result?.pagination?.pageNumber) {
        setPageNumber(result?.pagination?.pageNumber)
      }

      if (perPageCount !== result?.pagination?.perPageCount) {
        setPerPageCount(result?.pagination?.perPageCount)
      }

      if ((result?.pagination?.totalCount || result?.pagination?.totalCount === 0) && totalCount !== result?.pagination?.totalCount) {
        setTotalCount(result?.pagination?.totalCount)
      }

      const start = result.pagination.pageNumber ? (result.pagination.pageNumber - 1) * result.pagination.perPageCount + 1 : 0
        const end = result?.customIndices?.length ? start - 1 + result?.customIndices?.length : 0
        setStartCount(start)
        setEndCount(end)
    } else {
      setPageNumber(0)
      setTotalCount(0)
    }
    if (result?.searchTerm) {
      if (searchTerm !== result.searchTerm) setSearchTerm(result.searchTerm)
    }
  }

  const executeSelect = (symbol: string) => {
    setCustomIndexSymbol(symbol)
  }

  const executeRefresh = (resetPageNumber: boolean, requestTotalCount: boolean) => {
    setRequestInProgress(true)
    getCustomIndices(getCustomIndexListRequest(resetPageNumber, requestTotalCount))
    .then((result: CustomIndicesResponse) => {
      processCustomIndexListResponse(result)
      setRequestInProgress(false)
    },
    //Reject promise
    (notOKResponseModel: NotOKResponseModel) => {
      setRequestInProgress(false)
      displayResponse(notOKResponseModel)
    })
  }

  const treeItemRef = useRef<null | HTMLDivElement>(null)

  const displayMessage = (message: string, type: MessageResponseValueType) => {
    const componentMessage: MessageResponse = {
      message : message as string,
      type
    }
    setResponse(null)
    setMessages([ componentMessage ])
    return
  }

  const displayResponse = (model: NotOKResponseModel) => {
    if (model) {
      setMessages([])
      setResponse(model as NotOKResponseModel)
      return
    }
  }

  useEffect(() => {
    if (treeItemRef && treeItemRef?.current) {
      treeItemRef.current.scrollIntoView({
        behavior: 'smooth'
      })
    }
  }, [treeItemRef.current])

  useEffect(() => {
    executeRefresh(true, true)
    setExpanded([ folderTitle ])
  }, [])

  useEffect(() => {
    setAddMembers(membersToBeAdded || new Map<string, CustomIndexMember>())
    setRefreshScreen(!refreshScreen)
  }, [membersToBeAdded])

  useEffect(() => {
    clearAlert()
    executeRefresh(false, false)
  }, [pageNumber])

  useEffect(() => {
    if (applySearch) {
      clearAlert()
      executeRefresh(true, true)
      setApplySearch(false)
    }
  }, [applySearch])

  useEffect(() => {
    setApplySearch(true)
  }, [searchTerm])

  const refresh = () => {
    clearAlert()
    executeRefresh(false, true)
  }

  const clearAlertsInCurrentWindow = () => {
    setMessages([])
    setResponse(null)
  }

  const clearAlert = () => {
    clearAlertsInCurrentWindow()
  }

  const handleSelect = (event: React.SyntheticEvent, nodeIds: string[]) => {
    clearAlert()

    setSelected(nodeIds)
    if (nodeIds.length === 1) {
      executeSelect(nodeIds[0])
    } else {
      executeSelect('')
    }
  }

  const alertModalProps: AlertModalProps = {
    showModal,
    setShowModal,
    AlertTitle: 'Custom Index Saved',
    AlertContent: `Custom Index ${symbol} saved successfully!`,
    AlertButtons: [
        {
            type: AlertButtonType.OK,
            display: 'OK',
            onClick: () => {
                setShowModal(false)
                onClose()
            },
            isPrimary: true,
        },
    ],
    onAlertClose: () => {
        return true
    },
    showCloseButton: false
  }

  const readOnlyProp: CSSProperties = saveIndexRequestInProgress ? { pointerEvents: 'none'} : {}
 
  const treeItemIcon = () => {
    return <ViewList style={styles.TreeIcon} />
  }
  
  const renderCustomIndex = (customIndex: CustomIndexEntry, index: number) => {
    return (
      <StyledTreeItem
        key={index}
        label={customIndex.symbol}
        itemId={customIndex.symbol}
        slots={{
          icon: treeItemIcon
        }}
        title={customIndex.symbol}
      />
    )
  }
  
  const renderCustomIndexList = () => {
    return (<StyledTreeItem label={getLabelName()} itemId={folderTitle}>
      {customIndexList?.map((customIndex, index) => renderCustomIndex(customIndex, index)) || []}
    </StyledTreeItem>)
  }

  const renderIcons = () => {
    return (
      <>
        {choice === choices.select ? <Tooltip title='Reload Custom Index List'>
          <IconButton aria-label='Reload Custom Index List' component='label' onClick={refresh}>
            <ReloadWorkbookList color='primary' style={{fontSize: '1.2em'}}/>
          </IconButton>
        </Tooltip> : <></>}
        
        {requestInProgress ? <Tooltip title='Request in Progress'>
          <IconButton aria-label='Request in Progress' component='label'>
            <CircularProgress color='primary' style={{fontSize: '1.2em', ...globalStyles.circularProgress}} aria-label='Request in Progress'/>
          </IconButton>
        </Tooltip> : <></>}
      </>
    )
  }

  const unaddMember = (tickerName: string) => {
    if (addMembers.has(tickerName)) {
      addMembers.delete(tickerName)
      setRefreshScreen(!refreshScreen)
    }
  }

  const renderAddedMember = (tickerName: string, ticker: CustomIndexMember) => {
    return <CustomChip label={tickerName}
        title={ticker.description}
        style={{margin: '4px'}}
        icon={<AddSeries />}
        deleteIcon={
            <CancelTicker
                onMouseDown={(event: any) => event.stopPropagation()}
            />
        }
        onDelete={(event: any) => unaddMember(tickerName)} 
    />
  }

  const getLabelName = () => {
    const label = `Custom Index (${startCount} - ${endCount} of ${totalCount})`
    return label
  }

  const componentMessageHandlerProps: ComponentMessageHandlerProps = {
    messages,
    setMessages,
    response,
    signOut
  }

  const paginationProps: GFDPaginationProps = {
    searchTerm,
    setSearchTerm,
    startCount,
    endCount,
    perPageCount,
    pageNumber,
    totalCount,
    setPageNumber,
    setPerPageCount
  }

  const renderAddedMembers = () => {
    const chips: any[] = []

    addMembers.forEach((ticker: CustomIndexMember, tickerName: string) => chips.push(renderAddedMember(tickerName, ticker)))

    return chips
  }

  const handleChangeAccordian = (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpandMembers(newExpanded)
  }

  const renderShowHideMembers = () => {
    return <>
        <Accordion expanded={expandMembers} onChange={handleChangeAccordian} style={{ marginBottom: '16px' }} >
            <Tooltip title='Click to Expand or Hide Options'>
                <Button variant='text' sx={{ width: '100%', padding: 0, backgroundColor: 'gainsboro', color: 'black' }} 
                  onClick={() => { setExpandMembers(!expandMembers) }}>{expandMembers ? `Hide Series (${addMembers?.size})` : `Show Series (${addMembers?.size})`}</Button>
            </Tooltip>
            <AccordionDetails>
              <div style={{ ...readOnlyProp }}>
                {renderAddedMembers()}
              </div>
            </AccordionDetails>
        </Accordion>
    </>
  }

  const handleChoiceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearAlert()
    setChoice((event.target as HTMLInputElement).value)
  }

  const renderChoices = () => {
    return <RadioGroup
      row
      aria-labelledby='radio-buttons-group-label'
      defaultValue='select'
      value={choice}
      onChange={handleChoiceChange}
      name='radio-buttons-group'
    >
      <FormControlLabel value={choices.create} control={<RadioBlue />} label={'Create'} />
      <FormControlLabel value={choices.select} control={<RadioBlue />} label={'Select to Add'} />
      {renderIcons()}
    </RadioGroup>
  }

  const AddTree = () => {
    return <Add style={styles.TreeIcon} />
  }
  
  const RemoveTree = () => {
    return <Remove style={styles.TreeIcon} />
  }

  const renderSelectPanel = () => {
    return <div style={{ ...readOnlyProp }}>
      <div style={styles.TreeContainer as CSSProperties}>
        <div style={styles.TreePagination}>
          
        </div>
        <div style={styles.TreePagination}>
          <GFDPagination {...paginationProps} />
        </div>
        <div style={styles.TreeBody}>
          <SimpleTreeView
            sx={{ 
              width: '100%',
              minHeight: '0',
              overflow: 'auto',
            }}
            aria-label='controlled'
            slots={{
              expandIcon: AddTree,
              collapseIcon: RemoveTree
            }}
            expandedItems={expanded}
            selectedItems={selected}
            onSelectedItemsChange={handleSelect}
            multiSelect
          >
            {renderCustomIndexList()}
          </SimpleTreeView>
        </div>
      </div>
    </div>
  }

  const validate = (): boolean => {
    if (choice === choices.select && !customIndexSymbol) {
      displayMessage('Please select a Custom Index', MessageResponseTypes.Error)
      return false
    }

    if (!addMembers.size) {
      displayMessage('No members to add', MessageResponseTypes.Error)
      return false
    }
    return true
  }

  const getCustomIndexID = () => {
    return customIndexList.find(index => index.symbol === customIndexSymbol)
  }
  
  const processSaveCustomIndex = () => {
    clearAlert()

    if (saveIndexRequestInProgress) return

    if (!validate()) return

    let customIndexID

    if (choice === choices.select) {
      customIndexID = getCustomIndexID()?.tickerID
      if (!customIndexID) {
        displayMessage('Unable to get Custom Index ID', MessageResponseTypes.Error)
        return
      }
    }
    
    const added: string[] = []
    addMembers?.forEach((value: CustomIndexMember, key: string) => added.push(key))

    const request: CustomIndexSaveRequest = {
      ...choice === choices.create ? {
        symbol,
        description,
        weight,
        periodicity,
        isTotalReturn: totalReturn,
        startDate: startDate === null ? null : new Date(startDate.format('MM/DD/YYYY'))
      } 
      : 
      {
        customIndexID,
        startDate: null
      },
      generateIndex: true,
      generateNow: true,
      addMembers: added
    }

    setSaveIndexRequestInProgress(true)
    saveCustomIndex(request).then((response: any) => {
      setShowModal(true)
      setSaveIndexRequestInProgress(false)
    },
    (notOKResponseModel: NotOKResponseModel) => {
        displayResponse(notOKResponseModel)
        setSaveIndexRequestInProgress(false)
    })
  }

  const handleChangeSymbol = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearAlert()
    let name = event.target.value?.trim()?.toUpperCase()
    if (!name?.startsWith('.')) name = '.' + name || ''
    if (nameRegEx.test(name)) {
      setSymbol(name)
    }
  }

  const handleChangeDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearAlert()
    let value = event.target.value?.trim()
    if (descriptionRegEx.test(value)) {
      setDescription(value)
    }
  }

  const handleChangePeriodicity = (
    event: SelectChangeEvent<DataFrequencyValueType>,
    child: ReactNode,
  ) => {
    clearAlert()
    setPeriodicity(event.target.value as DataFrequencyValueType)
  }

  const handleChangeWeight = (
    event: SelectChangeEvent<CompositeWeightValueType>,
    child: ReactNode,
  ) => {
    clearAlert()
    setWeight(event.target.value as CompositeWeightValueType)
  }

  const invalidDateErrorString = 'Invalid date'

  const runStartDateChecks = () => {
    if (!startDate) {
        setStartDateError(null)
    } else if (!startDate.isValid()) {
        setStartDateError(invalidDateErrorString)
    } else {
        setStartDateError(null)
    }
  }

  const handleChangeStartDate = (
      value: moment.Moment | null
  ) => {
    clearAlert()
    if (value?.isValid()) {
        setStartDate(value)
    } else {
      setStartDate(null)
    }
  }

  useEffect(() => {
    if (saveIndexRequestInProgress || !setDisableSubmit) return
    const disable: boolean = Boolean(startDateError)
    setDisableSubmit(disable)
  }, [startDateError])

  useEffect(() => {
    runStartDateChecks()
  }, [startDate])

  const renderCreatePanel = () => {
    return <div style={{ ...readOnlyProp }}>
      <div style={styles.rowContainer as CSSProperties}>
        <div style={styles.columnItem as CSSProperties}>
            <TextField id='indexSymbol' label="Index Symbol (must start with '.')" variant='standard'
                style={styles.innerItem as CSSProperties}
                value={symbol}
                onChange={handleChangeSymbol}
            />
        </div>
        <div style={styles.columnItem as CSSProperties}>
            <TextField id='indexTitle' label='Index Title' variant='standard'
            style={styles.innerItem as CSSProperties}
                value={description}
                onChange={handleChangeDescription}
            />
        </div>
      </div>

      <div style={styles.rowContainer as CSSProperties}>
        <div style={{...styles.columnItem as CSSProperties, alignSelf: 'flex-end'}}>
          <FormControlLabel
              control={<CheckboxBlue 
                  checked={totalReturn} 
                  onChange={(event, checked) => setTotalReturn(checked)}
                  />}
              label='Total Return'
              labelPlacement='end'
          />
        </div>
        <div style={styles.columnItem as CSSProperties}>
          <InputLabel id='indexStartDate'>Start Date</InputLabel>
          <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                  key={'indexStartDate'}
                  value={startDate}
                  minDate={moment(AppConfigurations.minimumDate)}
                  maxDate={moment()}
                  onChange={handleChangeStartDate}
                  sx={{ ...styles.innerItem, fontWeight: 'bold' }}
                  slotProps={{
                      textField: {
                          error: startDateError !== null,
                          helperText: startDateError
                      }
                  }}
              />
            </LocalizationProvider>
          </div>
      </div>

      <div style={styles.rowContainer as CSSProperties}>
        <div style={styles.columnItem as CSSProperties}>
          <InputLabel id='indexPeriodicityLabel'>Periodicity</InputLabel>
          <Select
              style={styles.innerItem as CSSProperties}
              labelId='indexPeriodicityLabel'
              id='indexPeriodicity'
              value={periodicity}
              label='Periodicity'
              onChange={handleChangePeriodicity}
          >
              {renderDataFrequencyMenuItems()}
          </Select>
        </div>
        <div style={styles.columnItem as CSSProperties}>
          <InputLabel id='indexWeightLabel'>Weight</InputLabel>
          <Select
              style={styles.innerItem as CSSProperties}
              labelId='indexWeightLabel'
              id='indexWeight'
              value={weight}
              label='Weight'
              onChange={handleChangeWeight}
          >
              {renderCompositeWeightMenuItems()}
          </Select>
        </div>
      </div>
    </div>
  }

  const renderButtons = () => {
    return <div style={styles.rowContainer as CSSProperties}>
        <CustomBtnCloseCancel
                id='cancelButton' 
                title={saveIndexRequestInProgress ? 'Close' : 'Cancel'}   
                variant='outlined' 
                onClick={onClose}
                startIcon={<Cancel />}
            >
              Cancel
            </CustomBtnCloseCancel> 
            {saveIndexRequestInProgress ?
            <IconButton title='Save Index in Progress'
                aria-label='Save Index in Progress' component='label' sx={{ 
                    p: '10px',
                    color: '#007ea8',
                }}
            >
                <CircularProgress title='Save Index in Progress' aria-label='Save Index in Progress' />
            </IconButton>
            :
            <CustomBtnBlue
            id='saveIndexButton'
            title='Save Custom Index'
            style={{ marginLeft: 5 }}
            variant='contained'
            onClick={processSaveCustomIndex}
            startIcon={<SaveIndexL />}
            {...(saveIndexRequestInProgress || disableSubmit ? {disabled: true} : {})}
        >
            Save Index
          </CustomBtnBlue>
        }   
    </div>
  }

  return (
    <>
      <AlertModal {...alertModalProps} />
      
      {<div>{renderShowHideMembers()}</div>}

      <div>
        Please note, Index takes some time to generate. You can check on the status in Custom Indices tool.
      </div>

      {<div style={{ ...readOnlyProp }}>{renderChoices()}</div>}

      <div style={styles.TreeMessage}>
        <ComponentMessageHandler {...componentMessageHandlerProps} />
      </div>
      
      {choice === choices.select && renderSelectPanel()}
      {choice === choices.create && renderCreatePanel()}

      {renderButtons()}
    </>
  )
}

const styles = {
  TreeIcon: {
    color: 'teal'
  },
  EditContainer: {
    width: '100%'
  },
  Edit: {
    input: {
      padding:'4px 8px',
      backgroundColor: 'whitesmoke',
      fontSize: 'default'
    }
  },
  Toolbar: {
    flexWrap: 'wrap',
    padding: '0 0 0 0',
    minHeight: '48px',
    flexGrow: '0',
    flexBasis: 'auto',
  },
  TreePagination: {
    width: '100%',
    flexGrow: '0',
    flexBasis: 'auto',
    borderBottom: '1px solid rgb(224, 224, 224)',
  },
  TreeMessage: {
    width: '100%',
    flexGrow: '0',
    flexBasis: 'auto',
  },
  TreeTitle: {
    width: '100%',
    backgroundColor: '#007ea8',
    color: 'white',
    borderRadius: 2,
    flexBasis: '32px',
    flexGrow: '0',
  },
  TreeHeader: {
    width: '100%',
    flexGrow: '0',
    flexBasis: 'auto',
    borderBottom: '1px solid rgb(224, 224, 224)',
  },
  TreeBody: {
    width: '100%',
    flexGrow: '1',
    display: 'flex',
    overflow: 'auto',
  },
  TreeContainer: {
    width: '100%',
    height: '100%',
    maxHeight: 'inherit',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
  rowContainer: {
    width: '100%',
    // height: '100%',
    maxHeight: 'inherit',
    display: 'flex',
    flexDirection: 'row',
    overflow: 'auto',
    marginTop: '8px'
  },
  columnItem: {
    width: '100%',
    flexGrow: '0',
    flexBasis: 'auto',
    marginBottom: '4px',
  },
  innerItem: {
    width: '80%',
    flexGrow: '0',
    flexBasis: 'auto',
    marginBottom: '4px',
  }
}

export default CustomIndexSelection
