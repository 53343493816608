import { Autocomplete, Grid, TextField, Tooltip } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { useEffect, useState } from 'react'
import { ALLCountryGroupEntry, SearchDatabaseTypes } from '../../../Models/DataModels/Common/FieldPopulationModel'
import DateYearRangeInput from './DateYearRangeInput'
import { LabelValuePair } from '../../../Models/DataModels/Common/LabelValuePairModel'
import { getLocallyStoredCountryGroups, getLocallyStoredSeriesCategory, getLocallyStoredSeriesTypes, getLocallyStoredMainIndicators, getLocallyStoredCountries, getLocallyStoredCountriesByGroup } from '../../../Services/DropDownValuesService'
import { mainIndicatorToolTipList, seriesCategoryToolTipList, countryGroupToolTipList } from '../../Common/Utility/SearchPageToolTips'
import React from 'react'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { renderAutoComputeOption } from './GFDatabase2Point0'

const GFDatabase = ({ searchEngineOptions, theme }: any) => {

  const [countryGroupList, setCountryGroupList] = useState<LabelValuePair[]>([ALLCountryGroupEntry])
  const [countriesByGroup, setCountriesByGroup] = useState<LabelValuePair[]>([])
  const [seriesCategoryList, setSeriesCategoryList] = useState<LabelValuePair[]>([])
  const [seriesTypeList, setSeriesTypeList] = useState<LabelValuePair[]>([])
  const [mainIndicatorsList, setMainIndicatorsList] = useState<LabelValuePair[]>([])
  const [mainIndicatorToolTip, setMainIndicatorToolTip] = useState<string>('')
  const [seriesCategoryToolTip, setSeriesCategoryToolTip] = useState<string>('')
  const [countryGroupToolTip, setCountryGroupToolTip] = useState<string>('')

  useEffect(() => {
    getLocallyStoredCountryGroups().then((value: LabelValuePair[] | null) => value && setCountryGroupList(value), () => { })
    getLocallyStoredSeriesCategory(SearchDatabaseTypes.GFDatabase).then((value: LabelValuePair[] | null) => value && setSeriesCategoryList(value), () => { })
    getLocallyStoredSeriesTypes(SearchDatabaseTypes.GFDatabase, searchEngineOptions.seriesCategory).then((value: LabelValuePair[] | null) => value && setSeriesTypeList(value), () => { })
    getLocallyStoredMainIndicators(SearchDatabaseTypes.GFDatabase, searchEngineOptions.seriesCategory).then((value: LabelValuePair[] | null) => value && setMainIndicatorsList(value), () => { })
  }, [])

  useEffect(() => {
    getLocallyStoredSeriesTypes(SearchDatabaseTypes.GFDatabase, searchEngineOptions.seriesCategory)
      .then((value: LabelValuePair[] | null) => {
        if (value) {
          setSeriesTypeList(value)
          const found: boolean = (searchEngineOptions.seriesType &&
            value.some(entry => entry.value === searchEngineOptions.seriesType))
          if (!found) searchEngineOptions.setSeriesType(null)
        } else {
          setSeriesTypeList([])
          searchEngineOptions.setSeriesType('')
        }
      }, () => { }
      )
    getLocallyStoredMainIndicators(SearchDatabaseTypes.GFDatabase, searchEngineOptions.seriesCategory).then(
      (value: LabelValuePair[] | null) => {
        if (value) {
          setMainIndicatorsList(value)
          const found: boolean = (searchEngineOptions.mainIndicator && value.some(entry => entry.value === searchEngineOptions.mainIndicator))
          if (!found)
            searchEngineOptions.setMainIndicator(null)
        } else {
          setMainIndicatorsList([])
          searchEngineOptions.setMainIndicator('')
        }
      }, () => { }
    )
  }, [searchEngineOptions.seriesCategory])

  useEffect(() => {
    getLocallyStoredCountriesByGroup(searchEngineOptions.countryGroup).then((value: LabelValuePair[] | null) => value && setCountriesByGroup(value), () => { })
  }, [searchEngineOptions.countryGroup])

  useEffect(() => {
    const toolTip: string = mainIndicatorToolTipList.find((element: any) => element.label === searchEngineOptions?.mainIndicator)?.toolTip || ''
    setMainIndicatorToolTip(toolTip)
  }, [searchEngineOptions.mainIndicator])

  useEffect(() => {
    const toolTip: string = seriesCategoryToolTipList.find((element: any) => element.label === searchEngineOptions?.seriesCategory)?.toolTip || ''
    setSeriesCategoryToolTip(toolTip)
  }, [searchEngineOptions.seriesCategory])

  useEffect(() => {
    const toolTip: string = countryGroupToolTipList.find((element: any) => element.label === searchEngineOptions?.countryGroup)?.toolTip || ''
    setCountryGroupToolTip(toolTip)
  }, [searchEngineOptions.countryGroup])

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Grid container spacing={2}>
          <Grid item md={3}>
            <Autocomplete
              disablePortal
              options={countryGroupList}
              defaultValue={ALLCountryGroupEntry}
              isOptionEqualToValue={(option: any, value) => option?.value === value?.value}
              onChange={(e, value) => {
                let actualValue: any = ''
                typeof (value?.value) !== (null) ? actualValue = value?.value : actualValue = ''
                searchEngineOptions.setCountryGroup(actualValue)
              }}
              renderInput={(params) =>
                <TextField {...params}
                  label={<React.Fragment>
                    <span>Filter by Country Group </span>
                    {searchEngineOptions.countryGroup &&
                      <Tooltip title={countryGroupToolTip}>
                        <HelpOutlineIcon />
                      </Tooltip>
                    }
                  </React.Fragment>}
                  size='small'
                />
              }
              renderOption={renderAutoComputeOption}
              sx={GFDDatabaseStyles.wholeLineInput}
            />
          </Grid>
          <Grid item md={3}>
            <Autocomplete
              disablePortal
              id='selectCountry'
              options={countriesByGroup}
              isOptionEqualToValue={(option: any, value) => option.value === value.value}
              onChange={(e, value) => {
                let actualValue: any = ''
                typeof (value?.value) !== (null) ? actualValue = value?.value : actualValue = ''
                searchEngineOptions.setCountry(actualValue)
              }}
              renderInput={(params) =>
                <TextField {...params}
                  label='Select Country'
                  size='small'
                />}
              renderOption={renderAutoComputeOption}
              sx={GFDDatabaseStyles.wholeLineInput}
            />
          </Grid>
          <Grid item md={3}>
            <Autocomplete
              disablePortal
              id='selectSeriesCategory'
              options={seriesCategoryList}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              onChange={(e, value) => {
                let actualValue: any = ''
                typeof (value?.value) !== (null) ? actualValue = value?.value : actualValue = ''
                searchEngineOptions.setSeriesCategory(actualValue)
              }}
              renderInput={(params) =>
                <TextField {...params}
                  label={<React.Fragment>
                    <span>Filter by Series Category </span>
                    {searchEngineOptions.seriesCategory &&
                      <Tooltip title={seriesCategoryToolTip}>
                        <HelpOutlineIcon />
                      </Tooltip>
                    }
                  </React.Fragment>}
                  size='small'
                />}
              renderOption={renderAutoComputeOption}
              sx={GFDDatabaseStyles.wholeLineInput}
            />
          </Grid>
          <Grid item md={3}>
            <Autocomplete
              disablePortal
              id='selectSeriesType'
              options={seriesTypeList}
              value={searchEngineOptions.seriesType || ''}
              onChange={(e, value) => {
                const actualValue = value?.value ? value?.value : ''
                searchEngineOptions.setSeriesType(actualValue)
              }}
              renderInput={(params) =>
                <TextField {...params}
                  label='Filter by Series Type'
                  size='small'
                  value={searchEngineOptions.seriesType || ''}
                />}
              renderOption={renderAutoComputeOption}
              sx={GFDDatabaseStyles.wholeLineInput}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={3}>
            <Autocomplete
              disablePortal
              id='selectMainIndicators'
              options={mainIndicatorsList}
              value={searchEngineOptions.mainIndicator || ''}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              onChange={(e, value) => {
                let actualValue: any = ''
                typeof (value?.value) !== (null) ? actualValue = value?.value : actualValue = ''
                searchEngineOptions.setMainIndicator(actualValue)
              }}
              renderInput={(params) => (
                <TextField {...params}
                  label={<React.Fragment>
                    <span>Filter by Main Indicators </span>
                    {searchEngineOptions.mainIndicator &&
                      <Tooltip title={mainIndicatorToolTip}>
                        <HelpOutlineIcon />
                      </Tooltip>
                    }
                  </React.Fragment>}
                  size='small'
                />
              )}
              renderOption={renderAutoComputeOption}
              sx={GFDDatabaseStyles.wholeLineInput}
            />
          </Grid>
          <DateYearRangeInput setIsDatesValid={searchEngineOptions.setDatesValid} setValidStartYear={searchEngineOptions.setStartYear} setValidEndYear={searchEngineOptions.setEndYear} setIsOnlyInYearsRange={searchEngineOptions.setIsOnlyInYearsRange} />
        </Grid>
      </ThemeProvider>
    </div >
  )
}

const GFDDatabaseStyles = {
  row: {
    display: 'flex',
    flexWrap: 'wrap' as 'wrap'
  },
  wholeLineInput: {
    paddingBottom: 1,
    width: '100%'
  }
}

export default GFDatabase
