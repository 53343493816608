import { CSSProperties, useEffect, useState } from "react"
import { LogoutReasonType } from "../../../../Models/DataModels/Requests/AuthRequests"
import { CustomIndex, CustomIndicesResponse } from "../../../../Models/DataModels/Responses/ToolsResponses"
import CustomIndicesList, { CustomIndexSortProps, CustomIndicesListProps } from "./CustomIndices/CustomIndicesList"
import { CustomIndexSortFieldType, SortOrderType } from "../../../../Models/DataModels/Common/SortModel"
import { GFDTablePaginationProps } from "../../../Common/Utility/GFDTablePagination"
import { getCustomIndices } from "../../../../Services/CustomIndexService"
import { CustomIndicesRequest } from "../../../../Models/DataModels/Requests/ToolsRequests"
import { PaginationRequest } from "../../../../Models/DataModels/Requests/SearchRequests"
import { ComponentMessageHandler, ComponentMessageHandlerProps } from "../../../Common/Utility/ComponentMessageHandler"
import { NotOKResponseModel } from "../../../../Models/DataModels/Common/NotOKResponseModel"
import { MessageResponse, MessageResponseValueType } from "../../../../Models/DataModels/Responses/NotOKResponse"
import { Alert, CircularProgress, Grid, IconButton } from "@mui/material"
import React from "react"
import { globalStyles } from "../../../Common/GlobalSettings/GlobalStyles"
import { ToolsAccesibilityProps } from "../../../../Models/DataModels/Common/ToolsModel"
import { UserInfo } from "../../../../Models/DataModels/Common/UserInfoModel"

export interface CustomIndicesProps {
  userInfo: UserInfo | null,
  signOut: (logoutReason: LogoutReasonType) => void,
  accessibilityProps: ToolsAccesibilityProps,
  checkIsTrial: () => boolean
}

const CustomIndices = ({
  userInfo,
  signOut,
  accessibilityProps,
  checkIsTrial
}: CustomIndicesProps) => {
  const [loadRequestInProgress, setLoadRequestInProgress] = useState<boolean>(false)

  const [customIndices, setCustomIndices] = useState<CustomIndex[]>([])
  const [triggerRefreshCustomIndices, setTriggerRefreshCustomIndices] = useState<boolean>(false)

  const [pageNumberCustomIndices, setPageNumberCustomIndices] = useState<number>(1)
  const [perPageCountCustomIndices, setPerPageCountCustomIndices] = useState<number>(100)
  const [totalCountCustomIndices, setTotalCountCustomIndices] = useState<number>(0)
  const [sortPropsCustomIndices, setSortPropsCustomIndices] = useState<CustomIndexSortProps>({ sortField: CustomIndexSortFieldType.None, sortOrder: SortOrderType.None })

  const [clearMessagesToggle, setClearMessagesToggle] = useState(false)

  const [messages, setMessages] = useState<MessageResponse[]>([])
  const [response, setResponse] = useState<NotOKResponseModel | null>()

  const displayMessage = (message: string, type: MessageResponseValueType) => {
    const componentMessage: MessageResponse = {
      message: message as string,
      type
    }
    setResponse(null)
    setMessages([componentMessage])
    return
  }

  const displayResponse = (model: NotOKResponseModel) => {
    if (model) {
      setMessages([])
      setResponse(model as NotOKResponseModel)
      return
    }
  }

  const clearMessages = () => {
    setClearMessagesToggle(!clearMessagesToggle)
  }

  const clearAlertsInCurrentWindow = () => {
    setMessages([])
    setResponse(null)
  }

  const clearAlert = () => {
    clearAlertsInCurrentWindow()
    clearMessages()
  }

  useEffect(() => {
    clearAlertsInCurrentWindow()
  }, [clearMessagesToggle])

  const getCustomIndexListPaginationRequest = (resetPageNumber: boolean, requestTotalCount: boolean): PaginationRequest => {
    let page = pageNumberCustomIndices
    if (!page || page <= 0) {
      page = 1
    }

    let perPage = perPageCountCustomIndices || 100

    return {
      pageNumber: resetPageNumber ? 1 : page,
      perPageCount: perPage,
      isTotalCountRequested: requestTotalCount
    } as PaginationRequest
  }

  const getCustomIndexListRequest = (resetPageNumber: boolean, requestTotalCount: boolean): CustomIndicesRequest => {
    return {
      pagination: getCustomIndexListPaginationRequest(resetPageNumber, requestTotalCount),
      sortField: sortPropsCustomIndices.sortField,
      sortOrder: sortPropsCustomIndices.sortOrder
    }
  }

  const processCustomIndexListResponse = (result: CustomIndicesResponse) => {
    setCustomIndices(result?.customIndices || [])

    if (result?.pagination) {
      if (pageNumberCustomIndices !== result?.pagination?.pageNumber) {
        setPageNumberCustomIndices(result?.pagination?.pageNumber)
      }

      if (perPageCountCustomIndices !== result?.pagination?.perPageCount) {
        setPerPageCountCustomIndices(result?.pagination?.perPageCount)
      }

      if ((result?.pagination?.totalCount || result?.pagination?.totalCount === 0) && totalCountCustomIndices !== result?.pagination?.totalCount) {
        setTotalCountCustomIndices(result?.pagination?.totalCount)
      }
    } else {
      setPageNumberCustomIndices(0)
      setTotalCountCustomIndices(0)
    }
  }

  const loadCustomIndices = (resetPageNumber: boolean, requestTotalCount: boolean) => {
    if (!accessibilityProps.canUseTool) return

    setLoadRequestInProgress(true)
    getCustomIndices(getCustomIndexListRequest(resetPageNumber, requestTotalCount))
      .then((result: CustomIndicesResponse) => {
        processCustomIndexListResponse(result)
        setLoadRequestInProgress(false)
        setTriggerRefreshCustomIndices(false)
      },
        //Reject promise
        (notOKResponseModel: NotOKResponseModel) => {
          setLoadRequestInProgress(false)
          setTriggerRefreshCustomIndices(false)
          displayResponse(notOKResponseModel)
        })
  }

  const messageToggleProps = {
    clearMessagesToggle,
    clearMessages: () => {
      setClearMessagesToggle(!clearMessagesToggle)
    }
  }

  const pageData: GFDTablePaginationProps = {
    perPageCount: perPageCountCustomIndices,
    pageNumber: pageNumberCustomIndices,
    totalCount: totalCountCustomIndices,
    setPageNumber: setPageNumberCustomIndices,
    setPerPageCount: setPerPageCountCustomIndices
  }

  const setOffRefreshCustomIndices = () => {
    if (!triggerRefreshCustomIndices)
      setTriggerRefreshCustomIndices(true)
  }

  const sortData = {
    sortProps: sortPropsCustomIndices,
    setSortProps: setSortPropsCustomIndices
  }

  const customIndicesListProps: CustomIndicesListProps = {
    customIndices,
    canUseTool: accessibilityProps.canUseTool,
    refreshCustomIndicesRequestInProgress: loadRequestInProgress,
    refreshCustomIndicesList: setOffRefreshCustomIndices,
    userInfo,
    signOut,
    checkIsTrial,
    pageData,
    sortData,
    ...messageToggleProps
  }

  useEffect(() => {
    if (loadRequestInProgress) return

    loadCustomIndices(false, false)
  }, [pageNumberCustomIndices, sortPropsCustomIndices])

  useEffect(() => {
    if (pageNumberCustomIndices !== 1) {
      setPageNumberCustomIndices(1)
      return
    }

    if (loadRequestInProgress) return

    loadCustomIndices(true, false)
  }, [perPageCountCustomIndices])

  useEffect(() => {
    if (!(accessibilityProps.canUseTool ?? false)) return
    if (loadRequestInProgress) return

    loadCustomIndices(true, true)
  }, [accessibilityProps.canUseTool])

  useEffect(() => {
    if (loadRequestInProgress) return

    if (triggerRefreshCustomIndices) {
      loadCustomIndices(false, true)
    }
  }, [triggerRefreshCustomIndices])

  const componentMessageHandlerProps: ComponentMessageHandlerProps = {
    messages,
    setMessages,
    response,
    signOut
  }

  return <>
    <div style={styles.container as CSSProperties}>
      <Grid container spacing={0} columns={3}>
        <Grid item sm={1} md={2} lg={1}>
          <div style={styles.headerWrapper}>
            <h3 style={{ fontWeight: 'bold' }}>Custom Indices</h3>
          </div>
        </Grid>
      </Grid>
      <div style={styles.item as CSSProperties}>
        <ComponentMessageHandler {...componentMessageHandlerProps} />
      </div>
    </div>
    {accessibilityProps.loadRequestInProgress ? <IconButton aria-label='Checking access...' component='label'>
        <CircularProgress style={globalStyles.circularProgress} title='Checking access...' aria-label='Checking access...' />
      </IconButton>
      : 
      accessibilityProps.canUseTool ? <CustomIndicesList {...customIndicesListProps} />
      : accessibilityProps.canUseTool === null ?
      <React.Fragment>
          <Alert style={{ padding: '0px 0px 0px 8px' }}
              severity='error'
          >
              Access check failed.
          </Alert>
      </React.Fragment> :
      <React.Fragment>
        <Alert style={{ padding: '0px 0px 0px 8px' }}
          severity='error'
        >
          Sorry, the Custom Indices Tool is only available to US and UK stock subscribers.
        </Alert>
      </React.Fragment>
    }
  </>
}

const styles = {
  header: {
    fontWeight: 'bold'
  },
  container: {
    width: '100%',
    height: '100%',
    margin: '0',
    display: 'flex',
    flexDirection: 'column'
  },
  item: {
    width: '100%',
    flexGrow: '0',
    flexBasis: 'auto',
    marginBottom: '4px',
    marginTop: '20px'
  },
  headerWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignContent: 'center',
    width: '100%',
    background: 'linear-gradient(90deg, #8dc8e7, #c2e8fc)',
    padding: '12px 20px 8px 20px',
    borderRadius: '40px'
  }
}

export default CustomIndices
